import { Injectable } from '@angular/core';
import { MatDateFormats, NativeDateAdapter } from '@angular/material/core';
import { format } from 'date-fns';

@Injectable()
export class CustomDateAdapter extends NativeDateAdapter {
  fullDateLength =  8;
  yearSeparator= 2;
  yearMonthSeparator = 5;
  override parse(value: string): Date | null {
    // Implement custom parsing
    const updatedValue = value.replace(/\D/g, '');
    if (updatedValue.length === this.fullDateLength) {
      return super.parse(value);
    } else {
      return null;
    }
  }
  override format(date: Date, displayFormat: MatDateFormats): string {
    const inputformat = displayFormat as unknown as string;
    if (inputformat === 'input') {
      return this.formatDateWithCapitalizedNames(date);
    } else {
      return format(date, 'MM/dd/yyyy');
    }
  }
  formatDateWithCapitalizedNames(date: Date): string {
    const formatString = 'MM/dd/yyyy'; // Your desired format
    return format(date, formatString);
  }
}

export const CUSTOM_DATE_FORMATS = {
  parse: {
    dateInput: 'input',
  },
  display: {
    dateInput: 'input',
    monthYearLabel: 'MMM yyyy',
    dateA11yLabel: 'EEE, MMM d, yyyy',
    monthYearA11yLabel: 'MMMM yyyy',
  },
};

export class AlconDateAdapter extends NativeDateAdapter {

  override format(date: Date, displayFormat: Object): string {
      if (displayFormat === 'alconDateFormat') {
        const day = ('0' + date.getDate()).slice(-2);
        const month = ('0'+ (date.getMonth() + 1)).slice(-2);
        const year = date.getFullYear();
        return `${month}/${day}/${year}`;
      } else {
        const dtf = new Intl.DateTimeFormat(this.locale, {...displayFormat, timeZone: 'utc'});
        const d = new Date();
        d.setUTCFullYear(date.getFullYear(), date.getMonth(), date.getDate());
        d.setUTCHours(date.getHours(), date.getMinutes(), date.getSeconds(), date.getMilliseconds());
        return dtf.format(d);
      }
  }

  override parse(value: string | number, parseFormat?: string): Date | null {
    const minDateLength = 8;

    if (typeof value == 'number') {
      return (value.toString().length < minDateLength) ? null : new Date(value);
    }
    return (value && !(value.length < minDateLength)) ? new Date(Date.parse(value)) : null;
  }
}