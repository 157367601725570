<div class="alcon-sub-header-container">
    <button class="alcon-sub-header" *ngIf="!superAdmin" [ngClass]="{'menu-active': isHomepageUrl}" (click)="getPageRedirect('home')">Homepage</button>
    <button class="alcon-sub-header" *ngIf="!superAdmin" [ngClass]="{'menu-active': isSurgeryUrl}" (click)="getPageRedirect('surgeries')">Surgeries</button>
    <button class="alcon-sub-header" *ngIf="authService.isFacilityAdmin || superAdmin" [ngClass]="{'menu-active': isAdmin}" (click)="getPageRedirect('admin')">Admin</button>

    <button class="alcon-sub-header-icon" (click)="toggleMenu()">
        <mat-icon>menu</mat-icon>
      </button>
<div class="alcon-sub-header-mobile" *ngIf="showMobileHeader">
    <div class="alcon-sub-header-mobile-header"> 
        <img src="assets/adi-logo-white.svg">
        <mat-icon (click)="toggleMenu()">close</mat-icon>
    </div>
    <div class="alcon-sub-header-mobile-menu">
        <button class="alcon-sub-header" *ngIf="!superAdmin" [ngClass]="{'menu-active': isHomepageUrl}" (click)="getPageRedirect('home')">Homepage</button>
        <button class="alcon-sub-header" *ngIf="!superAdmin" [ngClass]="{'menu-active': isSurgeryUrl}" (click)="getPageRedirect('surgeries')">Surgeries</button>
        <button class="alcon-sub-header" *ngIf="authService.isFacilityAdmin || superAdmin" [ngClass]="{'menu-active': isAdmin}" (click)="getPageRedirect('admin')">Admin</button>
        <hr/>
    </div>
</div>
</div>
