<ng-container *ngIf="(authStateService.authState$ | async)?.isAuthenticated else clinicConnectHeader">
    <adi-header-lib
      (loader)="logoutLoader($event)"
      [host]="headerEndPoint"
      [source]="headerSource"
 ></adi-header-lib>
    <div class="alc_header-secondary">
        <app-sub-header *ngIf="isAccountLoaded"></app-sub-header>
        <div class="alcon-account-selection-container">
            <alcon-account-selector-lib
                [host]="headerEndPoint"
                [source]="headerSource"
            >
            </alcon-account-selector-lib>
             <!-- <div class="alc_header-secondary-account" *ngIf="AccountFacilityID && facilitiesSource?.length && (authStateService.authState$ | async)?.isAuthenticated" [formGroup]="accountForm">
                <label>Alcon Account:</label>
                <button [matMenuTriggerFor]="beforeMenu" class="account-switch-button">
                    <div class="account-name-container" *ngIf="currentFacility">{{currentFacility.name}} </div>
                    <div class="d-flex">
                        <mat-icon svgIcon="arrow_down"></mat-icon>
                    </div>
                </button>
                <mat-menu #beforeMenu="matMenu" xPosition="before" class="alc_header_account-menu">
                    <button mat-menu-item *ngFor="let facility of facilitiesSource" (click)="changeFacility(facility)">{{facility?.name}}</button>
                </mat-menu>
            </div>  -->
    </div>
    </div>
</ng-container>
<ng-template #clinicConnectHeader>
<div class="alc_header">
    <div class="alc_header-primary">
        <div class="alc_header-primary-navigation">
            <div class="alc_header-primary-brand">
                <img src="https://alcon.widen.net/content/pmxiwthkgx/webp/Adi_headerlogo.webp?position=c&quality=80&u=uxrrnh&use=ibhcw" alt="Adi Home" class="alc_header-primary-brand-logo adh-logo">          
            </div>
        </div>
    </div>
</div>
</ng-template>
    
