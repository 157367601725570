import { Component } from '@angular/core';
import { adiStorageKey } from 'src/app/shared/constants/auth.constant';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-redirect-page',
  standalone: true,
  imports: [],
  templateUrl: './redirect-page.component.html',
  styleUrl: './redirect-page.component.scss'
})
export class RedirectPageComponent {
  private redirectUrl = environment.newCCURL;
  constructor() { }
  timer: number = 5; //in seconds
  private countdownInterval: any;

  ngOnInit(): void {
    const lang = localStorage.getItem(adiStorageKey.lang);
    if(lang) {
      this.redirectUrl = this.redirectUrl.replace('en_US', lang);
    }
    this.startCountdown();
  }
  
  startCountdown(): void {
    this.countdownInterval = setInterval(() => {
      this.timer--;
      if (this.timer <= 0) {
        clearInterval(this.countdownInterval); // Stop the interval when timer hits 0
        this.gotoAdiHome(); // Redirect after countdown
      }
    }, 1000);
  }

  gotoAdiHome(){
    window.location.href = this.redirectUrl;
  }
}
