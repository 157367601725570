import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'hyphenate',
  standalone: true
})
export class HyphenatePipe implements PipeTransform {
  transform(text: string): string {
    return text.replace(/([a-zA-Z]{4})(?=[a-zA-Z]{4})/g, '$1­');
  }
}