import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-admin-ux-dialog',
  templateUrl: './admin-ux-dialog.component.html',
  styleUrls: ['./admin-ux-dialog.component.scss']
})
export class AdminUxDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<AdminUxDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
  ) {}

  closePopup(){
    this.dialogRef.close();
  }
  
  gotoAdiHome(){
    window.location.href = window.location.origin; 
    this.closePopup();
  }
}
