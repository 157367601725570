import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'alcon-or-day-avatar',
  standalone: true,
  imports: [
    CommonModule,
    MatIconModule
  ],
  templateUrl: './or-day-avatar.component.html',
  styleUrl: './or-day-avatar.component.scss'
})
export class OrDayAvatarComponent {

  @Input() isDarkTheme = true;
  @Input() displayText = "Left/2nd eye";
  @Input() eye = "right"
}
