import { Component, ElementRef, HostListener, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AlconToastMessageService } from 'src/app/shared/alcon-toast-message.service';
import { SurgeryService } from 'src/app/shared/surgery.service';
import { AlertDialogComponent } from '../alert-dialog/alert-dialog.component';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { surgeryDocumentType } from 'src/app/shared/model/surgery.model';
import { SharedService } from 'src/app/shared/service/shared.service';
@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss']
})
export class FileUploadComponent implements OnInit {
  errors: Array<string> = [];
  filestring = '';
  fileExt = 'pdf, docx, xlsx, pptx, txt, rtf, csv, png, jpg, msg';
  maxFiles = 1;
  maxSize = 5;
  isDraggable = true;
  selectedFile: File | null = null;
  selectedFileDate: Date | null = null;
  isMobile: boolean = false;
  isTablet: boolean = false;
  showUploadBtn: boolean = false;
  fileName: string = '';
  files: any;
  @ViewChild('fileInput') fileInput!: ElementRef;
  displayedColumns: string[] = ['documentName', 'uploadDate', 'uploadedBy', 'delete'];
  ELEMENT_DATA: any[] = [];
  dataSource: MatTableDataSource<any> = new MatTableDataSource<any>();
  @ViewChild(MatSort, {static: false}) sort!: MatSort;
  showLoader: boolean = false;
  currentSort: {active: string, direction: string} = {active: '', direction: ''};
  hasUpdate = false;
  private areFilesChanged = false;
  constructor(
    public dialogRef: MatDialogRef<FileUploadComponent>, 
    public toastService: AlconToastMessageService,
    public service: SurgeryService, 
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialog: MatDialog,
    private sharedService: SharedService
  ) {
    // Initially check if the screen size is for mobile or tablet
    this.isMobile = window.innerWidth < 768;
    this.isTablet = window.innerWidth >= 768 && window.innerWidth < 1025;
    window.innerWidth > 1024 ? this.isDraggable = true : this.isDraggable = false;

  }

  ngOnInit() {
    this.showLoader = true;
    this.getDocuments();
  }

  getSortIcon(columnName: string): string {
    if (this.currentSort.active !== columnName) {
      return 'swap_vert';
    }
    return this.currentSort.direction === 'asc' ? 'arrow_upward' : 'arrow_downward';
  }

  customSort(columnName: string): void {
    if (this.currentSort.active === columnName) {
      this.currentSort.direction = this.currentSort.direction === 'asc' ? 'desc' : 'asc';
    } else {
      this.currentSort = {active: columnName, direction: 'asc'};
    }  
    this.sortData();
  }

  sortData() {
    const data = this.ELEMENT_DATA.slice(); // Replace `yourDataArray` with your actual data array
    if (!this.currentSort.active || this.currentSort.direction === '') {
      this.dataSource.data = data;
      return;
    }
  
    this.dataSource.data = data.sort((a, b) => {
      const isAsc = this.currentSort.direction === 'asc';
      return this.compare(a[this.currentSort.active], b[this.currentSort.active], isAsc);
    });
  }
  
  compare(a: number | string, b: number | string, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }
  closePopup() {
    this.dialogRef.close(this.areFilesChanged);
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: Event) {
    // Adjust isMobile and isTablet on screen size change
    this.isMobile = window.innerWidth < 768;
    this.isTablet = window.innerWidth >= 768 && window.innerWidth < 1025;
    window.innerWidth > 1024 ? this.isDraggable = true : this.isDraggable = false;
  }


  @HostListener('dragover', ['$event']) onDragOver(event: any) {
    event.preventDefault();
  }

  @HostListener('dragenter', ['$event']) onDragEnter(event: any) {
    event.preventDefault();
  }

  @HostListener('dragend', ['$event']) onDragEnd(event: any) {
    event.preventDefault();
  }

  @HostListener('dragleave', ['$event']) onDragLeave(event: any) {
    event.preventDefault();
  }
  @HostListener('drop', ['$event']) onDrop(event: any) {
    event.preventDefault();
    event.stopPropagation();
    const files = event.dataTransfer.files;
    if (!this.isMobile && !this.isTablet) {
      this.onFileSelect(files, true);
    } else {
      event.preventDefault();
      event.stopPropagation();
    }
  }

  onFileSelect(event: any, isDrag?: boolean) {
    this.errors = [];
    this.files = isDrag ? event : event.target.files;
    if (this.files.length > 0 && (!this.isValidFiles(this.files))) {
      this.showUploadBtn = false;
    } else {
      this.showUploadBtn = true;
      this.fileName = this.files[0].name.toLowerCase();
      this.selectedFile = this.files[0];
    }
  }
  removeFile() {
    this.fileName = '';
    this.showUploadBtn = false;
    if (this.fileInput.nativeElement) {
      this.fileInput.nativeElement.value = '';
    }
  }

  getDocuments() {
    this.service.getDocuments(this.data.practice_org, this.data.patientId, this.data.id, surgeryDocumentType.surgeryDocument).subscribe({
      next: (res) => {
        this.ELEMENT_DATA = res.data;
        this.dataSource.data = this.ELEMENT_DATA;
        this.dataSource.sort = this.sort;
        this.removeFile();
        this.showLoader = false;
      },
      error: (error) => {
        this.ELEMENT_DATA = [];
        this.dataSource.data = this.ELEMENT_DATA;
        this.removeFile();
        this.showLoader = false;
        if(error && error.includes('no documents uploaded')) {
          this.errors = [];
        } else {
          this.errors = [];
          this.errors.push('An error occured when uploading your file.Please try again.');    
        }
        
      }
    });
  }

  deleteElement(documentId: any) {
    this.showLoader = true;
    this.service.deleteDocument(this.data.practice_org,this.data.patientId, this.data.id, documentId).subscribe({
      next: (res) => {
        if(res.statusCode === 200) {
          this.ELEMENT_DATA = this.ELEMENT_DATA.filter((el: any) => el.documentId !== documentId);
          this.dataSource.data = this.ELEMENT_DATA;
          this.showLoader = false;
          this.areFilesChanged = true;
          this.errorAlertDialog(res.message);
        } else {
          this.showLoader = false;
          this.errorAlertDialog('An error occured when deleting your file.Please try again.');
        }
        this.hasUpdate = true;
      },
      error: (error) => {
        this.showLoader = false;
        this.errorAlertDialog('An error occured when deleting your file.Please try again.');
      }
    });
  }

  saveFiles() {
    this.errors = [];
    if (this.selectedFile) {
      this.showLoader = true;
      this.service.uploadDocument(this.data.practice_org,this.data.patientId ,this.data.id, this.selectedFile).subscribe({
        next: (res) => {
          if (res.statusCode === 201)
          {
            this.areFilesChanged = true;
            this.getDocuments();
          } else {
            this.errors = [];
            this.errors.push('An error occured when uploading your file.Please try again.');
            this.removeFile();    
            this.showLoader = false;
          }
          this.hasUpdate = true;
        },
        error: (err: any) => {
          this.errors = [];
          this.errors.push('An error occured when uploading your file.Please try again.');
          this.removeFile();
          this.showLoader = false;    
        }
      });
    }
  }

  errorAlertDialog(msg:any) {
    const dialogRef = this.dialog.open(AlertDialogComponent,{
      data:{
        message: msg,
        buttonText: {
          cancel: 'Ok'
        }
      },
    }).afterClosed().subscribe(() => {
      this.closePopup();
    });
  }

  private isValidFiles(files: any) {
    this.isValidFileExtension(files);
    return this.errors.length === 0;
  }

  private isValidFileExtension(files: any) {
    // Make array of file extensions
    const extensions = (this.fileExt.split(','))
      .map(function (x) { return x.toLocaleUpperCase().trim() });

    for (let i = 0; i < files.length; i++) {
      // Get file extension
      const ext = files[i].name.toUpperCase().split('.').pop() || files[i].name;
      // Check the extension exists
      const exists = extensions.includes(ext);
      if (!exists) {
        if (this.errors.length > 0) {
          this.errors.forEach((el) => {
            if (!el.includes('format'))
              this.errors.push('File is not in an allowed format.');
          });
        } else {
          this.errors.push('File is not in an allowed format.');
        }
        if (this.fileInput.nativeElement) {
          this.fileInput.nativeElement.value = ''; // Clear the file input
        }
      }
      // Check file size
      this.isValidFileSize(files[i]);
    }
  }

  private isValidFileSize(file: any) {
    const fileSizeinMB = file.size / (1024 * 1000);
    const size = Math.round(fileSizeinMB * 100) / 100; // convert upto 2 decimal place
    if (size > this.maxSize) {
      if (this.errors.length > 0) {
        this.errors.forEach((el) => {
          if (!el.includes('5 MB'))
            this.errors.push('File exceeded the 5 MB limit.');
        });
      } else {
        this.errors.push('File exceeded the 5 MB limit.');
      }
      if (this.fileInput.nativeElement) {
        this.fileInput.nativeElement.value = ''; // Clear the file input
      }
    }
  }

  handleFile(url: string, name: string){
    this.sharedService.viewOrDownloadDocument(url, name);
  }

}
