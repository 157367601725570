import { Component } from '@angular/core';

@Component({
  selector: 'app-account-inactive',
  templateUrl: './account-inactive.component.html',
  styleUrls: ['./account-inactive.component.scss']
})
export class AccountInactiveComponent {

}
