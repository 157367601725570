export interface iolData{
    iolManufacturer: string;
    lensType: string;
    lensModel: string;
    diopter: string;
    lensPriority: lensPriority;
}

export enum lensPriority{
    primary = 'PRI',
    duplicate = 'DOP', 
    alternate = 'ALT', 
    actuator = 'ACH', 
    capsular = 'CAP', 
    sulcus = 'SUL'
}
export const lensPriorityDisplay:{[key:string]:string} = {
    [lensPriority.primary] : 'Primary',
    [lensPriority.duplicate] : '2nd Primary',
    [lensPriority.alternate] : 'Alternate',
    [lensPriority.actuator] : 'A/C',
    [lensPriority.capsular] : 'Capsular',
    [lensPriority.sulcus] : 'Sulcus',
}
export const lensPriorityDisplayOrder:{[key:string]:number} = {
    [lensPriority.primary] : 1,
    [lensPriority.duplicate] : 2,
    [lensPriority.alternate] : 6,
    [lensPriority.actuator] : 5,
    [lensPriority.capsular] : 3,
    [lensPriority.sulcus] : 4,
}

export interface lensDbResponse {
    data: lensModelRes[],
    message: string,
    statusCode: number
}

export interface lensModelRes {
    description: string,
    familyType: string,
    id: number,
    lensDetails: lensDetails[],
    lensType: string,
    manufacturer: string,
    name: string
}

export interface lensDetails {
    model: string,
    powerRange: powerRange[]
}

export interface powerRange {
    high: number,
    id: number,
    increment: number,
    isActive: boolean,
    low: number
}

export interface alconLensGroupSetManufacturer{
    [key: string]: alconLensGroupManufacturer
}
export interface alconLensGroupManufacturer{
    type: alconLensGroupSetModelType,
    value: string
}
export interface alconLensGroupSetModelType{
    [key: string]: alconLensGroupModelType
}
export interface alconLensGroupModelType{
    model: alconLensGroupSetModel,
    value: string
}
export interface alconLensGroupSetModel{
    [key: string]: alconLensGroupModel
}
export interface alconLensGroupModel{
    powers: string[],
    value: string,
    description: string
}
export interface lensTypeNames {
    id: number,
    name: string,
}