import { Component } from '@angular/core';

@Component({
  selector: 'app-account-pending',
  templateUrl: './account-pending.component.html',
  styleUrls: ['./account-pending.component.scss']
})
export class AccountPendingComponent {

}
