import { Injectable } from '@angular/core';
import { alconLensGroupManufacturer, alconLensGroupSetManufacturer, alconLensGroupSetModel, alconLensGroupSetModelType, lensModelRes, powerRange } from '../../model/iol.model';
import { hiddenPayload, IOLManufacturer, lensModel, lensType } from 'src/app/pages/iol-model-preferences/iol-model-preferences.model';

@Injectable({
  providedIn: 'root'
})
export class LensService {

  constructor() { }


  public groupLensDataForPreference(lensDbData: lensModelRes[]): IOLManufacturer[]{
    const manufacturerGroup: IOLManufacturer[] = [];
    const groupedLensData = this.groupLensData(lensDbData);
    for(const key in groupedLensData){
      manufacturerGroup.push({
        name: groupedLensData[key]?.value,
        type: this.getLensModelTypeForPreference(groupedLensData[key].type)
      })
    }
    return manufacturerGroup;
  }

  private getLensModelTypeForPreference(modelTypes: alconLensGroupSetModelType){
    const _modelTypes = [];
    for(const key in modelTypes){
      _modelTypes.push({
        name: modelTypes[key].value,
        lens: this.getLensModelForPreference(modelTypes[key].model)
      })
    }

    return _modelTypes;
  }

  private getLensModelForPreference(models: alconLensGroupSetModel){
    const _models = [];
    for(const key in models){
      _models.push({
        name: models[key].value,
        description: models[key].description,
        isVisible: true
      })
    }

    return _models;
  }

  public groupLensData(lensData: lensModelRes[]): alconLensGroupSetManufacturer{
    const lensCollection: alconLensGroupSetManufacturer = {};
    lensData.forEach(item => {
      if(lensCollection[item.manufacturer]){
        if(!lensCollection[item.manufacturer].type[item.lensType]){
          lensCollection[item.manufacturer].type[item.lensType] = {
            value: item.lensType,
            model: {}
          }
        }
      }else{
        lensCollection[item.manufacturer] = {
          value: item.manufacturer,
          type: {
            [item.lensType]: {
              value: item.lensType,
              model: {}
            }
          }
        }
      }
      item.lensDetails.forEach(lensModel=>{
        if(lensCollection[item.manufacturer].type[item.lensType].model[lensModel.model]){
          const model = lensCollection[item.manufacturer].type[item.lensType].model[lensModel.model]
          model.powers = this.generateIolPowerListForDisplay(model.powers, lensModel.powerRange)
          model.description = item.description
        }else{
          lensCollection[item.manufacturer].type[item.lensType].model[lensModel.model] = {
            value: lensModel.model,
            powers: this.generateIolPowerListForDisplay([], lensModel.powerRange),
            description: item.description
          }
        }
      })
    })
    return lensCollection;
  }

  private generateIolPowerListForDisplay(existingList: string[], rangeList: powerRange[]){
    rangeList.forEach(range => {
      if(range)
      {
        let increment = Number(range.increment),
            high = Number(range.high),
            low = Number(range.low);

        for (let index = low; index <= high; index += increment)
        {
          const diopterValue = this.formatDiopter(index)
          if (!existingList.includes(diopterValue))
          {
            existingList.push(diopterValue);
          }
        }
      }
    })
    return existingList;
  }

  private formatDiopter(power: number) {
    const sign = power < 0 ? "-" : "+";
    const absValue = Math.abs(power);
    const [integerPart, decimalPart] = absValue.toString().split(".");
    const paddedInteger = integerPart.padStart(2, '0');       // Eg. 01
    return `${sign}${paddedInteger}.${decimalPart || '0'}`;   // Eg. +01.5
  }

  toCreatehiddenHashValues(hidePreference: hiddenPayload[]){
    const manufacturerDataRef:string[] = [];
    const fieldSeparator = "---";
    hidePreference.forEach(pref => {
      const prefHashString = pref.manufacturer + fieldSeparator + pref.type + fieldSeparator + pref.model;
      manufacturerDataRef.push(prefHashString)
    });
    return manufacturerDataRef;
  }

  toDeleteHiddenModel(manufacturerData: any, manu: string, type: string, manufacturerDataRef: string[]){
    for(const model in manufacturerData[manu].type[type].model){
      if(this.isHiddenModel(manufacturerDataRef, manu, type, model)){
        delete manufacturerData[manu].type[type].model[model];
      }
    }
    //To delete the type, if it doesnt contains any models
    if(this.isObjectEmpty(manufacturerData[manu].type[type]?.model)){
      delete manufacturerData[manu].type[type];
    }
  }

  public removeHiddenLens(manufacturerData: any, hidePreference: hiddenPayload[]){
    const manufacturerDataRef = this.toCreatehiddenHashValues(hidePreference);
    const fieldSeparator = "---";
    const allSelector = "*";
    
    //To check if all the items are hidden
    if(manufacturerDataRef.includes( allSelector + fieldSeparator + allSelector + fieldSeparator + allSelector)){
        return {};
    }

    for(const manu in manufacturerData){

      if(this.isHiddenModel(manufacturerDataRef, manufacturerData[manu].value)){
        delete manufacturerData[manu];
      }else{
        for(const type in manufacturerData[manu].type){
          if(this.isHiddenModel(manufacturerDataRef, manufacturerData[manu].value, manufacturerData[manu].type[type].value)){
            delete manufacturerData[manu].type[type];
          }else{
            this.toDeleteHiddenModel(manufacturerData, manu, type, manufacturerDataRef);
          }
        }
          //To delete the manufacturer, if it doesnt contains any models
          if(this.isObjectEmpty(manufacturerData[manu].type)){
              delete manufacturerData[manu];
          }
        }
      }
    return manufacturerData;
  }

  isObjectEmpty(obj: object): boolean {
    return Object.keys(obj).length === 0;
  }

  getPreHashString(manufacturer: string, type?: string, model?: string){
    const fieldSeparator = "---";
    const allSelector = "*";

    if(manufacturer && type && model){
      return manufacturer + fieldSeparator + type + fieldSeparator + model;
    } else if(manufacturer && type){
      return manufacturer + fieldSeparator + type + fieldSeparator + allSelector;
    }
    return manufacturer + fieldSeparator + allSelector + fieldSeparator + allSelector;
  }

  isHiddenModel(manufacturerDataRef: string[], manufacturer: string, type?: string, model?: string){
   
    if(manufacturerDataRef.includes(this.getPreHashString(manufacturer, type, model))){
      return true;
    }
    return false;
  }

  public tagVisibilityPreferenceStatus([...manufacturerData]: IOLManufacturer[], hidePreference: hiddenPayload[]){
    if(!hidePreference?.length){
      return manufacturerData;
    }

      const fieldSeparator = "---";
      const allSelector = "*";
      const manufacturerDataRef = this.toCreatehiddenHashValues(hidePreference);

      manufacturerData.forEach(manufacturer => {
        manufacturer.type.forEach(type => {
          type.lens.forEach(model => {
            const prefHashStringModel = manufacturer.name + fieldSeparator + type.name + fieldSeparator + model.name;
            const prefHashStringType = manufacturer.name + fieldSeparator + type.name + fieldSeparator + allSelector;
            const prefHashStringManufacturer = manufacturer.name + fieldSeparator + allSelector + fieldSeparator + allSelector;
            const prefHashStringHideAll =  allSelector + fieldSeparator + allSelector + fieldSeparator + allSelector;
            if(
              manufacturerDataRef.includes(prefHashStringModel) ||
              manufacturerDataRef.includes(prefHashStringType) ||
              manufacturerDataRef.includes(prefHashStringManufacturer) ||
              manufacturerDataRef.includes(prefHashStringHideAll)
            ){
              model.isVisible = false;
            }
          })
        })
      })
    return manufacturerData;

  }
}
