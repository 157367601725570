import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ClickElsewhereDirective } from './click-elsewhere.directive';



@NgModule({
  declarations: [
    ClickElsewhereDirective
  ],
  imports: [
    CommonModule
  ],
  exports:[
    ClickElsewhereDirective
  ]
})
export class ClickElsewhereModule { }
