import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
  selector: 'app-surgery-iolspecs-duplicate-dialog',
  templateUrl: './surgery-iolspecs-duplicate-dialog.component.html',
  styleUrls: ['./surgery-iolspecs-duplicate-dialog.component.scss'],
})
export class SurgeryIolspecsDuplicateDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<SurgeryIolspecsDuplicateDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public router: Router
  ) {}

  closePopup() {
    this.dialogRef.close();
  }

  proceedWithDuplicateIol(selectedOption: string) {
    this.dialogRef.close(selectedOption);
  }
}
