<div 
class="alcon-or-header-container d-flex align-items-center justify-content-space-between"
[ngClass]="(oreService.isDarkModeEnabled | async) ? 'alcon-ore-header-dark' : 'alcon-ore-header-light'"
>
  <div class="alcon-or-header-logo d-flex align-items-center">
    <img src="assets/adi-logo-white.svg" class="alcon-logo" />
    <span class="alcon-account-name">{{ accountName }} </span>
  </div>
  <div class="alcon-or-header-toggle d-flex align-items-center">
    <mat-icon class="alcon-ore-theme-icon" svgIcon="dark_light_theme"></mat-icon>
    <span class="alcon-or-header-mode-label"> Dark Mode</span>
    <alcon-toggle-switch
      class="alcon-ore-mode-selector"
      [selected]="(oreService.isDarkModeEnabled | async) ?? true"
      (selectionChanged)="onModeToggle($event)"
      [hideButtonStateLabel]="true"
      [scaleValue]="0.7"
    ></alcon-toggle-switch>
    <button class="alcon-exit-ore-btn" [ngClass]="{ 'alcon-all-times-recorded': oreService.allTimesRecorded| async}" (click)="exitORClicked()">
      Exit the OR Experience
      <mat-icon class="alcon-ore-logout-icon" svgIcon="logout_icon"></mat-icon>
    </button>
  </div>
</div>
