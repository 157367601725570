import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from "./auth.service";
import { DeviceService } from './device.service';
import { NavigationService } from './service/navigation.service';
import { SharedService } from './service/shared.service';
import { PermissionService } from './service/permission.service';

@Injectable({
  providedIn: 'root'
})
export class PageaccessGuard  {
  constructor(
    public authService: AuthService,
    public router: Router,
    private deviceService: DeviceService,
    public navigationService: NavigationService,
    private sharedService: SharedService,
    private permissionService: PermissionService
  ) { 
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      if(this.authService.isLoggedIn)
      {
        let permission = this.permissionService.hasPageAccess(route.data['permission']);
        if (permission !== true) 
        { 

          if(this.authService.isAccountSwitched){
            this.authService.firstLogin();
          }else{
            this.navigationService.navigateTo(['access-denied']);
          }
        }else if(this.deviceService.getDeviceType() !== "desktop" && this.authService.isAdminGroup(route.data['permission'])){
          this.authService.showDesktopOnlyModel();
          return false;
        }
        this.authService.isAccountSwitched = false;
        return true;
      }
      else
      {
        this.sharedService.saveRevisitedUrls(route, state);

        this.navigationService.navigateTo(['']);
        return true;
      }
  }
  
}
