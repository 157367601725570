<div class="alc_tab-navigation">
    <div class="container" *ngIf="surgeriesFound && !surgerySummary">
        <div class="tab-menus">
            <ul>
                <li *ngFor="let menu of menuItems">
                    <a *ngIf="getCheckMenuPermission(menu.name) && dateRangeFilterSaved.startDate && dateRangeFilterSaved.endDate"
                    href="#"
                    [routerLink]="['/'+currentLocale+'/'+menu.route]"
                    [queryParams]="{startDate:dateRangeFilterSaved.startDate, endDate:dateRangeFilterSaved.endDate}"
                    routerLinkActive="active" [routerLinkActiveOptions]="myMatchOptions"
                    >{{menu.label}} {{ menu.count && menu.count > 0 ? '('+menu.count+')' : ''}}</a>

                    <a *ngIf="getCheckMenuPermission(menu.name) && dateRangeFilterSaved.startDate=='' && dateRangeFilterSaved.endDate==''"
                    href="#"
                    [routerLink]="['/'+currentLocale+'/'+menu.route]"
                    routerLinkActive="active" [routerLinkActiveOptions]="myMatchOptions"
                    >{{menu.label}} {{ menu.count && menu.count > 0 ? '('+menu.count+')' : ''}}</a>
                </li>
            </ul>
        </div>
    </div>
    <div class="container" *ngIf="surgeriesFound && surgerySummary">
        <div class="tab-menus">
            <ul>
                <li *ngFor="let menu of menuItems">
                    <a *ngIf="getCheckMenuPermission(menu.name)"
                    (click)="selectTabs(menu.name)" 
                    [ngClass]="{active : (menu.name === surgeryTabName)}"
                    >{{menu.label}} {{ menu.count && menu.count > 0 ? '('+menu.count+')' : ''}}</a>
                </li>
            </ul>
        </div>
    </div>
</div>

