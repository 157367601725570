import { APP_INITIALIZER, NgModule, isDevMode } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';

import { AuthInterceptor } from './shared/authconfig.interceptor';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './pages/login/login.component';
import { HomeComponent } from './pages/home/home.component';
import { HeaderComponent } from './components/header/header.component';
import { AuthButtonComponent } from './components/auth-button/auth-button.component';

import { OktaAuthModule, OktaConfig } from '@okta/okta-angular';
import { OktaAuth } from '@okta/okta-auth-js';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TabNavigationModule } from './components/tab-navigation/tab-navigation.module';
import { PageHeaderModule } from './components/page-header/page-header.module';
import { ErrorInterceptInterceptor } from './shared/error-intercept.interceptor';
import { FormComponent } from './pages/form/form.component';
import { AccountInactiveComponent } from './pages/account-inactive/account-inactive.component';
import { AccountPendingComponent } from './pages/account-pending/account-pending.component';
import { AccessDeniedComponent } from './pages/access-denied/access-denied.component';
import { AccountUnassignedComponent } from './pages/account-unassigned/account-unassigned.component';
import { ConfirmDialogComponent } from './components/confirm-dialog/confirm-dialog.component';
import { MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/dialog';
import { MatDialogModule } from '@angular/material/dialog';
import { PatientConfirmDialogComponent } from './components/patient-confirm-dialog/patient-confirm-dialog.component';
import { AlertDialogComponent } from './components/alert-dialog/alert-dialog.component';
import { PageNotFoundComponent } from './pages/page-not-found/page-not-found.component';
import { NgxEditorModule } from 'ngx-editor';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { LoaderModule } from './components/loader/loader.module';
import {MatCardModule} from '@angular/material/card';
import { DatePipe } from '@angular/common';
import { FileUploadComponent } from './components/file-upload/file-upload.component';
import { MatIconModule } from '@angular/material/icon';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { AdminUxDialogModule } from './components/admin-ux-dialog/admin-ux-dialog.module';
import { MatTabsModule } from '@angular/material/tabs';
import {MatSelectModule} from '@angular/material/select';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { ClickElsewhereModule } from './shared/click-elsewhere/click-elsewhere.module';
import { SectionLoaderModule } from './components/section-loader/section-loader.module';
import { RouteReuseStrategy } from '@angular/router';
import { CustomRouteReuseStrategy } from './shared/CustomRouteReuseStrategy';
import { SurgeryIolspecsDuplicateDialogComponent } from './components/surgery-iolspecs-duplicate-dialog/surgery-iolspecs-duplicate-dialog.component';
import {NgIdleModule} from '@ng-idle/core';
import { CustomDialogComponent } from './components/modal-dialogs/custom-dialog/custom-dialog.component';
import { MatMenuModule } from '@angular/material/menu';
import { SummaryDialogComponent } from './components/modal-dialogs/summary-dialog/summary-dialog.component';
import {AdiFooterModule} from '@alcon/adi-footer-lib';
import { adiStorageKey } from './shared/constants/auth.constant';
import { AlconBannerComponent } from "./shared/alcon-banner/alcon-banner.component";
import { CustomOktaCallbackComponent } from './pages/okta-callback/custom-okta-callback/custom-okta-callback.component';
import { AlconAccountSwitchPopupModule } from '@alcon/account-switch-popup';


const currentLocale = localStorage.getItem(adiStorageKey.lang) ?? 'en_US';

const authConfig = {
  issuer: environment.issuer,
  clientId: environment.clientId,
  redirectUri: window.location.origin + environment.baseHref + '/login/callback',
  postLogoutRedirectUri: `${window.location.origin}/${currentLocale}/login`,
  scopes: ['profile', 'openid', 'email', 'offline_access'],
  tokenManager: {
    storage: 'cookie',
    storageKey: environment.clientId
  },
  cookies: {
    secure: true,
  }
};
const oktaAuth = new OktaAuth(authConfig);
const moduleConfig: OktaConfig = { oktaAuth };

@NgModule({
    declarations: [
        AppComponent,
        LoginComponent,
        HomeComponent,
        AuthButtonComponent,
        FormComponent,
        AccountInactiveComponent,
        AccountPendingComponent,
        AccessDeniedComponent,
        AccountUnassignedComponent,
        ConfirmDialogComponent,
        PatientConfirmDialogComponent,
        AlertDialogComponent,
        PageNotFoundComponent,
        FileUploadComponent,
        SurgeryIolspecsDuplicateDialogComponent,
        CustomDialogComponent,
        SummaryDialogComponent,
        CustomOktaCallbackComponent
    ],
    providers: [
        DatePipe,
        { provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: { hasBackdrop: false } },
        { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptInterceptor, multi: true, },
        { provide: RouteReuseStrategy, useClass: CustomRouteReuseStrategy }
    ],
    bootstrap: [AppComponent],
    imports: [
        BrowserModule,
        FormsModule,
        MatDialogModule,
        ReactiveFormsModule,
        HttpClientModule,
        PageHeaderModule,
        TabNavigationModule,
        AppRoutingModule,
        NgxEditorModule,
        OktaAuthModule.forRoot(moduleConfig),
        BrowserAnimationsModule,
        MatDatepickerModule,
        MatNativeDateModule,
        LoaderModule,
        MatCardModule,
        MatIconModule,
        MatTableModule,
        MatSortModule,
        AdminUxDialogModule,
        MatSnackBarModule,
        NgMultiSelectDropDownModule.forRoot(),
        ClickElsewhereModule,
        SectionLoaderModule,
        MatSelectModule,
        NgIdleModule.forRoot(),
        MatMenuModule,
        AlconBannerComponent,
        HeaderComponent,
        AdiFooterModule,
        AlconAccountSwitchPopupModule
    ]
})
export class AppModule {}
