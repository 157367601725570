<page-header *ngIf="showLogin" [pageTitle]="'Login'"></page-header>
<tab-navigation></tab-navigation>
<div class="alc_grid" *ngIf="showLogin">
    <div class="container">
        <div class="alc_grid-wrapper">
              <div class="row login-container">
                <div class="col-sm-5 col-xs-12">
                    <mat-card class="login-card">
                        <mat-card-header>
                            <mat-card-title class="login-card-title">
                                <p>Welcome</p>
                            </mat-card-title>
                        </mat-card-header>
                        <mat-card-content class="login-card-content">
                            <p>Gain access to Alcon Clinic Connect Platform and manage patients and surgery related actions for your facility.</p>
                        </mat-card-content>
                        <mat-card-actions>
                            <button type="button" class="alc_button alc_button--primary alc_button--icon alc_button--block" (click)="login()">
                                Login
                            </button>
                        </mat-card-actions>
                    </mat-card>
                </div>
                <div class="col-sm-1 col-xs-0"></div>
                <div class="col-sm-6 col-xs-12">
                    <mat-card class="login-card contact-info">
                        <mat-card-header>
                            <mat-card-title class="login-card-title">
                                <p>Need help?</p>
                            </mat-card-title>
                        </mat-card-header>
                        <mat-card-content class="login-card-content">
                            <div>
                                <p><b>Contact us:</b></p>
                                <span>Surgical Customer Service</span> <br/>
                                <span><a href="tel:1-800-862-5266">1-800-862-5266</a></span> <br/>
                                <span><a href="mailto:us.customercare@alcon.com">us.customercare&#64;alcon.com</a></span>
                            </div>
                        </mat-card-content>
                    </mat-card>
                </div>
              </div>
        </div>
    </div>
</div>