import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouteReuseStrategy, DetachedRouteHandle } from '@angular/router';

@Injectable()
export class CustomRouteReuseStrategy extends RouteReuseStrategy {
    // all other methods will be the same as the default
    shouldDetach(route: ActivatedRouteSnapshot): boolean {
        return false;
    }
    store(route: ActivatedRouteSnapshot, handle: DetachedRouteHandle): void {}
    shouldAttach(route: ActivatedRouteSnapshot): boolean {
        return false;
    }
    retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle | null {
        return null;
    }
    
    /*
    * Determines if a route should be reused.
    * This strategy returns `true` when the future route config and current route config are identical.
    * 
    * but we want to refresh the route if the data.shouldReuse is true in the route definition
    * */
    shouldReuseRoute(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {
        // so we need to check if it is true. if not, we return the default behavior
        return future.data?.['shouldReuse'] ? false : future.routeConfig === curr.routeConfig;
    }
}
