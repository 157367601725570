import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
  selector: 'app-patient-confirm-dialog',
  templateUrl: './patient-confirm-dialog.component.html',
  styleUrls: ['./patient-confirm-dialog.component.scss']
})
export class PatientConfirmDialogComponent {

  title: string = "Patient was successfully added!"
  message: string = "Would you like to keep adding patients or go to View Patients?"
  YesButtonText = "Add Patient"
  NoButtonText = "View Patients"
  
  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any,
    private dialogRef: MatDialogRef<PatientConfirmDialogComponent>) {
    if(data)
    { 
          this.title = data.title || this.title;
          this.message = data.message || this.message;
          if (data.buttonText) 
          {
            this.YesButtonText = data.buttonText.yes || this.YesButtonText;
            this.NoButtonText = data.buttonText.no || this.NoButtonText;
          }
    }
  }

  clickOnYes(): void 
  {
    this.dialogRef.close(false);
  }

  
  clickOnNo(): void 
  {
    this.dialogRef.close(true);
  }

}
 