<div class="alcon-ore-history-item d-flex" [ngClass]="(oreService.isDarkModeEnabled | async) ? 'alcon-ore-history-dark' : 'alcon-ore-history-light'">
  <div class="alcon-ore-history-item-field-wrapper">
    <span class="alcon-ore-history-item-label">{{ history?.field }}:&nbsp;</span>
    <div class="alcon-ore-history-item-field d-flex">
      <span class="alcon-ore-history-item-old-value name-truncate-in-three-lines">{{ history?.oldValue }}</span>
      <mat-icon class="alcon-ore-history-item-arrow" svgIcon="long_arrow_forward"></mat-icon>
      <span class="alcon-ore-history-item-new-value name-truncate-in-three-lines">{{ history?.newValue }}</span>
    </div>
  </div>
  <div class="alcon-ore-history-item-date">{{ history?.date }}</div>
  <div class="alcon-ore-history-item-name">{{ history?.name }}</div>
</div>
