<mat-form-field class="alc_mat-form-field-outlined" >
    <mat-select [formControl]="searchableMultiSelectControl" multiple (openedChange)="close($event)"     
                panelClass="alc_list-select-container"
                placeholder="All model types"
                (selectionChange)="onSelectionChange($event)"
                #searchableSelect="matSelect"
    >
      <mat-select-trigger>
        <ng-container [ngTemplateOutlet]="selected" >
        </ng-container>
      </mat-select-trigger>
      <ng-container [ngTemplateOutlet]="searchBox" >
      </ng-container>
      @if(selectedQueryMatch > 0){
        <div class="alcon-select-group">Selected model types
            <a class="alcon-navigation-link cursor-pointer" (click)="clearAll()">Clear all</a>
                
            </div>
        @for (option of selectedData; track option) {
          <mat-option [value]="option" [hidden]="option['isSearchNotMatching']">
              <ng-container [ngTemplateOutlet]="dropdownOptions" [ngTemplateOutletContext]="{dropdownOptions: option}">
              </ng-container>
          </mat-option>
        }
      }
      @if(unSelectedSurgeonQueryMatch > 0){
          <div class="alcon-select-group">All model types</div>
          @for (option of allMultiDropdownData; track option) {
            <mat-option [value]="option" [hidden]="option['isSelected'] || option['isSearchNotMatching']">
                <ng-container [ngTemplateOutlet]="dropdownOptions" [ngTemplateOutletContext]="{dropdownOptions: option}">
                </ng-container>
            </mat-option>
          }
      }
    </mat-select>
  </mat-form-field>
 
 

<ng-template #selected>
    @if ((searchableMultiSelectControl.value?.length || -1) > 0) {
        <span class="label-additional-selection">
          {{(searchableMultiSelectControl.value?.length || 0)}} {{searchableMultiSelectControl.value?.length === 1 ? 'model type': 'model types'}} selected
        </span>
      }
</ng-template>

<ng-template #searchBox>
    <div class="alc_input-wrapper alc_input-wrapper--search">
        <input
          type="text"
          class="alc_input-text alc_input-text--search"
          placeholder="Search"
          [(ngModel)]="searchKey"
          (ngModelChange)="searchOptions($event)"
        />
        <mat-icon class="search-close-icon" *ngIf="searchKey"
         aria-hidden="false" aria-label="close icon" fontIcon="close" (click)="resetSearch()"></mat-icon>
      </div> 
</ng-template>

<ng-template #dropdownOptions let-dropdownOptions="dropdownOptions">
    <div class="alcon-option-name">{{dropdownOptions.name}}</div>
</ng-template>