import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject, map } from 'rxjs';
import { PatientGraphqlService } from 'src/app/shared/service/alcon-graphql/patient-graphql.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PatientService {
  
  constructor(
    private patientGraphqlService: PatientGraphqlService
  ) { }


  public getPatientDetails(patientId: string){
    return this.patientGraphqlService.getPatientDetailsGraphQuery({
      limit: 1,
      offset:0,
      patientId: patientId
    }).pipe(map((res)=>(res?.data?.patientResponse?.patients?.length>0)? res?.data?.patientResponse?.patients[0] : null));
  }

  public getPatientById(patientId: string) {
    return  this.patientGraphqlService.getPatientDetailsGraphQuery({
      patientId: patientId
    }).pipe(map((res)=>(res?.data?.patientResponse?.patients?.length>0)? res?.data?.patientResponse?.patients[0] : null));
  }
}
