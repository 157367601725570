<div class="alcon-unassigned-account-container">
    <h1>We've Moved!</h1>
    <p class="alcon-unassigned-account-content">Thank you for visiting. Our website has moved to a new home.</p>
    <img src="assets/images/account-unassigned.png" alt="Account Unassigned">
    <div>
        <p class="alcon-unassigned-account-content">You will be redirected to the new site in {{timer}} seconds...</p>
        <p class="alcon-unassigned-account-content">If the page doesn't redirect, please click the button below.
            <button type="button" class="alc_button alc_button--primary alcon-unassigned-account-button mt-2"
                (click)="gotoAdiHome()">Go to Adi homepage</button>
        </p>
    </div>
</div>