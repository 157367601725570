import { Component, EventEmitter, Input, Output, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/shared/auth.service';
import { FilterDateRange } from 'src/app/model/dateRange';
import { SurgeonService } from 'src/app/shared/surgeon.service';
import { AlertDialogComponent } from '../alert-dialog/alert-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { SharedService } from 'src/app/shared/service/shared.service';
import { Subscription } from 'rxjs';
import { userRoles } from 'src/app/shared/constants/auth.constant';
import { SurgeryService } from 'src/app/shared/surgery.service';

@Component({
  selector: 'page-header',
  templateUrl: './page-header.component.html',
  styleUrls: ['./page-header.component.scss']
})
export class PageHeaderComponent implements OnInit, OnDestroy{
  @Input() pageTitle: string | undefined;
  @Input() add_new_url: string | undefined;
  @Input() add_new_title: string | undefined;
  enablePastDates: boolean = true;
  AccountFacilityID: any=""
  facSubscription: Subscription = new Subscription;

  @Input()dateRangeFilter: FilterDateRange= {startDate:"", endDate:""};

  @Output() onAddPageChange: EventEmitter<any> = new EventEmitter();
  @Output() dateRangeSelected = new EventEmitter<FilterDateRange>();
  @Output() enableLoading = new EventEmitter<string>();
  @Output() surgeryDetails = new EventEmitter<any>();
  dateRangeError = false;

  constructor(public router: Router,
    private authService: AuthService,
    public surgeonservice: SurgeonService,
    private dialog: MatDialog,
    public sharedService: SharedService,
    public surgeryService: SurgeryService
    ) {
    }

  ngOnInit(): void {
    
  }


  ngOnChanges(): void {
  }

  addForm()
  {
    this.onAddPageChange.emit();
  }


  
  showAddNewTitle(): boolean {
    const userRole: string = this.authService.getLoggedInUserRole();
    if (this.add_new_title) {
      if(this.add_new_title === 'Add Surgeon') {
        if(userRole === userRoles.superAdmin || userRole === userRoles.clinicAdmin) {
          return true;
        } else {
          return false;
        }
      } else if(this.add_new_title === 'Add User') {
        if(userRole === userRoles.superAdmin) {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    } else {
      return false;
    }
  }

  errorAlertDialog(msg:any) {
    const dialogRef = this.dialog.open(AlertDialogComponent,{
      data:{
        message: msg,
        buttonText: {
          cancel: 'Ok'
        }
      },
    });
  }



  ngOnDestroy() {
    this.facSubscription.unsubscribe();
  }
}
