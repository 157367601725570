import { Inject, Injectable } from "@angular/core";
import { HttpInterceptor, HttpRequest, HttpHandler, HttpHeaders, HttpEvent, HttpResponse } from "@angular/common/http";
import { environment } from '../../environments/environment';
import { OKTA_AUTH } from '@okta/okta-angular';
import { OktaAuth } from '@okta/okta-auth-js';
import { Router } from "@angular/router";
import { JwtDecodeService } from "./service/jwt-decode.service";
import { facility } from "./constants/auth.constant";
import { adiStorageKey } from "./constants/auth.constant";
import { AuthService } from "./auth.service";
import { tap } from "rxjs";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    constructor(@Inject(OKTA_AUTH) private oktaAuth: OktaAuth, public router: Router, private authService: AuthService,
        private jwtDecodeService: JwtDecodeService
    ) { }

    intercept(req: HttpRequest<any>, next: HttpHandler) {
        if (!req.url.includes('/logging')) {
            const authToken = this.oktaAuth.getAccessToken();

            if (authToken && this.jwtDecodeService.sameClientId(authToken)) {
                req = req.clone({
                    setHeaders: {
                        Authorization: "Bearer " + authToken,
                        selectedPracticeOrg: this.authService.practiseOrg,
                        selectedPracticeType: this.authService.accountType
                    },
                });
            }
        }
        return next.handle(req);
    }
}