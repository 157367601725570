import { Injectable } from '@angular/core';
import { AuthService } from '../auth.service';
import { accessType, pageAccess, pagePermission, permissionSetValue, userRoles, facility } from '../constants/auth.constant';
import { config } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PermissionService {

 appPermission: { [key: string]: pageAccess } = {};

 private appRenderPermission:any = {
  [pagePermission.surgeries]: {path: ["surgeriesDash", "surgeryMgmt"], isUserManagerFeature: false, isSuperAdminFeature: false},
  [pagePermission.patient]: {path: ["surgeriesDash", "surgeryMgmt"], isUserManagerFeature: false, isSuperAdminFeature: false},
  [pagePermission.surgeon]: { isUserManagerFeature: true, isSuperAdminFeature: true},
  [pagePermission.forms]: { facilityType: facility.clinic, isUserManagerFeature: true, isSuperAdminFeature: true},
  [pagePermission.iolModel]: { facilityType: facility.clinic, isUserManagerFeature: true, isSuperAdminFeature: true},
  [pagePermission.asc]: {isSuperAdminFeature: true},
  [pagePermission.clinics]: {isSuperAdminFeature: true}
 }

constructor(public authService: AuthService) { 

    for (const key in pagePermission) {
      this.appPermission[key] = {
        access: false,
        manageMode: false
      };
    }      
}

  setPermission(){
    const baseObj = this.authService.permissions;

    Object.keys(this.appRenderPermission).forEach((key:string) => {
      if((this.appRenderPermission[key].facilityType && this.appRenderPermission[key].facilityType === this.authService.accountType) || !this.appRenderPermission[key].facilityType){
        if(this.authService.roleName === userRoles.superAdmin){
            if(this.appRenderPermission[key].isSuperAdminFeature){
              this.appPermission[key] = {
                access:  true,
                manageMode: true
              }
            }
        }else{
            if(this.appRenderPermission[key].path){
              let currentValue = baseObj;  
              this.appRenderPermission[key].path.forEach((keyString: string)=>{

                if (currentValue && typeof currentValue === 'object') {
                  currentValue = currentValue[keyString];
                } else {
                  return;
                }
              })
              this.appPermission[key] = {
                access: (currentValue == permissionSetValue.view || currentValue == permissionSetValue.manage) ? true : false,
                manageMode: currentValue == permissionSetValue.manage ? true : false,
              }
            }else if(this.appRenderPermission[key].isUserManagerFeature && this.authService.accessType == accessType.userManage){
              this.appPermission[key] = {
                access:  true,
                manageMode: true
              }
            }
          }
     
      }  
    
    });
  }    
  

  hasPageAccess(permission?: string){
    if(permission && this.appPermission[permission])
      return this.appPermission[permission]?.access;
    else{
      return true;
    }
  }
  getPermisson(permission?: string){
    if(permission)
    return this.appPermission[permission].manageMode;
    else{
      return false
    }
  }

}
