<div class="alcon-ore-timeout-container" [ngClass]="{'alcon-ore-timeout-container--dark': isDarkThemeApplied}">
    <div class="alcon-ore-timeout-header alcon-timeout-complete" *ngIf="oreService.allTimesRecorded | async; else timeoutIncomplete">
        <span>Time-out complete</span>
    </div>
    <div class="alcon-ore-timeout-body">
        <div class="alcon-ore-timeout-avatar">
            <!-- <alcon-or-day-avatar [isDarkTheme]="true" [displayText]="'Right/2nd eye'" [eye]="'right'"></alcon-or-day-avatar> -->
            <alcon-or-day-avatar [isDarkTheme]="isDarkThemeApplied" [displayText]="eyeLabel" [eye]="eye"></alcon-or-day-avatar>
        </div>
        <div class="alcon-ore-timeout-actions">
            <div *ngFor="let action of (oreService.timeOutData | async)" class="alcon-ore-timeout-action" 
            [ngClass]="{ 
                'alcon-ore-active-action': action.isActive,
                'alcon-ore-last-updated-action': action.isLastUpdated
                }"
            >
                <alcon-or-day-action [timeOutForm]="timeOutForm" [data]="action"></alcon-or-day-action>
            </div>
        </div>
    </div>
    <div class="alcon-ore-timeout-footer">
        <span>{{ primaryIol ? primaryIol : '&nbsp;' }} </span>
    </div>
</div>
<ng-template #timeoutIncomplete>
    <div class="alcon-ore-timeout-header alcon-timeout-incomplete"><span class="alcon-timeout-incomplete">Time-out incomplete</span></div> 
</ng-template>