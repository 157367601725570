import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Form, FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatIconModule } from '@angular/material/icon';
import { AlconSearchableMultiselectDropdownComponent } from 'src/app/shared/alcon-searchable-multiselect-dropdown/alcon-searchable-multiselect-dropdown.component';
import { lensTypeNames } from 'src/app/shared/model/iol.model';
import { EventEmitter, OnDestroy, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { IolModelPreferencesService } from '../../services/iol-model-preferences.service';
import { IOLs } from '../../iol-model-preferences.model';
import { searchIOLFilter } from '../../iol-model-preferences.model';

@Component({
  selector: 'app-iol-preferences-filter',
  standalone: true,
  imports: [ CommonModule,
    MatIconModule,
    MatDatepickerModule,
    ReactiveFormsModule,
    AlconSearchableMultiselectDropdownComponent
  ],
  templateUrl: './iol-preferences-filter.component.html',
  styleUrl: './iol-preferences-filter.component.scss'
})
export class IolPreferencesFilterComponent implements OnInit, OnDestroy {
  uniqueTypeNamesObj!: lensTypeNames[];
  AccountFacilityID: any;
  @Output() save = new EventEmitter<boolean>();

  private subscription: Subscription;
  private isModelSelectedSubscription: Subscription
  iols!: IOLs;
  preferenceFilterForm: FormGroup
  iolSubscription!: Subscription;
  uniqueTypeNames!: string[];
  selectedModels!: string[];
  searchfields: searchIOLFilter = {
    manufacturer: '',
    model: '',
    type: []
  }
  anyModelSelected = false;
  constructor(private fb:FormBuilder,
    private iolModelPreferencesService: IolModelPreferencesService) {
    this.preferenceFilterForm = this.fb.group({
      search_manufacturer: [''],
      search_model: ['']
    });

    this.isModelSelectedSubscription = this.iolModelPreferencesService.isModelSelected.subscribe((data: boolean) => {
      this.anyModelSelected = data;
    });

    this.subscription = this.preferenceFilterForm.valueChanges.subscribe((value: any) => {
        value.search_manufacturer ? this.searchfields.manufacturer = value.search_manufacturer : this.searchfields.manufacturer = '';
        value.search_model ? this.searchfields.model = value.search_model : this.searchfields.model = '';
        this.iolModelPreferencesService.searchSubject.next(this.searchfields);
   }); 
  }
  ngOnInit(): void {
    this.iolSubscription = this.iolModelPreferencesService.iols$.subscribe(data => {
      if(data){
        this.iols = data;
        this.findUniqueLensTypeNames();
      }
     });
  }
  findUniqueLensTypeNames(): void {
    const allTypeNames = this.iols.manufacturer.flatMap(element => element['type'].map(type => type['name']));
    this.uniqueTypeNames = allTypeNames.filter((name, index) => allTypeNames.indexOf(name) === index);
    this.uniqueTypeNamesObj = this.uniqueTypeNames.map((name, index) => ({id: index, name}));
    };

  resetSearch(isVal: string){
    if(isVal){
      this.preferenceFilterForm.get(isVal)?.reset();
    }
  }

  saveChanges(save: boolean) {
    this.save.emit(save);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.isModelSelectedSubscription.unsubscribe();
  }
  changedSelectedID(selectedNamesIds:any){
    this.selectedModelTypes(selectedNamesIds);
  }

  selectedModelTypes(selectedNamesIds: any){
    this.selectedModels = this.uniqueTypeNamesObj.filter((type) => selectedNamesIds.includes(type.id)).map((item)=> item.name);
    this.searchfields.type = this.selectedModels;
    this.iolModelPreferencesService.searchSubject.next(this.searchfields);
  }

}
