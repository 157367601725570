import { Injectable } from '@angular/core';
import { Idle, DEFAULT_INTERRUPTSOURCES, AutoResume } from '@ng-idle/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AuthService } from '../auth.service';
import { CustomDialogComponent, dialogAction } from '../../components/modal-dialogs/custom-dialog/custom-dialog.component';
import { environment } from '../../../environments/environment';
import { customDialog } from 'src/app/components/modal-dialogs/dialog.model';
import { DeviceService } from 'src/app/shared/device.service';

@Injectable({
  providedIn: 'root'
})
export class IdleService {

  idleState = false;
  timedOut = false;
  idleWarning = false;
  warningDuration = environment.SessionWarningDuration * 60;

  private dialogRef!: MatDialogRef<CustomDialogComponent>;
  private onlineInterval:any;
  constructor(private idle: Idle, 
    private dialog: MatDialog,
    private authService:AuthService,
    private deviceService: DeviceService
    ) { }

  private triggerLogOut() {
      if (navigator.onLine) {
        this.authService.logout();
        clearInterval(this.onlineInterval)
      }
  }

  startIdleWatching() {
    if(!this.idleState){
      this.idleState = true;
      this.idle.setIdle(environment.sessionTimeOut * 60 - this.warningDuration);
      this.idle.setTimeout(this.warningDuration);
      
      this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);
    
      this.idle.onIdleStart.subscribe(() => {
      });
 
      this.idle.onInterrupt.subscribe(() => {
        if (this.idleWarning) {
          this.idle.setAutoResume(AutoResume.disabled);
        }
      });
 
      this.idle.onTimeoutWarning.subscribe(
        (countdown) =>{
          const displayTime = this.getDisplayTime(countdown);
          if (!this.idleWarning) {
            this.showWarningMessage(displayTime);
          }
          const updatedText = "You will be logged out in <b>" + displayTime + " </b>.</br> Do you want to stay signed in?";
          this.dialogRef.componentInstance.updateText(updatedText)
        }
      );
    
      this.idle.onTimeout.subscribe(() => {
        this.dialogRef.close();
        this.onlineInterval = setInterval(() => {
            this.triggerLogOut()
          }, 1000)
        });

      this.idle.watch();
    }
  }

  reset() {
    this.idleWarning = false;
    this.idleState = false;
    this.timedOut = false;
    this.idle.setAutoResume(AutoResume.idle);
    this.idle.watch();
  }

  showWarningMessage(countdown: string){
    var dialogData:customDialog = {
      type: 'idle',
      icon: "assets/alert-triangle.svg",
      title: 'Your session is about to expire!',
      text:  "You will be logged out in <b>" + countdown + " </b>.</br> Do you want to stay signed in?",
      buttonText: {
        primary: "Yes, keep me signed in",
        secondary: "No, sign me out",
      },
      isReverseButtonOrder: true
    }
    this.idleWarning = true;
    this.dialogRef = this.dialog.open(CustomDialogComponent,{
      position: {top: this.deviceService.getDeviceType() == 'mobile' ? '80px': '120px'},
      panelClass:'alc_popup-full-max-width',
      hasBackdrop: true,
      backdropClass: 'dialog-backdrop',
      data: dialogData,
    });
    this.dialogRef.afterClosed().subscribe((value: dialogAction) => {
      if(value == dialogAction.primary){
        this.reset();
      }else{
        this.authService.logout();
      }
    });
  }

  private getDisplayTime(seconds: number){
    if(seconds <= 60){
      return seconds + " seconds"
    }else{
      const minutes = Math.floor(seconds/60);
      const secondsRemaining = seconds - (minutes * 60);
      let displayText = "";
      if(minutes > 1){
        displayText = minutes + " minutes";
      }else{
        displayText = minutes + " minute";
      }

      if(secondsRemaining > 1){
        displayText += " " + secondsRemaining + " seconds";
      }else{
        displayText += " " + secondsRemaining + " second";
      }

      return displayText;
    }
  }

  stopIdle(){
    this.idle.stop();
  }
}
