import { Injectable } from '@angular/core';
import { BehaviorSubject, map } from 'rxjs';
import { OREThemes, selectedORETheme } from 'src/app/shared/constants/auth.constant';
import { orDayActions } from '../constants/alcon-or-experience.constants';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthService } from 'src/app/shared/auth.service';
import { OrDayAction, timeOutRequestBody } from '../models/alcon-or-experience.model';

@Injectable({
  providedIn: 'root'
})
export class AlconOrExperienceService {
  private mccApiUrl = environment.apiUrl + '/' + environment.ccApiRoute;
  
  public isDarkModeEnabled = new BehaviorSubject((localStorage.getItem(selectedORETheme) === OREThemes.light) ? false : true);
  public timeOutData = new BehaviorSubject([...orDayActions]);
  public editInProgress = false;
  public oreProcessIsLoading = false;
  public hasEditPermission = true;
  public httpOptionsWithResponse = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
    observe: 'response' as 'response'
  };
  allTimesRecorded = this.timeOutData.pipe(map((actions: OrDayAction[]) => actions.every(action => action.recordedTime !== '')));
  constructor(private httpClient: HttpClient,private authService: AuthService) { }


  formatToHHMMAmPm(currentTime: boolean, utcDateTimeString?: string | undefined): string {
    let date: Date;
    if (currentTime) {
      date = new Date();
    } else if (utcDateTimeString) {
      date = new Date((utcDateTimeString.replace(' ', 'T') + 'Z'));
    } else {
      return '';
    }
    let hours = date.getHours();
    const minutes = date.getMinutes();
    const amPm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours || 12; 
    const formattedMinutes = minutes < 10 ? '0' + minutes : minutes.toString();
    const formattedTime = `${hours}:${formattedMinutes} ${amPm}`;
  
    return formattedTime.toLowerCase(); 
  }

  formatToHHMMAmPmXX(currentTime: boolean, dateString?: string): string {
    let date: Date;
    if (currentTime) {
      date = new Date();
    } else if (dateString) {
      date = new Date(dateString);
    } else {
      return '';
    }
  
    // Convert to local timezone and format to 'HH:MM am/pm'
    const options: Intl.DateTimeFormatOptions = {
      hour: 'numeric',
      minute: '2-digit',
      hour12: true
    };
    const formattedTime = date.toLocaleTimeString('en-US', options).toLowerCase();
  
    return formattedTime;
  }

  convertTo24HourUTC(formattedTime: string | undefined, surgeryDate: string): string {
    if (!formattedTime) {
      return '';
    }
    const date: Date = new Date(surgeryDate);
    const [time, amPm] = formattedTime.split(' ');
    let [hoursStr, minutesStr] = time.split(':');
    let hours: number = parseInt(hoursStr, 10);
    const minutes: number = parseInt(minutesStr, 10);
    if (amPm.toLowerCase() === 'pm' && hours < 12) {
      hours += 12;
    } else if (amPm.toLowerCase() === 'am' && hours === 12) {
      hours = 0;
    }
    date.setHours(hours, minutes, 0, 0);
    
    const utcYear: string = date.getUTCFullYear().toString();
    const utcMonth: string = (date.getUTCMonth() + 1).toString().padStart(2, '0'); 
    const utcDate: string = date.getUTCDate().toString().padStart(2, '0');
    const utcHours: string = date.getUTCHours().toString().padStart(2, '0');
    const utcMinutes: string = date.getUTCMinutes().toString().padStart(2, '0');
    const utcSeconds: string = date.getUTCSeconds().toString().padStart(2, '0');
    const utcMilliseconds: string = date.getUTCMilliseconds().toString().padStart(3, '0');
    
    const utcDateTimeString: string = `${utcYear}-${utcMonth}-${utcDate} ${utcHours}:${utcMinutes}:${utcSeconds}.${utcMilliseconds}`;
    return utcDateTimeString;
  }
  

  updateActionORE(reqBody: timeOutRequestBody, patientId: string, practiceOrg: string) {
    // const practiceOrg = this.authService.getPracticeOrg();
    const url = this.mccApiUrl + `/surgery/partialUpdate?practiceOrg=${practiceOrg}&patientId=${patientId}`;
    return this.httpClient.put(url, reqBody,this.httpOptionsWithResponse);
  }

  getUpdateOREPayload(surgeryId: string, timeOutData: OrDayAction[], surgeryDate: string) {
    return {
      surgeryId,
      inORExperience: {
        enteredORDttm: this.getUTCformatedField(timeOutData, 'enteredORDttm', surgeryDate),
        timeOutDttm: this.getUTCformatedField(timeOutData, 'timeOutDttm', surgeryDate),
        surgeryStartDttm: this.getUTCformatedField(timeOutData, 'surgeryStartDttm', surgeryDate),
        surgeryEndDttm: this.getUTCformatedField(timeOutData, 'surgeryEndDttm', surgeryDate),
        exitedORDttm: this.getUTCformatedField(timeOutData, 'exitedORDttm', surgeryDate),
      }
    }
  }

  getUTCformatedField(timeOutData: OrDayAction[], field: string, surgeryDate: string ) {
    return this.convertTo24HourUTC(timeOutData.find(o => o.field === field)?.recordedTime, surgeryDate);
  }
}
