<div class="alc-summary-dialog-container">
    <div class="alc-summary-dialog-close-icon">
      <mat-icon
      (click)="closePopup('false')"
      aria-hidden="false"
      aria-label="close icon"
      fontIcon="close">
    </mat-icon>
    </div>
    <div class="alc-summary-dialog-content-container">
        <img
        [src]="data.icon"
        [alt]="'icon'"
        class="summary_dialog_icon"
      />

        <div class="alc-summary-dialog-content-title">
            <h1>{{data.title}}</h1>
        </div>
        <div *ngIf="summary" class="row alc-summary-banner">
          <app-alcon-banner
          bannerText="Rows with errors will not be processed for update."
          [bannerType]="bannerTypes.info"
          bannerIcon="info"
        ></app-alcon-banner>
        </div>
        <div class="row alc-summary-count-container">
          <div class="col-md-6">
            <div class="alc-summary-count">{{data.count.new?.count}}</div>
            <div class="alc-summary-count-text">
              <img *ngIf="summary"
              class="summary-count-icon"
              src="assets/toast_success.svg"
              [alt]="'icon'"
             />
              <p>{{data.count.new?.text}}</p>
            </div>
          </div>
          <div class="col-md-6">
            <div class="alc-summary-count">{{data.count.update?.count}}</div>
            <div class="alc-summary-count-text">
              <img *ngIf="summary"
                class="summary-count-icon"
                src="assets/alert-triangle.svg"
                [alt]="'icon'"
               />
               <p>{{data.count.update?.text}}</p>
              </div>
          </div>
        </div>
        <div *ngIf="data.text" class="alc-summary-dialog-message">
            <p>{{data.text}}</p>
        </div>
    </div>
  <div>
    <p class="alc-summary-subtext">{{data.subText}}</p>
  </div>
  <div class="alc-summary-dialog-content-footer" [ngClass]="{'single-btn': !data.buttonText.secondary}">
    <button *ngIf="data.buttonText.secondary" type="button" class="alc_button alc_button--secondary" (click)="closePopup('secondary')">{{data.buttonText.secondary}}</button>
    <button type="button" class="alc_button alc_button--primary" (click)="closePopup('primary')">{{data.buttonText.primary}}</button>
  </div>
</div>
