import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { summaryDialog } from '../dialog.model';
import { BannerTypes } from 'src/app/shared/model/banner.model';

@Component({
  selector: 'app-summary-dialog',
  templateUrl: './summary-dialog.component.html',
  styleUrls: ['./summary-dialog.component.scss']
})
export class SummaryDialogComponent {
  summary = false;
  bannerTypes = BannerTypes;
  constructor(
    public dialogRef: MatDialogRef<SummaryDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: summaryDialog) {
      if(data.type == 'summary'){
        this.summary = true;
      }
    }

  closePopup(event?: string) {
    this.dialogRef.close(event);
  }
}
