import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NewUser, User } from "../model/user";
import {  Observable, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { SharedService } from './service/shared.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  private mccapiURL = environment.apiUrl + "/" + environment.ccApiRoute;
  
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  }

  public httpOptionsWithResponse = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
    observe: 'response' as 'response'
  };

  constructor(private httpClient: HttpClient, 
    private sharedService: SharedService) { }

  // getAll(facilityId: string, searchby : string, page : number, limit : number, orderby : string, sortType: string): Observable<User[]> {
  //   const url = this.mccapiURL + '/users?practiceOrg=' + facilityId + '&searchTerm='+searchby+'&pageNumber='+page+'&limit='+limit+'&sortBy='+orderby+'&sortType='+sortType;
  //   return this.httpClient.get<HttpResponse<any>>(url, this.httpOptionsWithResponse).pipe(
  //     map(resp => this.sharedService.extractData<any>(resp, url, this.httpOptionsWithResponse))
  //   )
  // }
  
  // getRoles(): Observable<any[]> {
  //   return this.httpClient.get<any[]>(this.mccapiURL + '/roles', this.httpOptions)
  // }

  getMedicalFacilities(mftype : string): Observable<User[]> {
    const url = this.mccapiURL + '/facilities?role='+mftype;
    return this.httpClient.get<HttpResponse<any>>(url, this.httpOptionsWithResponse).pipe(
      map(resp => this.sharedService.extractData<any>(resp, url, this.httpOptionsWithResponse))
    )
  }

  // create(obj: NewUser): Observable<User>{
  //   const url = this.mccapiURL + '/user';
  //   return this.httpClient.post<HttpResponse<any>>(url, JSON.stringify(obj), this.httpOptionsWithResponse).pipe(
  //     map(resp => this.sharedService.extractData<any>(resp, url, this.httpOptionsWithResponse))
  //   )
  // }

  
  // details(id: any): Observable<User> {
  //   const url = this.mccapiURL + '/user/' + id;
  //   return this.httpClient.get<HttpResponse<any>>(url, this.httpOptionsWithResponse).pipe(
  //     map(resp => this.sharedService.extractData<any>(resp, url, this.httpOptionsWithResponse))
  //   )
  // }
  
  // update(id: number, obj: any): Observable<HttpResponse<any>> {
  //   const url = this.mccapiURL + '/user/' + id;
  //   return this.httpClient.patch<HttpResponse<any>>(url,  JSON.stringify(obj), this.httpOptionsWithResponse).pipe(
  //     map(resp => this.sharedService.extractData<any>(resp, url, this.httpOptionsWithResponse))
  //   )
  // }
  
  // delete(id: number)
  // {
  //   return this.httpClient.delete<User>(this.mccapiURL + '/user/' + id, this.httpOptions)
  // }

  
  // manipulateObjKeys(formVal: any) {
  //   let tempFacilities: string[] = [];
  //   if(formVal.facilities) {
  //     formVal.facilities.forEach((el: any) => {
  //       if(el && el.practiceOrg) 
  //         tempFacilities.push(el.practiceOrg);
  //     })
  //   }
  //   const obj: NewUser = {
  //     prefix: formVal.prefix,
  //     roleId: formVal.role,
  //     firstName: formVal.first_name,
  //     middleName: formVal.middle_name,
  //     lastName: formVal.last_name,    
  //     phoneNumber: formVal.phone ? formVal.phone.replace(/\D/g, '') : '',
  //     email: formVal.email,
  //     otherAssociatedFacility: tempFacilities ? tempFacilities : [],
  //     sapId: formVal.sap_id,
  //     status: formVal.status,
  //     preferredFacility: formVal.preferred_medicalFacility_id
  //   }
  //   return obj;
  // }
}