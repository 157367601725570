<div
  class="alcon-or-action-container"
  [formGroup]="timeOutForm"
  [ngClass]="{
    'alcon-or-action-container--no-pad': data.isLastRecorded,
    'alcon-or-action-last': data.isLastElement,
    'alcon-ore-history-dark': (oreService.isDarkModeEnabled | async),
    'alcon-edit-progress-ore': currentActionEditInProgress
  }"
>
  <div
    class="alcon-or-action-details"
    [class.alcon-active-ore-element]="inputFocused"
  >
    <div class="alcon-or-action-label">
      {{ data.label }}
    </div>
    <div *ngIf="data.recordedTime" class="alcon-timeout-field-wrapper" [ngClass]="{'alcon-ore-class': timeOutForm.get(data.field)?.errors}">
      <input
        (focus)="currentActionEditInProgress && inputFocused = true"
        (blur)="currentActionEditInProgress && inputFocused = false"
        [formControlName]="data.field"
        maxlength="8"
        [attr.readonly]="!currentActionEditInProgress ? '' : null"
        class="alcon-timeout-field"
      />
      <mat-icon *ngIf="!oreService.editInProgress && oreService.hasEditPermission" class="alcon-timeout-field-icon" svgIcon="edit_nc" (click)="editTime()"></mat-icon>
      <div *ngIf="currentActionEditInProgress" class="alcon-ore-actions">
        <mat-icon svgIcon="check_nc" class="alcon-ore-action-save" (click)="saveTime()" [ngClass]="{'alcon-disable-icon': timeOutForm.get(data.field)?.errors}"></mat-icon>
        <mat-icon svgIcon="close" class="alcon-ore-action-cancel" (click)="cancelEdit(true)"></mat-icon>
      </div>
    </div>
    <div
      class="alcon-or-action-time"
      *ngIf="((!data.isActive && !data.recordedTime) || (data.isActive && !oreService.hasEditPermission)) "
    >
      ---
    </div>
    <div class="alcon-or-action-time" *ngIf="data.isActive && oreService.hasEditPermission">
      <button
        (click)="callTime()"
        class="alc_button alc_button--secondary alcon-re-call-time-btn"
      >
        Call time
      </button>
    </div>
  </div>
</div>
