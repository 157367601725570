export enum surgeryTabType{
    catract = "cataract",
    other = "other",
    cancelled= "cancelled",
    undefined= "undefined",
    exchange="exchange"
}

export const patientGenderList = [
    { label: 'Female', value: 'female' },
    { label: 'Male', value: 'male' },
    { label: 'Other', value: 'other' }
  ];

export enum patientModelTab{
    patient = "patient",
    surgery = "surgery",
    iol= "iol"
}
