import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BannerTypes } from '../model/banner.model';
import { MatIcon } from '@angular/material/icon';

@Component({
  selector: 'app-alcon-banner',
  standalone: true,
  imports: [
    CommonModule,
    MatIcon
  ],
  templateUrl: './alcon-banner.component.html',
  styleUrl: './alcon-banner.component.scss'
})
export class AlconBannerComponent {
  @Input() bannerType: BannerTypes = BannerTypes.warning;
  @Input() bannerText = '';
  @Input() bannerLinkText = '';
  @Input() showCloseButton = false;
  @Input() bannerIcon = '';
  @Output() onBannerLinkClick = new EventEmitter<{ linkIndex: string }>();
  @Output() close = new EventEmitter();
  bannerTypes = BannerTypes;

  onLinkClick(linkIndex = '') {
    this.onBannerLinkClick.emit({ linkIndex });
  }

  dismissBanner(){
    this.close.emit();
  }
}
