import { Injectable } from '@angular/core';
import { SessionStorageService } from '../session-storage.service';
import { surgeryFilters } from '../constants/auth.constant';
import { FilterDateRange } from 'src/app/model/dateRange';
import { StoredFilter } from '../model/surgery.model';
import { EyeSurgeryFilter } from 'src/app/model/EyeSurgeryFilter';

@Injectable({
  providedIn: 'root'
})
export class SurgeryFiltersService {

  constructor(private sessionStorageService: SessionStorageService) { }

  updateStoredFilters(field: string, value: string | FilterDateRange): void{
    let headerFilters = {
      patient: '',
      surgeon: '',
      startDate: '',
      endDate: '',
      surgeonNames:''
    };
    // Check if the filters are already there or not
    const headerConfigs = this.getStoredFilters(surgeryFilters.headerFilters);
    if(headerConfigs){
      headerFilters = {...headerConfigs};
    }
    if(field === 'patient'){
      headerFilters.patient = value as string;
    }
    else if(field === 'surgeon'){
      headerFilters.surgeon = value as string;
    }
    else if(field === 'surgeonNames'){
      headerFilters.surgeonNames = value as string;
    }
    
    else{
      value = value as FilterDateRange;
      headerFilters.startDate = value.startDate;
      headerFilters.endDate = value.endDate;
    }
    this.setStoredFilters(surgeryFilters.headerFilters,headerFilters);
  }

  getStoredFilters(filterType: string): any {
    return this.sessionStorageService.getItem(filterType);
  }

  setStoredFilters(filterType: string, value: any) : void {
    this.sessionStorageService.setItem(filterType, value);
  }

  removeStoredFilter(filterType: string) : void {
    this.sessionStorageService.removeItem(filterType);
  }

  getExistingHeaderFilters(){
    const filters: StoredFilter = this.getStoredFilters(surgeryFilters.headerFilters);
    return {
        patient: filters?.patient ?? "",
        surgeon: filters?.surgeon ?? "",
        startDate: filters?.startDate ?? "", 
        endDate: filters?.endDate ?? "",
        surgeonNames: filters?.surgeonNames ?? ""
      };
  }

  getActiveFiltersString(filterData? : EyeSurgeryFilter){
    let activeFiltersString = ''
    activeFiltersString = this.appendPatientAndSurgeon(activeFiltersString)
    activeFiltersString = this.appendSurgeryType(activeFiltersString,filterData)
    activeFiltersString = this.appendEye(activeFiltersString,filterData)
    activeFiltersString = this.appendFemotORAWaitlist(activeFiltersString,filterData)
    activeFiltersString = this.appendPostOPMedsAndSupplies(activeFiltersString,filterData)
    activeFiltersString = this.appendFacility(activeFiltersString,filterData)
    // return activeFiltersString
    return this.formatFiltersString(activeFiltersString);
  }

  formatFiltersString(filterString:string){
    // Remove the last semicolon if it exists
    const trimmedDetails = filterString.endsWith(';') ? filterString.slice(0, -1) : filterString;
    // Insert a whitespace after each semicolon
    const formattedDetails = trimmedDetails.replace(/;(?!\s)/g, '; ');
    return formattedDetails;
  }

  appendPatientAndSurgeon(filterString:string){
    let filters = this.getExistingHeaderFilters()
    if (filters.patient !== ''){
      filterString += 'Patient: ' + filters.patient+ ';'
    }
    if (filters.surgeonNames.length > 0){
      filterString +=   'Surgeon: '+ filters.surgeonNames+ ';'
    }
    return filterString
  }
  appendSurgeryType(filterString:string,filterData? : EyeSurgeryFilter){
    if (filterData?.cataractSurgery || filterData?.exchangeSurgery || filterData?.other){
      let ar = []
      if (filterData?.cataractSurgery ){
        ar.push('Cataract')
      }
      if (filterData?.exchangeSurgery ){
        ar.push('Exchange')
      }
      if (filterData?.otherSurgery ){
        ar.push('Other')
      }
      const surgeries = ar.map((sur) => sur).join(',');

      filterString +=   'Surgery type: '+ surgeries+ ';'
    }
    return filterString
  }

  appendEye(filterString:string,filterData? : EyeSurgeryFilter){
    if(filterData?.leftEye || filterData?.rightEye ){
      let eyeArray = []
      if (filterData?.leftEye ){
        eyeArray.push('Left')
      }
      if (filterData?.rightEye ){
        eyeArray.push('Right')
      }
      const eyes = eyeArray.map((eye) => eye).join(',');

      filterString +=   'Eye: '+ eyes+ ';'
    }
    return filterString
  }
  appendPostOPMedsAndSupplies(filterString:string,filterData? : EyeSurgeryFilter){
    if( filterData?.postopMeds  && filterData?.postopMeds?.length > 0)
    {
      const postOPMeds = filterData?.postopMeds?.map((sur) => sur).join(',');
      filterString +=   'Post Op Meds: '+ postOPMeds+ ';'
    }
    if( filterData?.supplies  && filterData?.supplies?.length > 0)
    {
      const supplies = filterData?.supplies?.map((sur) => sur).join(',');
      filterString +=   'Supplies: '+ supplies+ ';'
    }
    return filterString
  }
  appendFacility(filterString:string,filterData? : EyeSurgeryFilter){
    if ( filterData?.selectedFacilityName){
      let facilityType = ( filterData?.ascId !== "") ? 'ASC':'Clinic'
      filterString +=   ` ${facilityType}:`+ filterData?.selectedFacilityName+ ';'
    }
    return filterString
  }
  appendFemotORAWaitlist(filterString:string,filterData? : EyeSurgeryFilter){
    if(filterData?.femtoYes){
      filterString +=   'Femto: Yes;'
    }
    if(filterData?.femtoNo){
      filterString +=   'Femto: No;'
    }
    if(filterData?.oraYes){
      filterString +=   'ORA: Yes;'
    }
    if(filterData?.oraNo){
      filterString +=   'ORA: No;'
    }
    if(filterData?.waitlistYes){
      filterString +=   'Waitlist: Yes;'
    }
    if(filterData?.waitlistNo){
      filterString +=   'Waitlist: No;'
    }
    return filterString

  }
}
