import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class JwtDecodeService {

  constructor() { }
  parseJwt(token?: string): any {
    if (token) {
      let jsonPayload = '';
      const base64Url = token?.split('.')[1];
      const base64 = base64Url?.replace(/-/g, '+').replace(/_/g, '/');
      if (base64) {
        jsonPayload = decodeURIComponent(
          window
            .atob(base64)
            .split('')
            .map(function (c) {
              return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
            })
            .join('')
        );
      }
      return JSON.parse(jsonPayload);
    } else {
      return undefined;
    }
  }

  sameClientId(oktaToken: string){

      if (oktaToken) {
        const decodedOktaToken = this.parseJwt(oktaToken);

        if (environment.clientId !== decodedOktaToken.cid) {
          return false;
        } else {
          return true;
        }
      }
        return false;
  }
}
