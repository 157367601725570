import { Component, OnDestroy, OnInit } from '@angular/core';
import * as MenuItems from '../../../assets/menus.json';
import { AbstractControl, FormBuilder, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { AuthService } from 'src/app/shared/auth.service';
import { Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { AlertDialogComponent } from 'src/app/components/alert-dialog/alert-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { Editor, Toolbar } from 'ngx-editor';
import { UserService } from '../../shared/user.service';
import { SurgeryService } from 'src/app/shared/surgery.service';
import { userRoleCode } from 'src/app/shared/constants/auth.constant';
import { testForbiddenPatterns } from 'src/app/shared/no-code-validator.service';

@Component({
  selector: 'app-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss']
})
export class FormComponent implements OnInit, OnDestroy{

  editor: any = Editor
  toolbar: Toolbar = [
    // default value
    [{ heading: ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'] }],
    ['bold', 'italic'],
    ['align_left', 'align_center', 'align_right', 'align_justify'],
  ];
  html = '';
  menus: any = (MenuItems as any).default
  record: any = [];
  errorMessage: String = '';
  form: FormGroup;
  submitted = false;
  id!: number;
  isAlreadyData : boolean = false
  clinic_listing : any = []
  isDisabled = false;
  isDisabledText = 'Save';
  surgeryTypes: any = [];

  constructor(
    public fb: FormBuilder,
    public authservice: AuthService,
    public userservice: UserService,
    private dialog: MatDialog,
    public router: Router,
    public surgeryService: SurgeryService
  ) {
    this.form = this.fb.group({
      practiceOrg: ['', Validators.required],
      surgeryType: ['', Validators.required],
      description: ['', [Validators.required, this.validateDescription()]]
    });
  }

  ngOnInit(): void 
  {
    this.editor = new Editor();
    this.getSurgeryTypes();
    this.getClinicListing(userRoleCode.clinicddmin);
  }

  ngOnDestroy(): void 
  {
    this.editor.destroy();
  }

  getDetails(facilityId:any, surgeryType:any) {
    this.authservice.getConcernForm(facilityId, surgeryType)
    .subscribe({
      next: (res: any) => 
      {
        if (res) 
        {
          if (res.data) 
          { 
            this.record = res.data;
            if(res.isExist == 'YES'){
              this.id = this.record.id;
              this.isAlreadyData = true;
            }
            this.form.patchValue({description: this.record.description});
          }
          else
          {
            this.isAlreadyData = false
            this.record = [];
            this.form.patchValue({description: ''});
          }
        }
        else
        { 
          this.openAlertDialog(res.message)
        }
      },
      error: (err: any) => 
      {
        this.openAlertDialog(err)
      },
    });
  }

  get f() 
  {
    return this.form.controls;
  }

  submit() 
  {
    this.submitted = true;
    
    // stop here if form is invalid
    if (this.form.invalid) 
    {
      return;
    }
    
    this.isDisabled = true
    this.isDisabledText = 'Please wait...'

    if(this.isAlreadyData == true)
    {
      this.authservice.concernFormUpdate(this.id, this.form.value).subscribe({
        next: (res: any) => {
            this.isDisabled     = false
            this.isDisabledText = 'Save'
            this.openAlertDialog('Consent Form has been updated.')
        },
        error: (err: any) => {
          this.isDisabled     = false
          this.isDisabledText = 'Save'
          this.openAlertDialog(err)
        },
      });
    }
    else
    {
      this.authservice.concernFormCreate(this.form.value).subscribe({
        next: (res: any) => {
          if (res) 
          {
            this.record = res.data;
            this.id     = this.record.id;
            this.isAlreadyData = true
            this.isDisabled     = false
            this.isDisabledText = 'Save'
            this.form.patchValue({
              description: this.record.description
            });
            this.openAlertDialog('Consent Form has been created.')
          } 
          else 
          {
            this.isDisabled     = false
            this.isDisabledText = 'Save'
            this.openAlertDialog(res.message)
          }
        },
        error: (err: any) => 
        {
          this.isDisabled     = false
          this.isDisabledText = 'Save'
          this.openAlertDialog(err)
        },
      });
    }
  }
  
  openAlertDialog(msg:any) {
    const dialogRef = this.dialog.open(AlertDialogComponent,{
      hasBackdrop: true,
      backdropClass: 'dialog-backdrop',
      data:{
        message: msg,
        buttonText: {
          cancel: 'Ok'
        }
      },
    });
  }

  getClinicListing(role: string) 
  {
    this.userservice.getMedicalFacilities(role).subscribe({
      next: (res: any) => 
      {
        if (res) 
        {
          this.clinic_listing = res.data;
        }
        else
        {
          this.openAlertDialog(res.message)
        }
      },
      error: (err: any) => {
        this.openAlertDialog(err)
      }
    })
  }
  
  onChangeFacilityID(obj:any)
  {
    this.form.patchValue({
      surgeryType: '',
      description: ''
    });
  }

  getSurgeryTypes() {
    this.surgeryService.getSurgeryTypes().subscribe({
      next: (res: any) => {
        if(res && res.data) {
          this.surgeryTypes = res.data;
        } else {
          this.errorAlertDialog(res.message)
        }
      },
      error: (err: any) => {
        this.errorAlertDialog(err);
      }
    }
    );
}

errorAlertDialog(msg:any) {
  const dialogRef = this.dialog.open(AlertDialogComponent,{
    hasBackdrop: true,
    backdropClass: 'dialog-backdrop',
    data:{
      message: msg,
      buttonText: {
        cancel: 'Ok'
      }
    },
  });
}

  onChangeSuregeryType(obj:any)
  {
    if(obj && this.form.value.practiceOrg)
    { 
      this.getDetails(this.form.value.practiceOrg, obj)
    }
  }

  validateDescription(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      // Remove <p> tags from the start
      let descriptionValue = control.value.replace(/^<p[^>]*>/i, '');
      // Remove </p> tags from the end
      descriptionValue = descriptionValue.replace(/<\/p>$/i, '');
      const parser = new DOMParser();
      const doc = parser.parseFromString(descriptionValue, 'text/html');
      const forbidden = testForbiddenPatterns(doc.documentElement.textContent || '');
      return forbidden ? { 'forbiddenCodeSnippet': { value: control.value } } : null;
    };
  }
}
