import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class GraphqlService {

  private ccGraphApiUrl = environment.apiUrl + '/' + environment.ccApiRoute + '/' + environment.graphQLRoute;
  constructor(    private httpClient: HttpClient
    ) { }
  httpOptions = { 
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };

  public httpOptionsWithResponse = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
    observe: 'response' as 'response'
  };

  
  callgraphApi(obj: string){
    return this.httpClient.post<any>(
      this.ccGraphApiUrl,
      obj,
      this.httpOptions
    );
  }

 
}

