<div class="alcon-unassigned-account-container">
    <h1>Uh ho!<br/>Looks like you don’t have permission to access this module.</h1>
    <img src="assets/images/account-unassigned.png" alt="Account Unassigned">
    <div>
        <p class="alcon-unassigned-account-content">Please go to your Adi homepage to see available Adi solutions for your account or contact Customer Service.</p>
        <button type="button" class="alc_button alc_button--primary alcon-unassigned-account-button" (click)="gotoAdiHome()">Go to Adi homepage</button>
    </div>
</div>
<div class="alcon-unassigned-account-footer">
    <p class="alcon-unassigned-account-footer-header">Customer Service</p>
    <div class="alcon-unassigned-account-footer-contact">
        <div class="alcon-footer-contact-section">
            <mat-icon svgIcon="phone" class="alcon-unassigned-footer-icon phone-icon"></mat-icon>
            <span><a [href]="'tel:'+contactNo">{{contactNo}}</a></span>
        </div>
        <div class="alcon-footer-contact-section">
            <mat-icon class="alcon-unassigned-footer-icon email-icon">mail</mat-icon>
            <a class='alcon-mail-id' [href]="'mailto:' + customerServiceMail">
                {{customerServiceMail}}</a>
        </div>
    </div>
</div>