export enum eyePosition {
    left = "L",
    right = "R"
}

export enum eyePositionLabel {
    left = "Left Eye",
    right = "Right Eye",
    none = "No eye selected"
}

export interface surgeryInput{
    startDate?: string,
    endDate?: string,
    clinicId?: string,
    ascId?: string,
    sort? : sortConfig,
    pagination?: paginationConfig,
    searchKey?: searchKeys
  }

  export interface filterInput{
    startDate: string,
    endDate: string,
    sort : sortConfig,
    pagination: paginationConfig,
    searchKey: searchKeys,
  }

  export interface resetFilter{
    pagination?: boolean,
    tabChange?: boolean
  }
  export interface searchKeys {
    patient?: string,
    surgeon?: string,
  }
  export interface sortConfig {
    sortField?: string,
    sortBy?: string,
  }

  export interface paginationConfig{
    limit?: number,
    offset?: number
  }
  
  export enum surgeryTab  {
    all = 'surgeries',
    missingForm = 'patient-missing-form',
    surgeryIncomplete = 'surgery-details-incomplete',
    iolPending = 'patient-iol-selection-pending',
    patientInfoIncomplete = 'patient-info-incomplete',
    unScheduled = 'patient-unscheduled',
  }


export enum surgeryType {
    catract = "Cataract Surgery",
    other = "Other Surgery",
    exchange = "Exchange Surgery"
}

export const surgeryDisplay: {[key: string]:string} = {
  [surgeryType.other]: "Other",
  [surgeryType.catract]: "Cataract",
  [surgeryType.exchange]: "Exchange"  
}

export enum surgeryTypeShort {
  catract = "CATARACT",
  other = "OTHER",
  exchange = "EXCHANGE"
}

export enum surgeryStatus {
    DRAFT = 'Draft',
    CANCELLED = 'Cancel',
    COMPLETED = 'Completed',
    SCHEDULE = 'Schedule',
    READY = 'Ready'
}

export enum consentFormStatus {
    INCOMPLETE = 'Incomplete',
    SUBMITTED = 'Submitted'
}

export enum surgeryDocumentType {
    consentForm = 'consentForm',
    surgeryDocument = 'surgeryDocument',
    preVerification = 'preVerification'
}

export enum surgeryFormName {
  consentForm = 'Missing consent',
  surgeryDocument = 'surgeryDocument',
  preVerification = 'Missing verification'
}

export enum sortType {
    desc = 'DESC',
    asc = 'ASC'
}

export const caseComplexity : {[key: string]: string} = {
  Simple : 'Standard',
  Complex : 'Complex'
}
export interface StoredFilter{
  startDate?: string,
  endDate?: string,
  patient?: string,
  surgeon?: string,
  surgeonNames?:string
}

export const simpleCase : {[key: string]: string} = {
  Simple : 'simple'
}

export interface prefillDetails {
  preFill: boolean,
  surgeryType?: surgeryType,
  eye?: eyePosition
}
