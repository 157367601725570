<page-header [pageTitle]="'Admin portal'"></page-header>
<tab-navigation [menuItems]="menus[1]['admins']"></tab-navigation>
<app-iol-preferences-filter (save)="save($event)"
    ></app-iol-preferences-filter>
<div class="container iol-model-conatainer d-flex justify-content-between">
    <app-iol-models class="iol-model-column"
        [title]="'Visible models'"
        [btnText]="'Mark as hidden'"
        [visible]="true"
    ></app-iol-models>
    <app-iol-models class="iol-model-column"
    [title]="'Hidden models'"
    [btnText]="'Make visible'"
    [visible]="false"
    ></app-iol-models>
</div>

<alc-section-loader *ngIf="isLoader"></alc-section-loader>
