import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AlconToastMessageComponent } from './alcon-toast-message/alcon-toast-message.component';
import { PatientComponent } from '../pages/surgery/patient/patient.component';
import { MatDialog } from '@angular/material/dialog';
@Injectable({
  providedIn: 'root',
})
export class AlconToastMessageService {
  constructor(private snackBar: MatSnackBar, private dialog: MatDialog) {}
  showToastMsg(
    message: string,
    linkText: string,
    saveType: string,
    messageType: string,
    panelClass: string | string[],
    duration = 20000,
    closeIcon = false
  ) {
    this.snackBar.openFromComponent(AlconToastMessageComponent, {
      data: {
        message: message,
        linkText: linkText,
        saveType: saveType,
        messageType: messageType,
        autoClose: closeIcon,
        panelClass: panelClass,
      },
      duration: duration,
      verticalPosition: 'top',
      horizontalPosition: 'center',
      panelClass: panelClass,
    });
  }

  closeToastMessage() {
    this.snackBar.dismiss();
  }

  showPatientModal(toastrData:any, data: any = {sid: '', patientId: '', practice_org: ''}) {
    let selectedIndex;
    toastrData.saveType === 'save-patient' ? selectedIndex = 1 : selectedIndex = 2;
    this.dialog.open(PatientComponent, {
      height: '80%',
      panelClass: 'alc_add_patient_dialog',
      hasBackdrop: true,
      backdropClass: 'dialog-backdrop',
      disableClose: true,
      data: {
        id: data.sid,
        practice_org: data.practice_org,
        patientId: data.patientId,
        selectedIndex: selectedIndex
      },
    });
  }
}
