<div class="alcon-mcc-patient-container" *ngIf="!showPatientList">
  <div class="alc_page-header">
    <div class="container">
      <div class="alc_page-header-cta">

        <mat-icon (click)="closePopup(isSurgerySubmitted ? appEvents.reloadData : '')" aria-hidden="false" aria-label="close icon" fontIcon="close" class="alc_add_patient_close"></mat-icon>
      </div>
      <div class="back-link" (click)="backToSearch()" *ngIf="(service.inlineDisplay | async) && !fromPatientDetail">
        <span class="back-icon"><svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <mask id="mask0_4793_59852" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="16" height="16">
          <rect width="16" height="16" fill="#D9D9D9"/>
          </mask>
          <g mask="url(#mask0_4793_59852)">
          <path d="M7.99984 13.3346L2.6665 8.0013L7.99984 2.66797L8.94984 3.6013L5.2165 7.33464H13.3332V8.66797H5.2165L8.94984 12.4013L7.99984 13.3346Z" fill="#003595"/>
          </g>
          </svg>
        </span>
        <span class="back-text"><a href="javascript:void(0)">Back to search</a></span>
      </div>
      <div class="alc_page-header-title alc_add_patient_header" [ngClass]="{'alc_patient_exist_container' : showPatientExistsError}">
        <h1 #h1 class="alc_add_patient_title_text  no-text-wrap-desktop">{{patientInfo}}</h1>
        <div *ngIf="!showPatientExistsError" class="alc_add_patient_facility_name alcon-text-format-same-line">
          <span *ngIf="patientFullName" [matTooltip]="patientFullName" >{{ patientFullName }},&nbsp;</span>
          <span>{{ accountFacilityName }}</span>
        </div>
        <p *ngIf="showPatientExistsError" class="alc_add_patient_exist">
          <img src="assets/alert-triangle.svg" class="alc_duplicate_iol_warning" alt="warning">
          We found patients with the same name and date of birth.
          <a href="javascript: void(0);" (click)="showPatientsList()">See more.</a></p>
      </div>
      <div class="error" *ngIf="isPatientIDEdited">
        <app-alcon-banner
        bannerText="Please save your changes to Patient ID before editing the First Name, Last Name, Date of Birth, or Gender."
        [bannerType]="bannerTypes.error"
        bannerIcon="errorOctagon"
      ></app-alcon-banner>
      </div>
      <div class="error" *ngIf="isPrimaryFieldEdited">
        <app-alcon-banner
        bannerText="Please save your changes to First Name, Last Name, Date of Birth, and Gender before editing the Patient ID."
        [bannerType]="bannerTypes.error"
        bannerIcon="errorOctagon"
      ></app-alcon-banner>
      </div>
    </div>

  </div>
  <alc-section-loader *ngIf="surgeryLoader || patientLoader">
  </alc-section-loader>
  <mat-horizontal-stepper #stepper [selectedIndex]="selectedIndex" class="alcon-add-surgery-stepper" [ngClass]="{'hide-header': showPatientOnly }" (selectedIndexChange)="call()">
    <mat-step *ngIf="!hidePatientInfo">
    <form mat-form [formGroup]="patientDetailsForm" (ngSubmit)="patientInfoSubmit('step')" #reactiveForm="ngForm" appAutofocus>
      <div class="container-patient-form">
        <ng-template matStepLabel>
          <div class="stepper-header-overlay" *ngIf="patientSubmitted" (click)="moveNext(false, 'patient', $event)"></div>
          <span class="mcc-step-patient-label"><span class="mcc-f-weight">01</span> {{stepOneHeader}}</span>
          <div class="custom-step-header">
            <ng-template [ngTemplateOutlet]="activeStep" *ngIf="!patientSubmitted || currentTabValue === 'patient'"></ng-template>
            <ng-template [ngTemplateOutlet]="completedStep" *ngIf="patientSubmitted && currentTabValue !== 'patient'"></ng-template>

          </div>
        </ng-template>
        <!-- Patient Details -->
          <div class="left-div">
            <div class="alc_form alc_form--create">
                <div class="alc_form-wrapper alc_form-wrapper--column">
                  <div class="alc_form-wrapper-column">
                    <div class="alc_form-content">
                      <div *ngIf="errorMessage" class="error-msg" role="alert">
                        <strong>Error!</strong> {{ errorMessage }}
                      </div>
                      <div class="alc_inputs">
                        <div class="container-title-name">
                          <div class="alc-input-gap">
                            <label class="alc_input-label">Title <span
                                *ngIf="patientField['title'].validator">*</span></label>
                            <div class="alc_patient_title">
                              <mat-form-field class="alc_mat-form-field-outlined alc_mat-form-field-outlined--no-error-slot">
                                  <mat-select
                                      panelClass="alc_list-select-container"
                                      formControlName="title"
                                      placeholder="Select an option"
                                  >
                                      <mat-option 
                                      *ngFor="let patientTitle of patientTitleList"
                                      [value]="patientTitle"
                                      >{{patientTitle}}</mat-option>
                                  </mat-select>
                                  <mat-error *ngIf="patientField['title'].errors && patientField['title'].errors['required']">
                                  Title is required
                                  </mat-error>
                              </mat-form-field>
                                </div>
                              </div>
                          <div class="alc_input right-div margin alc-input-gap" [ngClass]="{'alc-disabled-field': isPatientIDEdited }">
                            <label class="alc_input-label">First Name<span
                                *ngIf="patientField['firstName'].validator">*</span></label>
                            <div class="alc_input-wrapper">
                              <input type="text" [ngClass]="patientField['firstName'].errors && patientDetailsForm.get('firstName')?.touched ? 'alc_input-text alc_form_error' : 'alc_input-text'" formControlName="firstName" name="firstName"
                              (blur)="checkIsAnyPrimaryFieldEdited()" [(ngModel)]="firstNameValue" id="firstName"/>
                            </div>
                            <div *ngIf="patientField['firstName'].errors && patientDetailsForm.get('firstName')?.touched" class="alc_input-error">
                              <div *ngIf="patientField['firstName'].errors['required']">
                                First Name is required
                              </div>
                              <div *ngIf="patientField['firstName'].errors['maxlength']">
                                Maximum length: 50 characters
                              </div>
                              <div *ngIf="patientField['firstName'].errors['forbiddenCodeSnippet']">
                                Code is not allowed in First Name
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="alc_div_container">
                        <div class="alc-input-gap">
                          <label class="alc_input-label">Middle Name <span
                              *ngIf="patientField['middleName'].validator"></span></label>
                          <div class="alc_input-wrapper-right">
                            <input type="text" [ngClass]="patientField['middleName'].errors && patientDetailsForm.get('middleName')?.touched ? 'alc_input-text alc_form_error' : 'alc_input-text'" formControlName="middleName"/>
                          </div>
                          <div *ngIf="patientField['middleName'].errors" class="alc_input-error">
                            <div *ngIf="patientField['middleName'].errors['maxlength']">
                              Maximum length: 50 characters
                            </div>
                            <div *ngIf="patientField['middleName'].errors['forbiddenCodeSnippet']">
                              Code is not allowed in Middle Name
                            </div>
                          </div>

                        </div>
                        <div class="alc-input-gap" [ngClass]="{'alc-disabled-field': isPatientIDEdited }">
                          <label class="alc_input-label">Last Name<span
                              *ngIf="patientField['lastName'].validator">*</span></label>
                          <div class="alc_input-wrapper">
                            <input type="text" [ngClass]="patientField['lastName'].errors && patientDetailsForm.get('lastName')?.touched ? 'alc_input-text alc_form_error' : 'alc_input-text'" formControlName="lastName"
                            [(ngModel)]="lastNameValue" (blur)="checkIsAnyPrimaryFieldEdited()"/>
                          </div>
                          <div *ngIf="patientField['lastName'].errors && patientDetailsForm.get('lastName')?.touched" class="alc_input-error">
                            <div *ngIf="patientField['lastName'].errors['required']">
                              Last Name is required
                            </div>
                            <div *ngIf="patientField['lastName'].errors['maxlength']">
                              Maximum length: 50 characters
                            </div>
                            <div *ngIf="patientField['lastName'].errors['forbiddenCodeSnippet']">
                              Code is not allowed in Last Name
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="alc_div_container">
                        <div class="alc-input-gap" [ngClass]="{'alc-disabled-field': isPatientIDEdited }">
                          <label class="alc_input-label">Date of Birth<span
                              *ngIf="patientField['tempdateOfBirth'].validator">*</span></label>
                          <div class="alc_patient_dob">
                            <input matInput [max]="getTodaysDate()" [min]="getMinDate()" [matDatepicker]="dobpicker" [ngClass]="(patientDetailsForm.get('tempdateOfBirth')?.invalid && patientDetailsForm.get('tempdateOfBirth')?.touched) ? 'alc_input-text alc_input-text--date alc_form_error': 'alc_input-text alc_input-text--date'" placeholder="mm/dd/yyyy"
                              formControlName="tempdateOfBirth" maxlength="10" (input)="onInput($event)" (dateChange)="checkIsAnyPrimaryFieldEdited()"/> 
                              <div class="alc_input-wrapper-right" *ngIf="!(isPatientIDEdited )" [ngClass]="{'alc_input-wrapper--date alc_patient_dob_click' : patientDetailsForm.get('tempdateOfBirth')?.value === '', 'alc_input-wrapper--icon':  patientDetailsForm.get('tempdateOfBirth')?.value !== ''}" (click)="dobpicker.open()">
                              <button type="button" mat-icon-button class="clear-btn" (click)="addPatientDobClear($event)" *ngIf="patientDetailsForm.get('tempdateOfBirth')?.value !== ''">
                                <mat-icon>close</mat-icon>
                              </button> 
                              </div>
                              <mat-datepicker panelClass="alcon-date-picker" [startView]="patientDetailsForm.get('tempdateOfBirth')?.value === '' ? 'multi-year': 'month'" #dobpicker></mat-datepicker>
                          </div>
                          <div *ngIf="patientDetailsForm.get('tempdateOfBirth')?.invalid && patientDetailsForm.get('tempdateOfBirth')?.touched">
                            <div *ngIf="patientDetailsForm.get('tempdateOfBirth')?.value === ''" class="alc_input-error">
                              Date of Birth is required
                            </div>
                            <ng-container *ngIf="patientDetailsForm.get('tempdateOfBirth')?.value !== ''">
                              <div *ngIf="patientDetailsForm.get('tempdateOfBirth')?.errors?.['invalidDate'] " class="alc_input-error">
                                {{validationText}}
                              </div>
                              <div *ngIf="patientDetailsForm.get('tempdateOfBirth')?.errors?.['matDatepickerMin']" class="alc_input-error">
                                Enter a valid date of birth (mm/dd/yyyy).
                              </div>
                            </ng-container>
                          </div>
                        </div>
                        <div [ngClass]="{'alc-disabled-field': isPatientIDEdited }">
                          <label class="alc_input-label">Gender<span
                              *ngIf="patientField['gender'].validator">*</span></label>
                          <div class="alc_input-wrapper">
                            <mat-form-field 
                              class="alc_mat-form-field-outlined"
                              [ngClass]="patientField['gender'].errors && patientDetailsForm.get('gender')?.touched ? 'alc_mat-form-field-outlined--error' : ''"
                            >
                                <mat-select
                                    panelClass="alc_list-select-container"
                                    formControlName="gender"
                                    placeholder="Select a gender"
                                    (selectionChange)="checkIsAnyPrimaryFieldEdited()"
                                >
                                    <mat-option 
                                    *ngFor="let patientGender of patientGenderList"
                                    [value]="patientGender.value"
                                    >{{patientGender.label}}</mat-option>
                                </mat-select>
                                <mat-error *ngIf="patientField['gender'].errors && patientField['gender'].errors['required']">
                                Gender is required
                                </mat-error>
                            </mat-form-field>
                              </div>
                            </div>
                          </div>
                      <div class="alc_div_container">
                        <div class="phone-parent">
                          <label class="alc_input-label">Patient Phone Number</label>
                          <div class="phone-content d-flex">
                            <div class="alc-input-gap phone-gap">
                              <div class="phone-code">
                                <mat-form-field class="alc_mat-form-field-outlined alc_mat-form-field-outlined--no-error-slot">
                                    <mat-select
                                        panelClass="alc_list-select-container"
                                        formControlName="phoneNumberCountryCode"
                                        placeholder="Select an option"
                                    >
                                        <mat-option 
                                        *ngFor="let code of countryCodeList"
                                        [value]="code.dial_code"
                                        >{{code.dial_code}}</mat-option>
                                    </mat-select>
                                </mat-form-field>
                                  </div>
                                </div>
                            <div class="alc_input right-div margin alc-input-gap phone-number-text">
                              <div class="alc_input-wrapper">
                                <input appPhoneMask [ngClass]="patientField['phoneNumber'].errors && patientDetailsForm.get('phoneNumber')?.touched ? 'alc_input-text alc_input-text--phone alc_form_error':'alc_input-text alc_input-text--phone'"
                                      placeholder="(XXX) XXX-XXXX" formControlName="phoneNumber" maxlength="14"/>
                              </div>
                            </div>
                          </div>
                          <div *ngIf="patientField['phoneNumber'].errors && patientDetailsForm.get('phoneNumber')?.touched" class="alc_input-error">
                            <div>
                              Enter a valid phone number.
                            </div>
                          </div>
                        </div>
                        <div class="alc_div_container email-container">
                        <div class="alc-input-gap">
                          <label class="alc_input-label">Patient Email</label>
                          <div class="alc_input-wrapper">
                            <input type="text" [ngClass]="patientField['email'].errors && patientDetailsForm.get('email')?.touched ? 'alc_input-text alc_form_error':'alc_input-text'" formControlName="email"/>
                          </div>
                          <div *ngIf="patientField['email'].errors && patientDetailsForm.get('email')?.touched" class="alc_input-error">
                            <div *ngIf="patientField['email'].errors['required']">
                              Email is required
                            </div>
                            <div *ngIf="patientField['email'].errors['maxLength']">
                              Maximum length: 50 characters
                            </div>
                            <div *ngIf="patientField['email'].errors['pattern']">
                              Enter a valid email address.
                            </div>
                          </div>
                        </div>
                      </div>
                      </div>
                      <div class="alc_div_container">
                        <div class="alc-input-gap">
                          <label class="alc_input-label">Alternate Phone Number</label>
                          <div class="alc_input-wrapper-right alc_input-wrapper--phone">
                            <input appPhoneMask
                              [ngClass]="patientField['alternatePhoneNumber'].errors && patientDetailsForm.get('alternatePhoneNumber')?.touched ? 'alc_input-text alc_input-text--phone alc_form_error':'alc_input-text alc_input-text--phone'"
                              placeholder="(XXX) XXX-XXXX" formControlName="alternatePhoneNumber" maxlength="14" />
                          </div>
                          <div
                            *ngIf="patientField['alternatePhoneNumber'].errors && patientDetailsForm.get('alternatePhoneNumber')?.touched"
                            class="alc_input-error">
                            <div>
                              Enter a valid phone number.
                            </div>
                          </div>
                        </div>
                        <div class="alc-input-gap">
                          <label class="alc_input-label">Alternate Contact Name</label>
                          <div class="alc_input-wrapper">
                            <input
                              [ngClass]="patientField['alternateContactName'].errors && patientDetailsForm.get('alternateContactName')?.touched ? 'alc_input-text alc_form_error':'alc_input-text alc_input-text--phone'"
                              formControlName="alternateContactName" maxlength="50" />
                          </div>
                          <div
                            *ngIf="patientField['alternateContactName'].errors && patientDetailsForm.get('alternateContactName')?.touched"
                            class="alc_input-error">
                            <div>
                              Enter a valid name.
                            </div>
                          </div>
                        </div>
                      </div>
                      </div>
                    </div>
                  </div>
                </div>
            </div>
          </div>
          <!-- Patient Medical Details -->
          <div class="right-div">
            <div class="alc_form alc_form--create">
                <div class="alc_form-wrapper alc_form-wrapper--column">
                  <div class="alc_form-wrapper-column">
                    <div class="alc_form-content">
                      <div *ngIf="errorMessage" class="error-msg" role="alert">
                        <strong>Error!</strong> {{ errorMessage }}
                      </div>
                      <div class="alc_inputs">
                      <div class="alc_div_container">
                        <div class="alc-input-gap" [ngClass]="{'alc-disabled-field': ( isPrimaryFieldEdited)}">
                          <label class="alc_input-label">{{getLabelByUserRole()}}  Patient ID<span
                              *ngIf="patientField['patientMrn'].validator">*</span></label>
                          <div class="alc_input-wrapper-right">
                            <input type="text" (blur)="validatePatientMrn($event);checkIsPatientIDEdited()" [ngClass]="patientField['patientMrn'].errors && patientDetailsForm.get('patientMrn')?.touched ? 'alc_input-text alc_form_error': 'alc_input-text'" formControlName="patientMrn"/>
                            <div class="alc-text-loader" *ngIf="isMrnCheckLoading"></div>
                          </div>
                          <div *ngIf="patientField['patientMrn'].errors && patientDetailsForm.get('patientMrn')?.touched" class="alc_input-error">
                            <div *ngIf="patientField['patientMrn'].errors['required']">
                              {{getLabelByUserRole()}} Patient ID is required
                            </div>
                            <div *ngIf="patientField['patientMrn'].errors['maxlength']">
                              Maximum length: 50 characters
                            </div>
                            <div *ngIf="patientField['patientMrn'].errors['forbiddenCodeSnippet']">
                              Code is not allowed in Patient ID
                            </div>
                            <div *ngIf="patientField['patientMrn'].errors['duplicate']">
                               Patient ID already exists
                            </div>
                          </div>
                        </div>

                        <div class="alc-input-gap">
                          <div class="alc_input-slide-toggle">
                            <label class="alc_add_patient_switch_label"><span class="switch-label">Diabetic</span></label>
                            <label class="switch">
                              <input type="checkbox" id="alc_input-checkbox-diabetic" formControlName="diabetesStatus">
                              <span class="slider round"></span>
                            </label>
                          </div>
                        </div>
                      </div>
                        <div class="alc-input-gap">
                          <label class="alc_input-label">Allergies</label>
                          <div class="alc_input-wrapper">
                            <textarea type="text" [ngClass]="patientField['allergies'].errors ? 'alc_input-textarea alc_form_error':'alc_input-textarea'" formControlName="allergies"></textarea>
                          </div>
                          <div *ngIf="patientField['allergies'].errors"
                            class="alc_input-error">
                            <div *ngIf="patientField['allergies'].errors['maxlength']">
                              Maximum length: 200 characters
                            </div>
                            <div *ngIf="patientField['allergies'].errors['forbiddenCodeSnippet']">
                              Code is not allowed in Allergies
                            </div>
                          </div>
                        </div>
                      <div class="alc_div_container">
                        <div>
                          <label class="alc_input-label">Preferred Language, If Other Than English</label>
                          <div class="alc_input-wrapper-right">
                            <mat-form-field 
                              class="alc_mat-form-field-outlined"
                            >
                                <mat-select
                                    panelClass="alc_list-select-container"
                                    formControlName="languagesSpoken"
                                    placeholder="Select a Language"
                                >                                 
                                    <mat-option value="">Select a Language</mat-option>
                                    <mat-option 
                                    *ngFor="let lang of prefferedLangList"
                                    [value]="lang.code"
                                    >{{ lang.display }}</mat-option>
                                </mat-select>
                            </mat-form-field>
                            </div>
                          <div *ngIf="patientField['languagesSpoken'].errors"
                            class="alc_input-error">
                            <div *ngIf="patientField['languagesSpoken'].errors['forbiddenCodeSnippet']">
                              Code is not allowed in Preferred Language
                            </div>
                          </div>
                        </div>

                        <div class="alc-input-gap">
                          <div class="alc_input-slide-toggle">
                            <label class="alc_add_patient_switch_label"><span class="switch-label">Translator Needed</span></label>
                            <label class="switch">
                              <input type="checkbox" id="translator_needed" formControlName="translatorNeeded">
                              <span class="slider round"></span>
                            </label>
                          </div>
                        </div>
                        <div>
                          <label class="alc_input-label">Sign Language Interpreter</label>
                          <div class="alc_input-wrapper-right">
                            <mat-form-field 
                              class="alc_mat-form-field-outlined"
                            >
                                <mat-select
                                    panelClass="alc_list-select-container"
                                    formControlName="signLanguage"
                                    placeholder="Select a Language"
                                   
                                >                                 
                                    <mat-option 
                                    *ngFor="let signlang of signLanguageList"
                                    [value]="signlang.value"
                                    >{{ signlang.label }}</mat-option>
                                </mat-select>
                            </mat-form-field>
                            </div>
                        </div>
                        </div>
                        <div>
                          <label class="alc_input-label">Patient Time of Day Preference</label>
                          <div class="alc_input-wrapper">
                            <mat-form-field class="alc_mat-form-field-outlined">
                                <mat-select
                                    panelClass="alc_list-select-container"
                                    formControlName="patientTimeOfDayPreference"
                                    placeholder="Select an option"
                                >
                                    <mat-option 
                                    *ngFor="let slot of patientPreferedTimeOfDay"
                                    [value]="slot.value"
                                    >{{ slot.label }}</mat-option>
                                </mat-select>
                            </mat-form-field>
                            </div>
                          </div>

                        <div class="alc-input-gap">
                          <label class="alc_input-label">Patient Comments</label>
                          <div class="alc_input-wrapper">
                            <textarea type="text" placeholder="eg. Claustrophobic" [ngClass]="patientField['comments'].errors ? 'alc_input-textarea alc_form_error':'alc_input-textarea'" formControlName="comments"></textarea>
                          </div>
                          <div *ngIf="patientField['comments'].errors"
                            class="alc_input-error">
                            <div *ngIf="patientField['comments'].errors['maxlength']">
                              Maximum length: 300 characters
                            </div>
                            <div *ngIf="patientField['comments'].errors['forbiddenCodeSnippet']">
                              Code is not allowed in Comments
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              <!-- </form> -->
            </div>
          </div>
      </div>
    </form>
      <div class="alc_form-actions" *ngIf="!showPatientOnly else patientContent">
        <button
          type="button"
          class="alc_button alc_button--secondary alc_add_patient_cancel"
          (click)="closePopup()">
          Close
        </button>
        <div class="alc_add_patient_save_buttons">
          <button mat-button type="button" class="alc_input-button alc_input-button--text alc_patient_details_save" (click)="patientInfoSubmit('save')" [disabled]='!patientDetailsForm.valid || showPrimaryfieldUpdate || showPatientExistsError'>Save and close</button>
          <button mat-button
            type="submit"
            class="alc_button alc_button--primary alc_add_patient_button"
            (click)="patientInfoSubmit('add')"
            [disabled]="!patientDetailsForm.valid || showPrimaryfieldUpdate || showPatientExistsError"
          >{{!surgeryID ? 'Add surgery details': 'Edit surgery details'}}
          </button>
        </div>
      </div>
      <ng-template #patientContent>
        <div class="alc_form-actions alcon_add_patient_only_buttons">
          <button
            type="button"
            class="alc_button alc_button--secondary alc_add_patient_cancel"
            (click)="closePopup()">
            Close
          </button>
            <button mat-button
              type="submit"
              class="alc_button alc_button--primary alc_add_patient_button"
              (click)="patientInfoSubmit('add')"
              [disabled]="!patientDetailsForm.valid || showPrimaryfieldUpdate || !patientFormChange"
            >Save Changes
            </button>
        </div>
      </ng-template>

    </mat-step>
    <mat-step *ngIf="!showPatientOnly">
      <ng-template matStepLabel>
        <div class="stepper-header-overlay" *ngIf="!(!patientDetailsForm.valid || showPrimaryfieldUpdate)" (click)="moveNext(false, 'surgery', $event)"></div>
        <span class="mcc-step-patient-label" [ngClass]="{'disabled-label': (!patientDetailsForm.valid || showPrimaryfieldUpdate)}"><span class="mcc-f-weight">{{hidePatientInfo ? '01' : '02'}}</span> Surgery details</span>
        <div class="custom-step-header">
          <ng-template [ngTemplateOutlet]="disabledStep" *ngIf="(!patientDetailsForm.valid || showPrimaryfieldUpdate)"></ng-template>
          <ng-template [ngTemplateOutlet]="enableStep" *ngIf="(!(!patientDetailsForm.valid || showPrimaryfieldUpdate)) && currentTabValue !== 'surgery' && !isSurgerySubmitted"></ng-template>
          <ng-template [ngTemplateOutlet]="activeStep" *ngIf="(!(!patientDetailsForm.valid || showPrimaryfieldUpdate)) && currentTabValue === 'surgery'"></ng-template>
          <ng-template [ngTemplateOutlet]="completedStep" *ngIf="isSurgerySubmitted && currentTabValue !== 'surgery' && (!(!patientDetailsForm.valid || showPrimaryfieldUpdate))"></ng-template>
        </div>
      </ng-template>
      <div *ngIf="currentTabValue === 'surgery'">
      <app-edit (surgeryLoader)="getSurgeryLoader($event)" (scrollToTop)="scrollToTopWithoutDelay()" 
      [tabType]="'surgery-details'" *ngIf="patientSubmitted" [data]="patientData" [basicCatractSurgeryInfo]="data?.basicCatractInfo"
      [exchangeSurgeryInfo]="data?.exchangeSurgery"
      [fromEditSurgery]="fromEditSurgery" [fromPatientDetail]="fromPatientDetail"
      (isFormValid)="surgeonFormValid = $event" (sentSurgeryId)="getSurgeryId($event)"
      [fromCloneSurgery]="fromCloneSurgery" (changeSurgeryObj)="patientData = $event"
      [doSubmit]="doSubmit" (switchCloneToEdit)="convertCloneToEditSurgery()"></app-edit>
    </div>
    </mat-step>
    <mat-step *ngIf="isIOLTab && !showPatientOnly">
      <ng-template matStepLabel>
        <div class="stepper-header-overlay" *ngIf="surgeonFormValid && !(!patientDetailsForm.valid || showPrimaryfieldUpdate)" (click)="moveNext(true, 'iol', $event)"></div>
        <span class="mcc-step-patient-label" [ngClass]="{'disabled-label': !surgeonFormValid || (!patientDetailsForm.valid || showPrimaryfieldUpdate)}"><span class="mcc-f-weight">{{hidePatientInfo ? '02' : '03'}}</span> IOL specs</span>
        <div class="custom-step-header">
         
          <ng-template [ngTemplateOutlet]="disabledStep" *ngIf="!surgeonFormValid || (!patientDetailsForm.valid || showPrimaryfieldUpdate)"></ng-template>
          <ng-template [ngTemplateOutlet]="activeStep" *ngIf="surgeonFormValid && currentTabValue === 'iol' && (!(!patientDetailsForm.valid || showPrimaryfieldUpdate))"></ng-template>
          <ng-template [ngTemplateOutlet]="enableStep" *ngIf="surgeonFormValid &&  currentTabValue !== 'iol' && !fromEditSurgery && (!(!patientDetailsForm.valid || showPrimaryfieldUpdate))"></ng-template>
          <ng-template [ngTemplateOutlet]="editableStep" *ngIf="surgeonFormValid && fromEditSurgery && currentTabValue !== 'iol' && (!(!patientDetailsForm.valid || showPrimaryfieldUpdate))" ></ng-template>
        </div>
      </ng-template>
      <div *ngIf="currentTabValue === 'iol'" class="h-100">
        <app-edit (surgeryLoader)="getSurgeryLoader($event)" [tabType]="'iol-specs'" *ngIf="isSurgerySubmitted" 
        [fromIOLOnly]="fromIOLOnly" [fromEditSurgery]="fromEditSurgery" [fromPatientDetail]="fromPatientDetail" [data]="patientData"
        (isFormValid)="surgeonFormValid = $event" [doSubmit]="doSubmit" [getSurgeryId]="surgeryID" [fromCloneSurgery]="fromCloneSurgery"></app-edit>
      </div>
    </mat-step>
  </mat-horizontal-stepper>

</div>

<ng-template #activeStep>
  <div class="active-line"></div>
  <mat-icon><img class="mcc-step-icon-edit" src="assets/step-circle.svg" alt="" /></mat-icon>
  <div class="active-line"></div>
</ng-template>
<ng-template #disabledStep>
  <div class="disabled-line"></div>
  <mat-icon><img class="mcc-step-icon-edit" src="assets/stepcircle_inactive.svg" alt="" /></mat-icon>
  <div class="disabled-line"></div>
</ng-template>
<ng-template #completedStep>
  <div class="completed-line"></div>
  <mat-icon><img class="mcc-step-icon-edit" src="assets/step-checkmark.svg" alt="" /></mat-icon>
  <div class="completed-line"></div>
</ng-template>
<ng-template #editableStep>
  <div class="completed-line"></div>
  <mat-icon><img class="mcc-step-icon-edit" src="assets/step-edit.svg" alt="" /></mat-icon>
  <div class="completed-line"></div>
</ng-template>
<ng-template #enableStep>
  <div class="completed-line"></div>
  <mat-icon><img class="mcc-step-icon-edit" src="assets/step-circle.svg" alt="" /></mat-icon>
  <div class="completed-line"></div>
</ng-template>

<ng-container *ngIf="showPatientList">
 <app-existing-patient-list
 [patientDetail]="patientDetailsForm.value"
 (closePopupEmit)="closePopup()"
 (goBack)="backToAddPage()"
 (showError)="errorAlertDialog($event)"
 ></app-existing-patient-list>
</ng-container>
