import { Injectable } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { adiStorageKey } from '../constants/auth.constant';

@Injectable({
  providedIn: 'root'
})
export class NavigationService {
  currentLanguage = localStorage.getItem(adiStorageKey.lang) ?? 'en_US';

  constructor(private router: Router, private route: ActivatedRoute) {
    this.route.params.subscribe(params => {
      if(params['locale']){
        this.currentLanguage = params['locale'];
      }
    });
  }

  navigateTo(urlParts: string[], params?: Params) {
    if(params)
      this.router.navigate([this.currentLanguage, ...urlParts],{queryParams: params});
    else
      this.router.navigate([this.currentLanguage, ...urlParts]);
  }
}

