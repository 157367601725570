import { inject, Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from "./auth.service";
import { SessionStorageService } from './session-storage.service';
import { loginConfigs, previousSessionConfigs } from './constants/auth.constant';
import { NavigationService } from './service/navigation.service';
import { userStatus } from './constants/auth.constant';
import { OktaAuth } from '@okta/okta-auth-js';
import { OktaAuthStateService, OKTA_AUTH } from '@okta/okta-angular';
import { JwtDecodeService } from './service/jwt-decode.service';
import { environment } from 'src/environments/environment';
import { SharedService } from './service/shared.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard  {
 
  constructor(
    @Inject(OKTA_AUTH) public oktaAuth: OktaAuth,
    public authService: AuthService,
    public router: Router,
    public navigationService: NavigationService,
    public sharedService: SharedService,
    private jwtDecodeService: JwtDecodeService
  ) { }
  
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      
      

      return this.oktaAuth.isAuthenticated().then(async (isAuthenticated) => {
        if (isAuthenticated) {
          return true;
        }
        else {
          if(window.location.host.includes('localhost')){
            await this.oktaAuth.signInWithRedirect();
          }
          else if (this.authService.hasAdiHomeToken()) {
            this.sharedService.saveRevisitedUrls(route, state);
            await this.oktaAuth.signInWithRedirect();
          } 
          else {       
            this.sharedService.saveRevisitedUrls(route, state);
            window.location.href = `${window.location.origin}`;
          }
        return false;
      }
      });
      
  }  
}
