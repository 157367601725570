import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SectionLoaderComponent } from './section-loader.component';


@NgModule({
    imports: [
        CommonModule
    ],
    declarations: [
        SectionLoaderComponent
      ],
      exports: [
        SectionLoaderComponent
      ]
  })
  export class SectionLoaderModule {}
