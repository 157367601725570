<div class="alc-custom-dialog-container">
    <div class="alc-custom-dialog-close-icon">
      <mat-icon
      (click)="closePopup(dialogAction.close)"
      aria-hidden="false"
      aria-label="close icon"
      fontIcon="close">
    </mat-icon>
    </div>
    <div class="alc-custom-dialog-content-container">
        <img
        [src]="data.icon"
        [alt]="'icon'" *ngIf="data.icon"
      />

        <div class="alc-custom-dialog-content-title">
            <h1>{{data.title}}</h1>
        </div>
        <div class="alc-custom-dialog-message">
            <p [innerHTML]="text"></p>
        </div>
    </div>
  
  <div class="alc-custom-dialog-content-footer" [ngClass]="{'alc-custom-dialog-content-footer--reverse': data.isReverseButtonOrder}">
    <button *ngIf="data.buttonText.secondary" type="button" class="alc_button alc_button--secondary" (click)="closePopup(dialogAction.secondary)">{{data.buttonText.secondary}}</button>
    <button *ngIf="data.buttonText.primary" type="button" class="alc_button alc_button--primary" (click)="closePopup(dialogAction.primary)">{{data.buttonText.primary}}</button>
  </div>
</div>