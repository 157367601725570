import { Injectable } from '@angular/core';
import { DeviceTypes } from './constants/constants';

@Injectable({
  providedIn: 'root'
})
export class DeviceService {

  constructor() { }

  getDeviceType(): DeviceTypes {
    const width = window.innerWidth;
    if(width < 768) {
      return DeviceTypes.mobile;
    } else if(width < 992) {
      return DeviceTypes.tablet;
    } else {
      return DeviceTypes.desktop;
    }
  }
}
