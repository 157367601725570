import { Component, DestroyRef } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { AlconClockService } from 'src/app/shared/service/alcon-clock.service';
import { AlconOrExperienceService } from '../../services/alcon-or-experience.service';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'alcon-or-room-clock',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './or-room-clock.component.html',
  styleUrl: './or-room-clock.component.scss'
})
export class OrRoomClockComponent {
  public time = "";
  public date = "";

  constructor(
    private destroyRef: DestroyRef,
    private clockService: AlconClockService,
    public oreService: AlconOrExperienceService,
  ){
    this.clockService.dateTimeEveryMinute$.pipe(
      takeUntilDestroyed(this.destroyRef)
    ).subscribe((dateTime)=>{
      this.time = dateTime.time;
      this.date = dateTime.date;
    })
  }
}
