import { Component, ViewEncapsulation } from '@angular/core';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { EditHistoryEntry, InORExperience, OrDayAction, SurgeryRecord, surgeryDetailsOR, surgeryInfo } from '../../models/alcon-or-experience.model';
import * as surgeryMockData from '../../../../../assets/stub-data/ore.json';
import { AlconOrHeaderComponent } from '../../components/alcon-or-header/alcon-or-header.component';
import { CommonModule, DatePipe } from '@angular/common';
import { OrRoomClockComponent } from '../../components/or-room-clock/or-room-clock.component';
import { AlconOrExperienceService } from '../../services/alcon-or-experience.service';
import { AlconOreTimeoutCaptureComponent } from "../../components/alcon-ore-timeout-capture/alcon-ore-timeout-capture.component";
import { OREThemes, facility, selectedORETheme } from 'src/app/shared/constants/auth.constant';
import {
  SubMenuFieldsORE,
  SummaryDetailsORE } from '../../constants/alcon-or-experience.constants';
import { AlconOreHistoryItemComponent } from '../../components/alcon-ore-history-item/alcon-ore-history-item.component';
import { SurgeryGraphqlService } from 'src/app/shared/service/alcon-graphql/surgery-graphql.service';
import { ActivatedRoute } from '@angular/router';
import { UtilityService } from 'src/app/shared/service/utility.service';
import { lensPriority } from 'src/app/shared/model/iol.model';
import { HistoryLogResponse } from 'src/app/shared/alcon-history-log/Model/alcon-history-log-model';
import { SurgeryService } from 'src/app/shared/surgery.service';
import { customDialog } from 'src/app/components/modal-dialogs/dialog.model';
import { CustomDialogComponent, dialogAction } from 'src/app/components/modal-dialogs/custom-dialog/custom-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { SectionLoaderModule } from "../../../../components/section-loader/section-loader.module";
import { timeFormatValidator } from 'src/app/shared/validators/time-fomat-validator';
import { surgeryStatus } from 'src/app/shared/model/surgery.model';
import { AuthService } from 'src/app/shared/auth.service';
import { SharedService } from 'src/app/shared/service/shared.service';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-alcon-or-experience',
  standalone: true,
  imports: [
    AlconOrHeaderComponent,
    CommonModule,
    OrRoomClockComponent,
    AlconOreHistoryItemComponent,
    AlconOreTimeoutCaptureComponent,
    MatIconModule,
    SectionLoaderModule
],
  templateUrl: './alcon-or-experience.component.html',
  styleUrl: './alcon-or-experience.component.scss',
})
export class AlconOrExperienceComponent {
  surgeryData: SurgeryRecord | undefined ;
  subMenuFieldsORE = SubMenuFieldsORE;
  summaryDetailsORE = SummaryDetailsORE;
  isPastSurgery = false;
  timeOutForm: FormGroup = new FormGroup({});

  constructor(
    public oreService: AlconOrExperienceService,
    private surgeryGraphqlService: SurgeryGraphqlService,
    private route: ActivatedRoute,
    private datePipe: DatePipe,
    private utilityService: UtilityService,
    private surgeryService: SurgeryService,
    public dialog: MatDialog,
    private authService: AuthService,
    public sharedService: SharedService,
    private titleService: Title

  ) {}

  ngOnInit() {
    this.titleService.setTitle('Adi - Heads Up Display');
    this.getSurgeryDetails();
  }

  configureTimeoutForm(surgery: surgeryDetailsOR) {
    const orDayActions = JSON.parse(JSON.stringify(this.oreService.timeOutData.getValue()));
    let isActive = false;
    this.timeOutForm.addControl('surgeryId', new FormControl(surgery.surgeryData.surgeryId)); 
    this.timeOutForm.addControl('patientId', new FormControl(surgery.surgeryData.patientId));
    this.timeOutForm.addControl('surgeryDate', new FormControl(surgery.surgeryData.surgeryDate));
    this.timeOutForm.addControl('practiceOrg', new FormControl(surgery.surgeryData.practiceOrg));
    orDayActions.forEach((value: OrDayAction, index: number) => {
      const fieldKey = value.field as keyof InORExperience;
      const formatedTime = this.oreService.formatToHHMMAmPm(false, surgery?.surgeryData?.inORExperience?.[fieldKey] ?? '');
      this.timeOutForm.addControl(value.field, new FormControl(formatedTime , [Validators.required, timeFormatValidator()]));
      value.recordedTime = formatedTime;
      if(!isActive && !formatedTime) {
        isActive = true;
        value.isActive = true;
        if(orDayActions[index-1]) {
          orDayActions[index-1].isLastUpdated = true;
        }
      } 
    });  
    this.oreService.timeOutData.next(orDayActions);
  }

  configureEditPermission(surgery: surgeryDetailsOR) {
    if(surgery.surgeryData.status === surgeryStatus.COMPLETED || this.authService.accountType === facility.clinic) {
      this.oreService.hasEditPermission = false;
    }
  }

  getSurgeryDetails() {
    this.oreService.oreProcessIsLoading = true;
    const surgeryId = this.route.snapshot.paramMap.get('surgeryId') ?? '';
    this.surgeryGraphqlService.getSurgeryDetailsforOR(surgeryId).subscribe({
      next: (res: any) => {
        const surgeryRecord =
          res?.data?.surgeryResponse?.surgeries[0];
        if (surgeryRecord) {
          this.configureEditPermission(surgeryRecord);
          this.surgeryData = this.populateTemplateData(surgeryRecord);
          const currentDate = new Date();
          currentDate.setHours(0, 0, 0, 0); 
          this.isPastSurgery = currentDate > (new Date(surgeryRecord.surgeryData.surgeryDate));
          this.configureTimeoutForm(surgeryRecord);
        }
        this.getSurgeryHistoryLog(surgeryId,this.surgeryData?.patientId, this.surgeryData?.practiceId);
      },
      error: (err: any) => {
        this.oreService.oreProcessIsLoading = false;
        this.sharedService.showRetryAlert().then(()=>{
          this.getSurgeryDetails();
        });
      },
    });
  }

  getPrimaryIOL(iol: any){
    let primaryIol;
    if(iol && Array.isArray(iol)) {
      primaryIol = iol.find((iolItem: any) => {
        return iolItem?.lensPreference === lensPriority.primary;
      });
    }
    return primaryIol;
  }

  public getModelDiopterDisplay(model: string, diopter: string){
    if(!model && (!diopter || isNaN(parseFloat(diopter)))){
      return "Model and diopter pending"
    }else if(model && (!diopter || isNaN(parseFloat(diopter)))){
      return "Primary IOL: " + model + ', ' + "diopter pending"
    }else {
      return "Primary IOL: " + model + ', ' + this.utilityService.formatLensDiopter(diopter)
    }
  }
  
  populateTemplateData(surgery: surgeryDetailsOR) {
    let primaryIol = this.getPrimaryIOL(surgery.iolSurgeonPreferences);
    return {
      accountName: surgery.asc.name,
      surgeryType: surgery.surgeryData.procedure,
      patientFirstName: surgery.patient.firstName,
      patientLastName: surgery.patient.lastName,
      dob: this.datePipe.transform(surgery.patient.birthDate,'MM/dd/yyyy') + ' (' + this.utilityService.calculateAge(surgery.patient.birthDate) + ' yo)',
      patientMrn: surgery.patient.mrn,
      patientId: surgery.surgeryData.patientId,
      gender: this.utilityService.getGender(surgery.patient.gender),
      diabetic: this.utilityService.getYesNo(surgery.patient.additionalInfo.diabetesStatus),
      allergies: surgery.patient?.additionalInfo?.allergyOther?.[0] || '---',
      surgeryOn: surgery.surgeryData.eye,
      eyeLabel: this.getEyeLabel(surgery.surgeryData, true),
      eye: surgery.surgeryData.eye == 'L' ? 'left' : 'right',
      avatarEyeLabel: this.getEyeLabel(surgery.surgeryData, false),
      lens: primaryIol && primaryIol.lensType ? primaryIol.lensType : '---',
      setFor: surgery.surgeryData.setFor || '---',
      ora: this.utilityService.getYesNo(surgery.surgeryData.ora),
      femto: this.utilityService.getYesNo(surgery.surgeryData.lensx),
      meds: surgery.surgeryData.postopMeds || '---',
      PatientNote: surgery.patient.additionalInfo.comments || '---',
      practiceId: surgery.patient.practiceId,
      editHistory: [],
      primaryIol: primaryIol ? this.getModelDiopterDisplay(primaryIol.model, primaryIol.diopter) : '',
      surgeryDate: surgery.surgeryData.surgeryDate
    };
  }

  getEyeLabel(data: surgeryInfo, includeIcon = false) {
    let eyeIcon = '';
    if(includeIcon) {
      eyeIcon = `<img src="assets/images/${ data.eye == 'L' ? 'oculus_sinister' : 'oculus_dexter'}.svg" />`;
    }
    if(data.procedure === 'Cataract Surgery' ) {
      return `${data.eye == 'L' ? 'Left' : 'Right'} ${eyeIcon} / ${ (data.firstOrSecondEye ) || '---' }`;
    }
    return `${data.eye == 'L' ? 'Left' : 'Right'} ${eyeIcon} ${ (data.firstOrSecondEye) ? '/ '+ data.firstOrSecondEye : '' }`;
  }

  getSurgeryHistoryLog(surgeryId:string, patientId:string = '',practiceOrg:string = '') {
    this.surgeryService.getRecentChanges(practiceOrg, patientId, surgeryId).subscribe(
      {
      next: (historyLogResponse: HistoryLogResponse) =>
      {
          this.oreService.oreProcessIsLoading = false;   
          let logDetails: EditHistoryEntry[] = [];
          let count = 0;
          if (historyLogResponse.data?.length){
            for (let historylog of historyLogResponse.data){
              if(historylog.logDelta){
                for (let log of historylog.logDelta){
                        logDetails.push({
                          field: this.utilityService.showValueForField(log.field),
                          oldValue: this.utilityService.showFormattedData(log.originalValue,log.field),
                          newValue: this.utilityService.showFormattedData(log.updatedValue,log.field),
                          date: this.datePipe.transform(historylog.dateTime ,'MM/dd/yyyy'),
                          name: historylog.user,
                        });
                        count++;
                        if(count === 5){
                          break;
                        }
                }
              }
              if(count === 5){
                break;
              }
            }
          }
          if(this.surgeryData)
              this.surgeryData.editHistory = logDetails;
      },
      error: (err: any) => {
        this.oreService.oreProcessIsLoading = false;
      }
    }
    );
  }
  onModeChange(isDarkModeEnabled: boolean) {
    localStorage.setItem(selectedORETheme, isDarkModeEnabled ? OREThemes.dark : OREThemes.light);
    this.oreService.isDarkModeEnabled.next(isDarkModeEnabled);
  }

  onExitOR(isDarkModeEnabled: boolean) {
    const data = this.oreService.timeOutData.getValue();
    if (data.filter(i => i.recordedTime).length === data.length) {
     //if timeout complete showExitORPopUp()
      this.showExitORPopUp();
    } else {
     //if timeout incomplete exitOR()
      this.exitOR();
    }

  }

  showExitORPopUp(){
    const dialogData: customDialog = {
      type: '',
      icon: this.oreService.isDarkModeEnabled.value?'assets/images/round-check-light-green.svg':'assets/images/round-check-green.svg' ,
      title: 'Time out is complete!',
      text:'You may stay on the Heads-up Display. To complete the surgery, go to the Patient Page.',
      buttonText: {
        primary: 'View Patient',
        secondary: 'Close',
      },
    };
    const surgeriesResponseDialog =  this.dialog.open(CustomDialogComponent,{
      position: {
        top : '120px'
      },
      panelClass:['alc_exit-or-popup', this.oreService.isDarkModeEnabled.value?'alc_or_popup_dark_mode':'alc_or_popup_light_mode'],
      hasBackdrop: true,
      disableClose: true,
      backdropClass: this.oreService.isDarkModeEnabled.value?'dialog-backdrop-dark':'dialog-backdrop',
      data: dialogData,
    });
    surgeriesResponseDialog.afterClosed().subscribe((result: dialogAction) => {
      if (result === dialogAction.primary) {
        window.close()
      } 
    });
  }

  exitOR(){
    window.close()
  }

}
