import { Component, Input, OnDestroy, OnInit, QueryList, ViewChildren } from '@angular/core';
import { MatCheckboxModule } from '@angular/material/checkbox';
import {MatAccordion} from '@angular/material/expansion';
import {MatExpansionModule} from '@angular/material/expansion';
import { Subscription } from 'rxjs';
import { IolModelPreferencesService } from '../../services/iol-model-preferences.service';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { IOLManufacturer, IOLs, lensModel, lensType, searchIOLFilter } from '../../iol-model-preferences.model';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'app-iol-models',
  standalone: true,
  imports: [ 
    MatAccordion,
    MatCheckboxModule,
    MatExpansionModule,
    CommonModule,
    MatIconModule,
    FormsModule
  ],
  templateUrl: './iol-models.component.html',
  styleUrl: './iol-models.component.scss'
})
export class IolModelsComponent implements OnDestroy, OnInit{
  @Input() title!: string;
  @Input() btnText: string = 'Make Visible';
  @Input() visible!: boolean;

  private iolSubscription!: Subscription;
  @ViewChildren(MatAccordion) accordions!: QueryList<MatAccordion>;

  allExpanded = false;
  iols!: IOLs;
  oIols!: IOLs;
  parentIOLs!: IOLs;
  selectedItem!: IOLs;
  selectedModel = 0;
  private loaderSubscription!: Subscription;
  private searchSubscription!: Subscription;
  isLoaded = false;

  searchValues: searchIOLFilter = {
    manufacturer: '',
    model: '',
    type: []
  };
  constructor(private iolModelPreferencesService: IolModelPreferencesService) {
     
  }

  ngOnInit(): void {
    this.searchSubscription = this.iolModelPreferencesService.searchSubject.subscribe((data: searchIOLFilter) => {
      this.searchValues = data;
      this.searchIOLs();
    });

    this.loaderSubscription = this.iolModelPreferencesService.loaderSubject.subscribe((data: boolean) => {
      this.isLoaded = !data;
    });

    this.iolSubscription = this.iolModelPreferencesService.updatedIOL$.subscribe(data => {
      if(data){
        this.allExpanded = false;
        this.oIols = JSON.parse(JSON.stringify(data));
        this.iols= JSON.parse(JSON.stringify(data));
        this.filterVisibleItems();
        this.parentIOLs = JSON.parse(JSON.stringify(this.iols));
        this.updateAllCheckedField(false, true);
        this.selectedItem = {
          manufacturer:[]
        };
        this.selectedModel = 0;
        this.searchIOLs();
      }
    });
  }

  searchIOLs(){
    let newValue = this.parentIOLs.manufacturer;

    if(this.searchValues.manufacturer){
      this.searchValues.manufacturer = this.searchValues.manufacturer?.toLowerCase()
      newValue = newValue.filter((manu: IOLManufacturer) => manu.name
        .toLowerCase()
      .includes(this.searchValues.manufacturer))
    }

    if(this.searchValues.type.length > 0){
      newValue = newValue
      .map(manu => ({
        ...manu,
        type: manu.type.filter(type => this.searchValues.type.includes(type.name))
      }))
      .filter(manuf => manuf.type.length > 0);
    }

    if(this.searchValues.model){
      this.searchValues.model = this.searchValues.model?.toLowerCase();
      newValue = this.searchAndFilter(newValue, this.searchValues.model);
    }

    this.iols.manufacturer = newValue;
    this.mergeSelectedItem();
    this.setManufactureCount();
  }

  setManufactureCount(){
    var lensCount = 0;
    this.iols.manufacturer.forEach(manufacturer => {
        lensCount = 0;
        manufacturer.type.forEach(type => {
          lensCount += type.lens.length; 
        });
        manufacturer.lensCount = lensCount
    });
  }

  searchAndFilter(data: IOLManufacturer[], searchTerm: string, visible?: boolean): IOLManufacturer[] {
    return data
      .map((manu: IOLManufacturer) => ({
        ...manu,
        type: manu.type
          .map(type => ({
            ...type,
            lens: type.lens.filter(lens =>
              this.getFilterCondition(lens, searchTerm, visible)
            ),
          }))
          .filter(type => type.lens.length > 0),
      }))
      .filter((manu: IOLManufacturer) => manu.type.length > 0);

      // let visibleItem: IOLManufacturer[] =[];
      // let visibleType: IOLManufacturer;
      // this.iols.manufacturer.forEach(manufacturer => {
      //   visibleType = {
      //     name: manufacturer.name,
      //     type: manufacturer.type.map((type) =>{
      //       return {...type, lens : type.lens.filter(lens => this.getFilterCondition(lens, searchTerm, visible))}
      //     }).filter(type => type.lens.length > 0)
      //   } 
      //   if(visibleType.type.length > 0){
      //   visibleItem.push(visibleType);
      // }

      // });
      // return visibleItem;
  }

  getFilterCondition(lens: lensModel, searchTerm?:string, visible?: boolean){
    if(searchTerm){
      return lens.name.toLowerCase().includes(searchTerm)
    }else if(visible){
      return lens.isVisible === this.visible;
    }else{
      return lens.checked;
    }

  }
 

    filterVisibleItems(){
      this.iols.manufacturer = this.searchAndFilter(this.oIols.manufacturer, '', true);
  }


  onExpandClick(){
    this.allExpanded = !this.allExpanded;
    this.accordions.forEach((accordion: MatAccordion) => {
      this.allExpanded ? accordion.openAll() : accordion.closeAll();
    });
  }

  ngOnDestroy() {
    this.iolSubscription.unsubscribe();
    this.loaderSubscription.unsubscribe();
    this.searchSubscription.unsubscribe();
  }

  selectCheckBox($event: any){
    $event.stopPropagation();
  }

  isAllSelected(manufacturer?: IOLManufacturer, type?: lensType): boolean{ 
    if(type){
      return type.lens.length === type.lens.filter(x=>x.checked).length ? true : false;
    }else if( manufacturer){
      return manufacturer.type.length === manufacturer.type.filter(x=>x.checked).length ? true : false;
    }else{
      return this.iols.manufacturer.length === this.iols.manufacturer.filter(x=>x.checked).length ? true : false;
    }
  }

  isAnySelected(manufacturer?: IOLManufacturer, type?: lensType): boolean{
    if(type){
      return type.lens.filter(x=>x.checked).length > 0 ? true : false;
    }else if( manufacturer){
      return manufacturer.type.filter(x=>x.checked).length > 0 || manufacturer.type.filter(x=>x.anyChildrenChecked).length > 0 ? true : false;
    }else{
      return this.iols.manufacturer.filter(x=>x.checked).length > 0 || this.iols.manufacturer.filter(x=>x.anyChildrenChecked).length > 0 ? true : false;
    }
  }
  updateCheckStatus(manufacturer?: IOLManufacturer, type?: lensType, lens? : lensModel){
    let checked = true;
    if(lens){
      lens.checked = !lens.checked;
    }
    else if(type){
      if(type.checked){
        checked = false;
      }
      this.updateIOLType(type, checked);
    }else if( manufacturer){
      if(manufacturer.checked){
        checked = false;
      }
      this.updateManufact(manufacturer, checked);
    }else{

      if(this.iols.checked){
        checked = false;
      }
      this.updateAllCheckedField(checked, false);
    }
    this.toUpdateChildrenCheckedStatus(manufacturer, type);
    this.moveToselectedList();
  }
  toUpdateChildrenCheckedStatus(manufacturer?: IOLManufacturer, type?: lensType){
    if(type){
        type.checked = this.isAllSelected(manufacturer, type);
        type.anyChildrenChecked = this.isAnySelected(manufacturer, type);
    }
    if(manufacturer){
        manufacturer.checked = this.isAllSelected(manufacturer);
        manufacturer.anyChildrenChecked = this.isAnySelected(manufacturer);
    }
     this.iols.checked = this.isAllSelected();
     this.iols.anyChildrenChecked = this.isAnySelected();
  }
  updateAllCheckedField(checked: boolean = false, getCount?: boolean): any {
    this.iols.checked = checked;
    this.iols.anyChildrenChecked = checked;
    var lensCount = 0;
      this.iols.manufacturer.forEach(manufacturer => {
        if(getCount){
          lensCount = 0;
          manufacturer.type.forEach(type => {
            lensCount += type.lens.length; 
          });
          manufacturer.lensCount = lensCount;
        }
      this.updateManufact(manufacturer, checked);
      });
  }

updateManufact(manufacturer: IOLManufacturer, checked:boolean){
  manufacturer.checked = checked;
  manufacturer.anyChildrenChecked = checked;
  manufacturer.type.forEach(type => {
    this.updateIOLType(type, checked);
  });
}

updateIOLType(type: lensType, checked: boolean){
  type.checked = checked;
  type.anyChildrenChecked = checked;
  type.lens.forEach(lens => {
    lens.checked = checked;
  });
}

moveIols(){
  this.selectedItem.manufacturer.forEach(manufacturer => {
    manufacturer.type.forEach(type => {
      type.lens.forEach(lens => {
        this.changeVisibleStatus(manufacturer, type, lens);
      });
    });
  });
  this.iolModelPreferencesService.updateIols(this.oIols);
  this.iolModelPreferencesService.setSelectedIOLs(this.oIols);

  this.iolModelPreferencesService.loaderSubject.next(false);
  this.iolModelPreferencesService.isModelSelected.next(true);
  this.selectedItem = {
    manufacturer:[]
  };
  this.selectedModel = 0;
}

moveToselectedList(){

  this.selectedItem = {
    manufacturer: this.searchAndFilter(this.iols.manufacturer, '', false),
  }
   this.selectedModel = this.selectedItem.manufacturer.reduce((grandTotal, manufacturer) => {
    const totalForManufa = manufacturer.type.reduce((typeTotal, type) => {
      const iolCount = type.lens.filter(lens => lens.checked).length;
      return typeTotal + iolCount;
    }, 0);
    return grandTotal + totalForManufa;
  }, 0);
}

changeVisibleStatus(manufacturer: IOLManufacturer, type: lensType, lens: lensModel){
  const lensModel = this.getLensModel(this.oIols, manufacturer, type, lens);
  if(lensModel){
    lensModel.isVisible = !this.visible;
  }
}

getLensModel(data: IOLs, manufacturer: IOLManufacturer, type: lensType, lens: lensModel){
  if(manufacturer){
    const manu = data.manufacturer.find(manu => manu.name === manufacturer.name);  
    if (manu) {
      const selctedType = manu.type.find(ty => ty.name === type.name);
      if (selctedType) {
        const lensModel = selctedType.lens.find(len => len.name === lens.name);
        return lensModel
      }
    }
  }
  return undefined;
}
  changeCheckedStatus(manufacturer: IOLManufacturer, type: lensType, lens: lensModel){
    const lensModel = this.getLensModel(this.iols, manufacturer, type, lens);
    if(lensModel){
      lensModel.checked = true;
    }
  }
  mergeSelectedItem(){
  
    if(this.selectedItem?.manufacturer.length > 0 ){
      this.selectedItem.manufacturer.forEach(manufacturer => {
        manufacturer.type.forEach(type => {
          type.lens.forEach(lens => {
            if(lens.checked){
              this.changeCheckedStatus(manufacturer, type, lens);
            }
          });
          if(type.lens.filter((lens: lensModel)=> lens.checked).length > 0){
            this.toUpdateChildrenCheckedStatus(manufacturer, type);
          }
        });
    });
    this.iols.manufacturer.forEach(manufacturer => {
      manufacturer.type.forEach(type => {
      this.toUpdateChildrenCheckedStatus(manufacturer, type);
      });
    });
  }
}
  
   

  
}
