<div class="duplicate-dialog-container">
  <div class="dialog-content-header-icon">
    <mat-icon (click)="closePopup()" aria-hidden="false" aria-label="close icon" fontIcon="close">
    </mat-icon>
  </div>
  <div class="alc_warning_icon_and_text">
    <div class="warning-icon">
      <img src="assets/alert-triangle.svg" class="alc_duplicate_iol_warning" alt="warning">
    </div>
    <div class="text-container">
      <p class="alc_duplicate_found_text">Wait, we have found a duplicate!</p>
      <p class="alc_iol_reserved_text">Based on the surgeon preferences, a duplicate of the primary IOL is already being
        reserved.</p>
      <p class="alc_duplicate_iol_confirmation">Are you sure you want to reserve another of the same model and diopter?
      </p>
    </div>
  </div>
  <div class="button-container">
    <button type="button" (click)="closePopup()" class="alc_button alc_button--secondary alc_iol_warning_buttons">
      No, go back and edit
    </button>
    <button mat-button type="submit" (click)="proceedWithDuplicateIol('yes')"
      class="alc_button alc_button--primary alc_iol_warning_buttons">
      Yes, proceed
    </button>
  </div>
</div>