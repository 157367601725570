<section>
    <div class="d-flex alcon-toast-div">
      <div class="alcon-toast-content">
        <div class="alcon-toast-message-section">
          <div class="alcon-toast-icon">
            <img
              src="assets/toast_success.svg"
              *ngIf="data.messageType === 'Success'"
              [alt]="'SUCCESS'"
              class="alcon-success-icon"
            />
            <img
              src="assets/images/error-octagon.svg"
              *ngIf="data.messageType === 'Failure'"
              [alt]="'ERROR_IMG'"
              class="alcon-error-icon"
            />
            <img
              src="assets/alert-triangle.svg"
              *ngIf="data.messageType === 'Warning'"
              [alt]="'Warning Image'"
              class="alcon-error-icon"
            />
          </div>
          <div class="alcon-toast-msg">
            <span class="alcon-toast-message">{{data.message}}</span>
            <a *ngIf="data.linkText" class="link-color" href="javascript: void(0);" (click)="showPatientModal($event, data)">{{data.linkText}}</a>
          </div>
        </div>
        <div class="alcon-toast-action">
          <button
            (click)="onClose()"
            *ngIf="data.autoClose"
            class="m-0 p-0 alcon-clear-button alcon-action-close-toast"
          >
            <img
              src="assets/images/close-banner.svg"
              [alt]="'CLOSE'"
            />
          </button>
        </div>
      </div>
    </div>
  </section>
  