import { Injectable } from '@angular/core';
import { HttpErrorResponse } from "@angular/common/http";
import { AlertDialogComponent } from '../components/alert-dialog/alert-dialog.component';
import { MatDialog } from '@angular/material/dialog';

@Injectable({
  providedIn: 'root'
})
export class HandleErrorService {
  constructor(public dialog: MatDialog) { }

  // Handling HTTP Errors using Toaster
  public handleError(err: HttpErrorResponse) {
    let errorMessage: string = 'Something went wrong!';
    if((err?.error?.statusCode === 409) && (err?.error?.message.includes('uc_surgeon_email'))) {
      errorMessage = 'Surgeon email already exists.';
      return errorMessage;
    }
    //if (err.error instanceof ErrorEvent) {
    if (err.error?.message) {
      if(this.isStackTrace(err.error.message)) {
        errorMessage = this.handleCommonError(err.error.statusCode);
      } else {
         if(err.error.statusCode === 409 && err.error && err.error.data) {
            return err.error;
          }
          else {
            errorMessage = `${err.error.message}`;
          }
      }
     
    } else if(err.error){
      // The backend returned an unsuccessful response code.
      errorMessage = this.handleCommonError(err.error.statusCode);
    }
    else if(err?.status){
      errorMessage = this.handleCommonError(err?.status);
    }
    return errorMessage;
  }
  
  handleCommonError(statusCode: number) {
    let errorMessage: string = '';
    switch (statusCode) {
      case 400:
        errorMessage = "Bad Request.";
        break;
      case 401:
        errorMessage = "You need to log in to do this action.";
        break;
      case 403:
        errorMessage = "You don't have permission to access the requested resource.";
        break;
      case 404:
        errorMessage = "The requested resource does not exist.";
        break;
      case 412:
        errorMessage = "Precondition Failed.";
        break;
      case 500:
        errorMessage = "Internal Server Error.";
        break;
      case 503:
        errorMessage = "The requested service is not available.";
        break;
      case 422:
        errorMessage = "Validation Error!";
        break;
      case 409:
        errorMessage = "Conflict Error!";
        break;
      default:
        errorMessage = "Something went wrong!";
    }
    return errorMessage;
  }

  isStackTrace(error: string) {
    const stackTracePatterns = [
      'Exception',
      'java',
      'class'
    ];
    console.log(stackTracePatterns.some(pattern => error.includes(pattern)));
    return stackTracePatterns.some(pattern => error.includes(pattern));
  }

  openAlertDialog(msg: any) {
    const dialogRef = this.dialog.open(AlertDialogComponent, {
      data: {
        message: msg,
        buttonText: {
          cancel: 'Ok',
        },
      },
    });
  }
}
