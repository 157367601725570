import { Component, Inject, OnInit  } from '@angular/core';
import { Router } from '@angular/router';
import { OKTA_AUTH } from '@okta/okta-angular';
import { OktaAuth } from '@okta/okta-auth-js';
import { AuthService } from '../../shared/auth.service';


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent{

  /*
  isAuthenticated = false
  user: any;
  group: string = '';

  constructor(public router: Router, @Inject(OKTA_AUTH) private oktaAuth: OktaAuth, public authService: AuthService) {}

  async ngOnInit()
  {
    
    const user = await this.oktaAuth.getUser();
    this.user = JSON.stringify(user, null, 4);
  }
  */


}
 