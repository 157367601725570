import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { SectionLoaderModule } from 'src/app/components/section-loader/section-loader.module';
import { DocumentService } from '../../service/document/document.service';

@Component({
  selector: 'alcon-inline-pdf-viewer',
  standalone: true,
  imports: [
    CommonModule,
    PdfViewerModule,
    SectionLoaderModule
  ],
  templateUrl: './alcon-inline-pdf-viewer.component.html',
  styleUrl: './alcon-inline-pdf-viewer.component.scss'
})
export class AlconInlinePdfViewerComponent implements OnInit {
  @Input() src!: string;
  public errorMessage = "Unable to load the file !!!";
  public isDocumentLoading = true;
  public isDocumentLoadFailed = false;
  public documentUint8Array!: Uint8Array;

  constructor(
    private documentService: DocumentService
  ){

  }

  ngOnInit(){
    this.getDocumentBlob()
  }

  public showError(){
    this.isDocumentLoadFailed = true;
    this.isDocumentLoading = false;
  }

  public displayDocument(){
    this.isDocumentLoading = false;
  }

  private getDocumentBlob(){
    this.documentService.fetchDocument(this.src).subscribe({
      next: async (res: Blob)=>{
        this.documentUint8Array = new Uint8Array(await res.arrayBuffer());
      },
      error:  (err)=>this.showError()
    })
  }
}
