import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, catchError, map } from 'rxjs';
import { HistoryLogResponse } from '../Model/alcon-history-log-model';
import { environment } from 'src/environments/environment';
import { ORExperienceKeys } from 'src/app/pages/or-experience/constants/alcon-or-experience.constants';
import { AlconOrExperienceService } from 'src/app/pages/or-experience/services/alcon-or-experience.service';


@Injectable({
  providedIn: 'root',
})
export class HistoryLogService {
  
  private mccApiUrl = environment.apiUrl + '/' + environment.ccApiRoute;
  httpOptions = { 
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };

  constructor(private httpClient: HttpClient, public oreService: AlconOrExperienceService) {}
  
  getPatientHistoryLog( practice_org: any, patient_id: any): Observable<HistoryLogResponse> {
    return this.httpClient
      .get<HistoryLogResponse>(this.mccApiUrl +`/${practice_org}/patient/${patient_id}/history/logs`, this.httpOptions)
  }

  getSurgerytHistoryLog( practice_org: any, patient_id: any, surgery_id: any): Observable<HistoryLogResponse> {
    return this.httpClient
      .get<HistoryLogResponse>(this.mccApiUrl +`/${practice_org}/patient/${patient_id}/surgery/${surgery_id}/history/logs`, this.httpOptions).pipe(
        map(response => this.updateLogDeltaFields(response))
      );
  }

  updateLogDeltaFields(response: HistoryLogResponse): HistoryLogResponse {
    response?.data?.forEach(historyLog => {
      historyLog.logDelta?.forEach(logDelta => {
        if (ORExperienceKeys.includes(logDelta.field)) {
          if(logDelta.originalValue) {
            logDelta.originalValue = this.oreService.formatToHHMMAmPm(false, logDelta.originalValue);
          }
          if(logDelta.updatedValue) {
            logDelta.updatedValue = this.oreService.formatToHHMMAmPm(false, logDelta.updatedValue);
          }
        }
      });
    });
    return response;
  }

}
