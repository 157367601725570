export const userRoles = {
    superAdmin: 'superadmin',
    clinicAdmin: 'clinicadmin',
    clinicStaff: 'clinicstaff',
    ascAdmin: 'ascadmin',
    ascStaff: 'ascstaff'
}

export enum accessType {
  user = 'user',
  userManage = 'usermanager'
}

export enum pagePermission{
    patient = 'patient',
    surgeries = 'surgeries',
    asc = 'asc',
    clinics = 'clinics',
    forms ='forms',
    surgeon = 'surgeon',
    iolModel = 'iolModel'
}

export interface pageAccess{
    access: boolean,
    manageMode: boolean
}

export enum permissionSet{
    patientsManagemen = 'surgeryMgmt'
}
export enum permissionSetValue{
    manage = 'manage',
    view = 'view'
}

export interface associatedAccounts{
    accessType: string,
    practiceOrg: string,
    sapId: string,
    name: string,
    type: string
    permissions: {
        CC: {
            active: boolean,
            roleName?: string,
            permissionSet: any
        }
    }
}

export interface entitlements{
        firstName: string,
        associatedAccounts: associatedAccounts[]
}

export const oktaConst = {
    tokenStorage: 'cc_okta-token-storage'
}

export const loginConfigs = {
    loginUrl: 'cc_loginUrl',
    loginQuery: 'cc_loginQuery',
    isUserLoggedIn: 'cc_is_user_login'
}

export const adiStorageKey = {
    lang: 'adi-selected-locale',
    SELECTED_ACCOUNT_ID: 'adi-selected-account-id',
    ADI_TRACE_ID: 'adi-trace-id'
}

export const previousSessionConfigs = {
    lastVisitedPath : 'cc_lastVisitedPath'
}

export const clinicConnectConfig = {
    cc_current_account : 'cc_current_account',
    cc_changed_sapId : 'cc_changed_sapId'
}

export const userRoleCode = {
    superadmin: 'SUPER_ADMIN',
    clinicddmin: 'CLINIC_ADMIN',
    clinicstaff: 'CLINIC_STAFF',
    ascadmin: 'ASC_ADMIN',
    ascstaff: 'ASC_STAFF'
}

export const facility  = {
    asc : 'ASC',
    clinic: 'CLINIC'
}

export const userStatus = {
    active: 'ACTIVE',
    inactive: 'INACTIVE',
    unassigned: 'UNASSIGNED',
    pending: 'PENDING'
}

export const selectedORETheme = 'cc_user_ore_theme';

export enum OREThemes  {
    light = 'LIGHT',
    dark = 'DARK'
}

export const suppliesDataList = [
    { name: 'Malyugin Ring', key: 'malyuginRings' },
    { name: 'Trypan Blue', key: 'trypanBlue' },
    { name: 'Hydrus', key: 'hydrus' },
    { name: 'iStent', key: 'istent' },
    { name: 'Omni', key: 'omni' },
    { name: 'KDB', key: 'kdb' },
    { name: 'GATT', key: 'gatt' },
    { name: 'CTR', key: 'ctr' },
    { name: 'Iris Hooks', key: 'irisHooks' },
    { name: 'Other', key: 'other' }
  ];

 export const IOLS_COLUMNS_SCHEMA = [
    {
      key: 'manufacturer',
      type: 'text',
      label: 'Manufacturer',
    },
    {
        key:'lensType',
        type: 'text',
        label: 'Type'
    },
    {
        key:'model',
        type: 'text',
        label: 'IOL Model'
    },
    {
        key:'diopter',
        type: 'text',
        label: 'Diopter'
    },
    {
        key:'lensPreference',
        type: 'text',
        label: 'Preference'
    }
  ];

export const ellipsisMenuItem = {
    editRoom: 'editRoom',
    deleteRoom: 'deleteRoom'
}

export const roomHeaders = [
    {
      key: 'name',
      type: 'text',
      label: 'Room',
      flex: 35
    },
    {
      key: 'equipment',
      type: 'text',
      label: 'Equipment',
      flex: 35,
      eType: ['ora', 'femto']
    },
    {
      key: 'status',
      type: 'text',
      label: 'Status',
      flex: 20,
      toggleSwitch: true
    }
  ];

export const surgeryFilters = {
    headerFilters: 'cc_filters',
    otherFilters: 'cc_otherFilters'
}

export const iolModelSaved = 'cc_iol_model_saved';
