<div class="alcon-banner-container" [ngClass]="bannerType">
  <div class="alcon-banner-icon-section" *ngIf="bannerIcon">
    <mat-icon [svgIcon]="bannerIcon" class="alcon-banner-icon"></mat-icon>
  </div>
  <div class="alcon-banner-content-section">
    <div class="alcon-banner-content-text">{{ bannerText }}</div>
    <button class="alcon-banner-link-text" (click)="onLinkClick()" *ngIf="bannerLinkText">{{ bannerLinkText }}</button>
  </div>
  <div class="alcon-banner-dismiss cursor-pointer d-flex align-items-center" *ngIf="showCloseButton" (click)="dismissBanner()">
    <button class="alc_button ">
      <mat-icon svgIcon="close"></mat-icon>
    </button>    
  </div>
</div>
