import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IdleService } from 'src/app/shared/service/idle.service';
import { customDialog } from '../dialog.model';

export enum dialogAction{
  primary,
  secondary,
  close
}
@Component({
  selector: 'app-custom-dialog',
  templateUrl: './custom-dialog.component.html',
  styleUrls: ['./custom-dialog.component.scss']
})
export class CustomDialogComponent {
  text = '';
  dialogAction = dialogAction;
  constructor(
    public dialogRef: MatDialogRef<CustomDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: customDialog,
  ){
    this.text = data.text;
  }

  closePopup(event: dialogAction) {
    this.dialogRef.close(event);
  }

  updateText(text: string){
    this.text = text;
  }

}
