import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { alconPdfDocHttpOptions } from '../../constants/constants';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class DocumentService {
  private mccApiUrl = environment.apiUrl + '/' + environment.ccApiRoute;
  constructor(
    private httpClient: HttpClient
  ) { }

  fetchDocument(src: string): Observable<any> {
    const url = this.mccApiUrl + '/fetchFile';
    return this.httpClient.post<any>(url, JSON.stringify({"fileUrl":src}), alconPdfDocHttpOptions)
  }

}
