import { Component, Input } from '@angular/core';
import { EditHistoryEntry } from '../../models/alcon-or-experience.model';
import { MatIconModule } from '@angular/material/icon';
import { AlconOrExperienceService } from '../../services/alcon-or-experience.service';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-alcon-ore-history-item',
  standalone: true,
  imports: [MatIconModule, CommonModule],
  templateUrl: './alcon-ore-history-item.component.html',
  styleUrl: './alcon-ore-history-item.component.scss'
})
export class AlconOreHistoryItemComponent {
  constructor(public oreService: AlconOrExperienceService) {}
  @Input() history: EditHistoryEntry | undefined = undefined;
}
