import { OrDayAction, SubMenuField } from '../models/alcon-or-experience.model';

export const SubMenuFieldsORE: SubMenuField[] = [
  {
    label: 'DOB',
    field: 'dob',
  },
  {
    label: 'Patient ID',
    field: 'patientMrn',
  },
  {
    label: 'Gender',
    field: 'gender',
  },
  {
    label: 'Diabetic',
    field: 'diabetic',
    showBooleanIcon: true,
  },
  {
    label: 'Allergies',
    field: 'allergies',
  },
];

export const SummaryDetailsORE: SubMenuField[] = [
  {
    label: 'Eye',
    field: 'eyeLabel',
    isRequired: true,
  },
  {
    label: 'Lens',
    field: 'lens',
    isRequired: true,
  },
  {
    label: 'Set For',
    field: 'setFor',
    isRequired: true,
  },
  {
    label: 'ORA',
    field: 'ora',
    twoColumnLayout: true,
    showBooleanIcon: true,
  },
  {
    label: 'Femto',
    field: 'femto',
    twoColumnLayout: true,
    showBooleanIcon: true,
  },
  {
    label: 'Meds',
    field: 'meds',
    isRequired: true,
  },
];

export enum InORExperienceKeys {
  EnteredORDttm = "enteredORDttm",
  TimeOutDttm = "timeOutDttm",
  SurgeryStartDttm = "surgeryStartDttm",
  SurgeryEndDttm = "surgeryEndDttm",
  ExitedORDttm = "exitedORDttm"
}

export const ORExperienceKeys = ['enteredORDttm', 'timeOutDttm', 'surgeryStartDttm', 'surgeryEndDttm', 'exitedORDttm'];

export const orDayActions: OrDayAction[] = [
  {
    label: 'Entered OR',
    field: InORExperienceKeys.EnteredORDttm,
    recordedTime: '',
    isActive: false
  },
  {
    label: 'Time out',
    field: InORExperienceKeys.TimeOutDttm,
    recordedTime: '',
    isActive: false
  },
  {
    label: 'Surgery started',
    field: InORExperienceKeys.SurgeryStartDttm,
    recordedTime: '',
    isActive: false
  },
  {
    label: 'Surgery ended',
    field: InORExperienceKeys.SurgeryEndDttm,
    recordedTime: '',
    isActive: false
  },
  {
    label: 'Exited the OR',
    field: InORExperienceKeys.ExitedORDttm,
    recordedTime: '',
    isLastElement: true,
    isActive: false
  },
];
