import { Component, DestroyRef, Inject, Input, OnInit } from '@angular/core';
import { AuthService } from '../../shared/auth.service';
import { OktaAuth } from '@okta/okta-auth-js';
import { OktaAuthStateService, OKTA_AUTH } from '@okta/okta-angular';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { SessionStorageService } from 'src/app/shared/session-storage.service';
import { MatDialog } from '@angular/material/dialog';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { facility } from 'src/app/shared/model/account.model';
import { SubHeaderComponent } from './sub-header/sub-header.component';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { CommonModule } from '@angular/common';
import {AdiHeaderModule} from '@alcon/adi-header-lib';
import { AlconAccountSelectorModule } from '@alcon/alcon-account-selector';
import { environment } from 'src/environments/environment';
import { NavigationService } from 'src/app/shared/service/navigation.service';
import { LoaderService } from 'src/app/shared/service/loader.service';
import { IdleService } from 'src/app/shared/service/idle.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-header',
  standalone: true,
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  imports: [SubHeaderComponent, MatMenuModule, MatIconModule, CommonModule, FormsModule, ReactiveFormsModule, RouterModule, AdiHeaderModule, AlconAccountSelectorModule],
})
export class HeaderComponent {
  @Input() facilitiesSource: facility[] = [];
  @Input() AccountFacilityID: string = '';
  showFlyout: boolean = false;
  facilitiesVisible = false;
  currentFacility:facility={};
  accountForm = new FormGroup({
    account: new FormControl('')
  });
  headerEndPoint = environment.headerApiEndPoint;
  headerSource = environment.headerSource;
  isAccountLoaded = false;
  constructor(
    public authService: AuthService,
    @Inject(OKTA_AUTH) public oktaAuth: OktaAuth,
    public authStateService: OktaAuthStateService,
    private sessionStorageService: SessionStorageService,
    public dialog: MatDialog,
    public router: Router,
    public navigationService: NavigationService,
    private loaderService: LoaderService,
    private idleService: IdleService,
    private destroyRef: DestroyRef
    ) {

      this.authService.entitlementValue$.pipe(
        takeUntilDestroyed(this.destroyRef)
      ).subscribe((event: boolean)=>{
        if(event)
          this.isAccountLoaded = true;
      })
    }

  ngOnChanges(){
    if(this.AccountFacilityID && this.facilitiesSource?.length){
      this.setCurrentFacility(this.AccountFacilityID);
    }
  }

  async logout()
  {
      this.authService.logout();
  }

  hideMenu() {
    this.showFlyout = false;
  }

  private setCurrentFacility(accountFacilityID: string){
    this.currentFacility = this.facilitiesSource.find((facility: any)=>facility.practiceOrg === accountFacilityID) as facility;
  }

  logoutLoader($event: any){
    if($event){
      this.idleService.stopIdle();
      this.loaderService.showLoader();
      this.sessionStorageService.clear();
    }
    else
      this.loaderService.hideLoader();
  }

}
