<div class="alcon-pvm-container">
    <div class="alcon-pvm-header">
        <div class="alcon-pvm-header-close">
            <button class="alc_button" (click)="close()">
                <mat-icon svgIcon="close" class="d-flex"></mat-icon>
            </button>
        </div>
        <div class="alcon-pvm-header-title name-truncate-in-three-lines">
            {{ data.name | hyphenate}}
        </div>        
    </div>
    <div class="alcon-pvm-body alcon-pdf-viewer">
        <alcon-inline-pdf-viewer [src]="data.src" ></alcon-inline-pdf-viewer>
    </div>
    <div class="alcon-pvm-footer flex-column flex-md-row">
        <button type="button" class="alc_button alc_button--secondary" (click)="close()">Close</button>
        <button type="button" class="alc_button alc_button--primary" (click)="downloadDoc()">Print</button>
    </div>
</div>