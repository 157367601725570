<div class="alc_page-header">
    <div class="container">
        <div class="alc_page-header-title">
            <h1>{{pageTitle}}</h1>
        </div>
        <div *ngIf="showAddNewTitle()" class="alc_page-header-cta fixed-sm-bottom">
            <button type="button" (click)="addForm()" class="alc_button alc_button--primary alc_button--icon alc_button--block alc_add_form"><span class="icon-ico-plus"></span> {{add_new_title}}</button>
        </div>
    </div>
   
</div>
