<div class="container filter-wrapper">
    <form [formGroup]="preferenceFilterForm">
        <div class="row filter-innerwrap">
          <div class="col-md-2 col-lg-2 manufacturer-search-container">
            <div class="alc_filter-input">
              <div class="alc_input-wrapper alc_input-wrapper--search">
                <input
                  type="text"
                  class="alc_input-text alc_input-text--search"
                  placeholder="Search by manufacturer"
                  formControlName="search_manufacturer"
                />
                <mat-icon class="search-close-icon" *ngIf="preferenceFilterForm.controls['search_manufacturer'].value"
                  aria-hidden="false" aria-label="close icon" fontIcon="close" (click)="resetSearch('search_manufacturer')"></mat-icon>
              </div>
            </div>
          </div>
          <div class="col-md-2 col-lg-2 p-0 model-types-container">
            <app-alcon-searchable-multiselect-dropdown [allOptions]="uniqueTypeNamesObj" (selectedOptions)="changedSelectedID($event)"></app-alcon-searchable-multiselect-dropdown>
          </div>
          <div class="col-md-2 col-lg-2 model-search-container">
            <div class="alc_filter-input">
              <div class="alc_input-wrapper alc_input-wrapper--search">
                <input
                  type="text"
                  class="alc_input-text alc_input-text--search"
                  placeholder="Search by model"
                  formControlName="search_model"
                />
                <mat-icon class="search-close-icon" *ngIf="preferenceFilterForm.controls['search_model'].value"
                  aria-hidden="false" aria-label="close icon" fontIcon="close" (click)="resetSearch('search_model')"></mat-icon>
              </div>
            </div>
          </div>
          <div class="col-md-2 col-lg-2"></div>
          <div class="col-md-2 col-lg-2 d-flex discard-changes-wrap align-items-center">
            <button mat-button type="button" [disabled]="!anyModelSelected" class="alc_input-button alc_input-button--text alc_discard_changes" (click)="saveChanges(false)">Discard changes</button>
          </div>
          <div class="col-md-2 col-lg-2 d-flex justify-content-center align-items-center">
            <button mat-button type="submit" [disabled]="!anyModelSelected" class="alc_button alc_button--primary alc_save_changes_button" (click)="saveChanges(true)">Save changes</button>
          </div>
        </div>
    </form>
</div>