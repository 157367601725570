<div class="alcon-pdf-container">
    <div class="alcon-pdf-container-spacer">
        <pdf-viewer 
            *ngIf="documentUint8Array"
            [src]="documentUint8Array"
            [fit-to-page]='true'
            (error)="showError()"
            (after-load-complete)="displayDocument()"
        ></pdf-viewer>
    </div>
    <div class="error-flex" *ngIf="isDocumentLoadFailed">
        <span>
            {{errorMessage}}
        </span>
    </div>
    <div class="loader-flex"  *ngIf="isDocumentLoading">
        <alc-section-loader [isLocal]="true"></alc-section-loader>
    </div>
</div>

