import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatSlideToggleChange, MatSlideToggleModule } from '@angular/material/slide-toggle';
import { AlconOrExperienceService } from '../../services/alcon-or-experience.service';
import { CommonModule } from '@angular/common';
import { AlconToggleSwitchComponent } from "../../../../shared/components/alcon-toggle-switch/alcon-toggle-switch.component";
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'app-alcon-or-header',
  standalone: true,
  imports: [MatSlideToggleModule, CommonModule, AlconToggleSwitchComponent, MatIconModule],
  templateUrl: './alcon-or-header.component.html',
  styleUrl: './alcon-or-header.component.scss'
})
export class AlconOrHeaderComponent {
  @Input() accountName: string = '';
  @Output() onModeChange =  new EventEmitter<boolean>();
  @Output() onExitOR =  new EventEmitter<boolean>();

  constructor(public oreService: AlconOrExperienceService) {
  }

  onModeToggle(state: boolean){
    this.onModeChange.emit(state);
  }

  exitORClicked(){
    this.onExitOR.emit(true);
  }
}
