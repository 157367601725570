import { Component, Inject } from '@angular/core';
import { OktaAuth } from '@okta/okta-auth-js';
import { OktaAuthStateService, OKTA_AUTH } from '@okta/okta-angular';


@Component({
  selector: 'app-auth-button',
  templateUrl: './auth-button.component.html',
  styleUrls: ['./auth-button.component.scss']
})

export class AuthButtonComponent {

  constructor(
    @Inject(OKTA_AUTH) public oktaAuth: OktaAuth, 
    public authStateService: OktaAuthStateService
  ) {}

  async logout() {
    await this.oktaAuth.signOut();
  }

}
