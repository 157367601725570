<page-header [pageTitle]="'Admin portal'"></page-header>
<tab-navigation [menuItems]="menus[1]['admins']"></tab-navigation>
<div class="alc_form alc_form--create">
    <form [formGroup]="form" (ngSubmit)="submit()">
        <div class="alc_form-wrapper alc_form-wrapper">
            <div class="alc_form-wrapper-column">
                <div class="alc_form-content">
                    <div class="alc_inputs">

                        <div class="alc_input">
                            <label class="alc_input-label">Clinic <span *ngIf="f['practiceOrg'].validator">*</span></label>
                            <div class="alc_input-wrapper">
                                <div class="alc_input-dropdown">
                                <select
                                    formControlName="practiceOrg"
                                    class="alc_input-dropdown-select"
                                    (ngModelChange)="onChangeFacilityID($event)"
                                >
                                    <option value="">Select Clinic</option>
                                    <option
                                    *ngFor="let clinic of clinic_listing"
                                    value="{{ clinic.practiceOrg }}"
                                    >
                                    {{ clinic.name }}
                                    </option>
                                </select>
                                </div>
                                <div
                                *ngIf="submitted && f['practiceOrg'].errors"
                                class="alc_input-error"
                                >
                                <div *ngIf="f['practiceOrg'].errors['required']">
                                    Clinic is required
                                </div>
                                </div>
                            </div>
                        </div>

                        <div class="alc_input">
                            <label class="alc_input-label">Surgery Type <span *ngIf="f['surgeryType'].validator">*</span></label>
                            <div class="alc_input-wrapper">
                              <div class="alc_input-dropdown">
                                <select
                                  class="alc_input-dropdown-select"
                                  formControlName="surgeryType"
                                  (ngModelChange)="onChangeSuregeryType($event)"
                                >
                                  <option value="">Select Surgery Type</option>
                                  <option *ngFor="let st of surgeryTypes" [value]="st.name">{{ st.name }}</option>
                                </select>
                              </div>
                              <div
                                *ngIf="
                                submitted && f['surgeryType'].errors
                                "
                                class="alc_input-error"
                              >
                                <div *ngIf="f['surgeryType'].errors['required']">
                                    Surgery Type is required
                                </div>
                              </div>
                            </div>
                          </div>
                          
                        <div class="alc_input">
                            <div class="NgxEditor__Wrapper">
                                <ngx-editor-menu [editor]="editor" [toolbar]="toolbar"> </ngx-editor-menu>
                                <ngx-editor [editor]="editor" formControlName="description" [placeholder]="'Type here...'" style="height: 300px" ></ngx-editor>
                            </div>
                            <div *ngIf="submitted && f['description'].errors" class="alc_input-error">
                                <div *ngIf="f['description'].errors['required']">
                                    Description is required
                                </div>
                            </div>
                            <div *ngIf="f['description'].errors" class="alc_input-error">
                                <div *ngIf="f['description'].errors['forbiddenCodeSnippet']">
                                    Code is not allowed in Comments
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div> 
        </div>
        <div class="alc_form-actions alc_form_save_bottom">
            <button type="submit" class="alc_button alc_button--primary" [disabled]="isDisabled || !form.valid">{{ isDisabledText }}</button>
        </div>
    </form>
</div>