import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Clinic } from "../model/clinic";
import {  Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { SharedService } from './service/shared.service';

@Injectable({
  providedIn: 'root'
})
export class ClinicService {

  private mccapiURL = environment.apiUrl + "/" + environment.ccApiRoute;


  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  }

  public httpOptionsWithResponse = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
    observe: 'response' as 'response'
  };

  constructor(private httpClient: HttpClient,
    private sharedService: SharedService
  ) { }

  getAll(searchTerm: string, searchValue : string, page : number, limit : number, orderby : string, sortType: string): Observable<Clinic[]> {
    const url = this.mccapiURL + '/facilities?searchTerm='+searchTerm+'&searchValue='+searchValue+'&pageNumber='+page+'&limit='+limit+'&sortBy='+orderby+'&sortType='+sortType;
    return this.httpClient.get<HttpResponse<any>>(url, this.httpOptionsWithResponse).pipe(
      map(resp => this.sharedService.extractData<any>(resp, url, this.httpOptionsWithResponse))
    )
  }

  create(obj: Clinic): Observable<Clinic>{
    const url = this.mccapiURL + '/facility';
    return this.httpClient.post<HttpResponse<any>>(url, JSON.stringify(obj), this.httpOptionsWithResponse).pipe(
      map(resp => this.sharedService.extractData<any>(resp, url, this.httpOptionsWithResponse))
    )
  }

  details(id: any): Observable<Clinic> {
    const url = this.mccapiURL + '/facility/' + id;
    return this.httpClient
      .get<HttpResponse<any>>(url, this.httpOptionsWithResponse).pipe(
        map(resp => this.sharedService.extractData<any>(resp, url, this.httpOptionsWithResponse))
      )
    }

  update(id: number, obj: any): Observable<Clinic> {
    const url = this.mccapiURL + '/facility/' + id;
    return this.httpClient.patch<HttpResponse<any>>(url, JSON.stringify(obj), this.httpOptionsWithResponse).pipe(
      map(resp => this.sharedService.extractData<any>(resp, url, this.httpOptionsWithResponse))
    )
  }

  delete(id: number)
  {
    return this.httpClient.delete<Clinic>(this.mccapiURL + '/facility/' + id, this.httpOptions)
  }

  manipulateClinicObj(formVal: any) {
    const obj: Clinic = {
      name: formVal.name,
      addressLine1: formVal.address1,
      addressLine2: formVal.address2,
      countryCode: formVal.country_id,
      countryName: 'United States',
      stateCode: formVal.state_id,
      city: formVal.city,
      zipcode: formVal.zipcode,
      phoneNumber: formVal.phone,
      email: formVal.email,
      salesOrg: formVal.sales_org,
      sapId: formVal.sap_id,
      status: formVal.status,
      associatedFacilities: formVal.asc_ids && formVal.asc_ids.length ? formVal.asc_ids : null,
      type:formVal.type
    }
    return obj;
  }

}
