import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NewSurgeon, Surgeon } from '../model/surgeon';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { AuthService } from './auth.service';
import { SharedService } from './service/shared.service';

@Injectable({
  providedIn: 'root',
})
export class SurgeonService {
  private mccapiURL = environment.apiUrl + "/" + environment.ccApiRoute;

  private surgeonPreferenceCollection:any = {};

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };

  
  public httpOptionsWithResponse = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
    observe: 'response' as 'response'
  };

  constructor(
    private httpClient: HttpClient,
    public authService: AuthService,
    public sharedService: SharedService
  ) {}

  getAll(
    facility_id: string,
    searchby: string,
    page: number,
    limit: number,
    orderby: string,
    sortType: string
  ): Observable<Surgeon[]> {
    const url =  this.mccapiURL + '/surgeons?practiceOrg=' + facility_id + '&searchTerm=' + searchby + '&pageNumber=' + page + '&limit=' + limit + '&sortBy=' + orderby + '&sortType=' + sortType;
    return this.httpClient
      .get<HttpResponse<any>>(
        url,
        this.httpOptionsWithResponse
      ).pipe(
        map(resp => this.sharedService.extractData<any>(resp, url, this.httpOptionsWithResponse))
      )
  }

  getSurgeonByPracticeOrg(
    facility_id: string,
  ): Observable<Surgeon[]> {
    const url =  this.mccapiURL + '/surgeons?practiceOrg=' + facility_id;
    return this.httpClient
      .get<HttpResponse<any>>(
        url,
        this.httpOptionsWithResponse
      ).pipe(
        map(resp => this.sharedService.extractData<any>(resp, url, this.httpOptionsWithResponse))
      )
  }

  search(obj: Surgeon): Observable<HttpResponse<any>> {
    const url = this.mccapiURL + '/surgeon?email=' + obj.email;
    return this.httpClient.get<HttpResponse<any>>(url, this.httpOptionsWithResponse).pipe(
      map(resp => this.sharedService.extractData<any>(resp, url, this.httpOptionsWithResponse))
    )
  }

  create(obj: NewSurgeon): Observable<Surgeon> {
    const url = this.mccapiURL + '/surgeon';
    return this.httpClient.post<HttpResponse<any>>(url, JSON.stringify(obj),this.httpOptionsWithResponse).pipe(
      map(resp => this.sharedService.extractData<any>(resp, url, this.httpOptionsWithResponse))
    )
  }

  details(id: any): Observable<Surgeon> {
    const url = this.mccapiURL + '/surgeon/' + id;
    return this.httpClient
      .get<HttpResponse<any>>(url, this.httpOptionsWithResponse).pipe(
        map(resp => {
          const surgeonPreference = this.sharedService.extractData<any>(resp, url, this.httpOptionsWithResponse);
          this.surgeonPreferenceCollection[id] = surgeonPreference;
          return surgeonPreference;
        }))
  }

  getSurgeonDetails(id: any): Observable<Surgeon>{
    if(this.surgeonPreferenceCollection[id]){
      return of(this.surgeonPreferenceCollection[id]);
     }
 
     const url = this.mccapiURL + '/surgeon/' + id;
     return this.httpClient
       .get<HttpResponse<any>>(url, this.httpOptionsWithResponse).pipe(
         map(resp => {
           const surgeonPreference = this.sharedService.extractData<any>(resp, url, this.httpOptionsWithResponse);
           this.surgeonPreferenceCollection[id] = surgeonPreference;
           return surgeonPreference;
         }))
  }

  clearSurgeonPreferenceCollection(){
    this.surgeonPreferenceCollection = {};
  }

  update(id: number, obj: any): Observable<Surgeon> {
    const url = this.mccapiURL + '/surgeon/' + id;
    return this.httpClient.patch<HttpResponse<any>>(url, JSON.stringify(obj),this.httpOptionsWithResponse).pipe(
      map(resp => this.sharedService.extractData<any>(resp, url, this.httpOptionsWithResponse))
    )
  }

  delete(id: number) {
    return this.httpClient
      .delete<Surgeon>(this.mccapiURL + '/surgeon/' + id, this.httpOptions)
  }

  manipulateObjKeys(formVal: any) {
    const obj: NewSurgeon = {
      prefix: formVal.prefix,
      firstName: formVal.first_name,
      middleName: formVal.middle_name,
      lastName: formVal.last_name,
      countryCd: formVal.country_id,
      countryName: 'United States',
      licenseStateCd: formVal.license_state_id,
      licenseNumber: formVal.license_number,
      licenseStateName: formVal.license_state_id,
      govId: formVal.gov_id,
      phone: formVal.phone ? formVal.phone : '',
      email: formVal.email,
      preferredClinicFacility: { practiceOrg: formVal.preferred_clinic_id },
      preferredAscFacility: { practiceOrg: formVal.preferred_asc_id },
      otherClinicFacility: formVal.other_clinic_ids && formVal.other_clinic_ids.length ? formVal.other_clinic_ids : null,
      otherAscFacility: formVal.other_asc_ids && formVal.other_asc_ids.length ? formVal.other_asc_ids : null,
      preference: {
        standardSurgeryTime: formVal.standard_surgery_time,
        complexSurgeryTime: formVal.complex_surgery_time,
        additionalLenxTime: formVal.additional_lenx_time,
        additionalOraTime: formVal.additional_ora_time,
        additionalGeneralAnesthesiaTime: formVal.additional_general_anesthesia_time,
        ora: formVal.ora,
        duplicateOne: formVal.one_duplicate,
        duplicateTwo: formVal.two_duplicates,
        additionalLensesPointFiveD: formVal.point_five_d,
        additionalLensesPointFiveDAndOneD: formVal.one_d,
        additionalOraPointFiveD: formVal.point_five_d_ora,
        additionalOraPointFiveAndOneD: formVal.one_d_ora,
        additionalOraToricOneT: formVal.one_t_power,
        additionalOraToricOneAndTwoT: formVal.two_t_power
      }
    }
    
  return obj;
}

}
