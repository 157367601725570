import { Component } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-account-unassigned',
  templateUrl: './account-unassigned.component.html',
  styleUrls: ['./account-unassigned.component.scss']
})
export class AccountUnassignedComponent {

  contactNo =  environment.customerServiceNo;
  customerServiceMail = environment.customerServiceMailId;
  
  gotoAdiHome() {
    window.location.href = window.location.origin; 
  } 

}
