import { Component, OnInit, Input } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'alc-section-loader',
  templateUrl: './section-loader.component.html',
  styleUrls: ['./section-loader.component.scss']
})
export class SectionLoaderComponent implements OnInit {

  @Input() isLocal!: boolean;
  
  safeSvgContent: SafeHtml = '';
  constructor(private sanitizer: DomSanitizer) {
    const svgContent: string = `<svg width="42" height="45" viewBox="0 0 42 45" fill="none" xmlns="http://www.w3.org/2000/svg">
  <mask id="mask0_4943_21224" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="17" y="0" width="10" height="11">
  <path fill-rule="evenodd" clip-rule="evenodd" d="M17.3999 0.5H26.9999V10.0135H17.3999V0.5Z" fill="white"/>
  </mask>
  <g mask="url(#mask0_4943_21224)">
  <path fill-rule="evenodd" clip-rule="evenodd" d="M22.1999 10.0135C24.851 10.0135 26.9999 7.88397 26.9999 5.25675C26.9999 2.62953 24.851 0.5 22.1999 0.5C19.5492 0.5 17.3999 2.62953 17.3999 5.25675C17.3999 7.88397 19.5492 10.0135 22.1999 10.0135Z" fill="#003595"/>
  </g>
  <mask id="mask1_4943_21224" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="19" y="39" width="6" height="6">
  <path fill-rule="evenodd" clip-rule="evenodd" d="M19.7998 39.7422H24.5998V44.4989H19.7998V39.7422Z" fill="white"/>
  </mask>
  <g mask="url(#mask1_4943_21224)">
  <path fill-rule="evenodd" clip-rule="evenodd" d="M22.1998 39.7422C20.8745 39.7422 19.7998 40.8067 19.7998 42.1206C19.7998 43.434 20.8745 44.4989 22.1998 44.4989C23.5251 44.4989 24.5998 43.434 24.5998 42.1206C24.5998 40.8067 23.5251 39.7422 22.1998 39.7422Z" fill="#99AED5"/>
  </g>
  <mask id="mask2_4943_21224" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="20" width="8" height="8">
  <path fill-rule="evenodd" clip-rule="evenodd" d="M0 20.1211H7.2V27.2562H0V20.1211Z" fill="white"/>
  </mask>
  <g mask="url(#mask2_4943_21224)">
  <path fill-rule="evenodd" clip-rule="evenodd" d="M3.6 27.2562C5.58844 27.2562 7.2 25.6592 7.2 23.6887C7.2 21.7181 5.58844 20.1211 3.6 20.1211C1.612 20.1211 0 21.7181 0 23.6887C0 25.6592 1.612 27.2562 3.6 27.2562Z" fill="#6686BF"/>
  </g>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M40.8001 24.8784C41.4628 24.8784 42.0001 24.3459 42.0001 23.6892C42.0001 23.0325 41.4628 22.5 40.8001 22.5C40.1374 22.5 39.6001 23.0325 39.6001 23.6892C39.6001 24.3459 40.1374 24.8784 40.8001 24.8784Z" fill="#CCD7EA"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M35.8242 11.0268C36.0584 10.7947 36.0584 10.4182 35.8242 10.1861C35.59 9.95359 35.21 9.95359 34.9758 10.1861C34.7411 10.4182 34.7411 10.7947 34.9758 11.0268C35.21 11.2589 35.59 11.2589 35.8242 11.0268Z" fill="#E6EBF4"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M11.1213 38.8721C12.2929 37.7111 12.2929 35.8286 11.1213 34.6676C9.94978 33.5066 8.05022 33.5066 6.87867 34.6676C5.70711 35.8286 5.70711 37.7111 6.87867 38.8721C8.05022 40.0331 9.94978 40.0331 11.1213 38.8721Z" fill="#6686BF"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M11.9698 13.5507C13.6098 11.9254 13.6098 9.28982 11.9698 7.66458C10.3298 6.03889 7.67018 6.03889 6.03015 7.66458C4.38969 9.28982 4.38969 11.9254 6.03015 13.5507C7.67018 15.1759 10.3298 15.1759 11.9698 13.5507Z" fill="#003595"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M36.6731 38.0333C37.3758 37.3365 37.3758 36.2073 36.6731 35.5105C35.97 34.8142 34.8305 34.8142 34.1274 35.5105C33.4243 36.2073 33.4243 37.3365 34.1274 38.0333C34.8305 38.73 35.97 38.73 36.6731 38.0333Z" fill="#CCD7EA"/>
  </svg>
  `;
  this.safeSvgContent = this.sanitizer.bypassSecurityTrustHtml(svgContent);
}

  ngOnInit() {
  }

}
