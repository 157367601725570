<div class="upload-container">
  <div class="alc-upload">
    <div class="alc-upload-header-icon">
      <mat-icon (click)="closePopup()" aria-hidden="false" aria-label="close icon" fontIcon="close"></mat-icon>
    </div>
    <div class="alc-upload-header-title">
      <h1>Upload a document</h1>
    </div>
    <div *ngIf="errors.length > 0">
      <div class="error-message" *ngFor="let err of errors">
        <span>
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M7.40232 2.87947C7.77785 2.50486 8.28681 2.29476 8.81724 2.29541L15.1967 2.30316C15.7272 2.3038 16.2356 2.51514 16.6102 2.89066L21.1157 7.40713C21.4904 7.78266 21.7004 8.29162 21.6998 8.82205L21.692 15.2015C21.6914 15.732 21.4801 16.2404 21.1045 16.615L16.5881 21.1205C16.2125 21.4952 15.7036 21.7053 15.1732 21.7046L8.79366 21.6969C8.26323 21.6962 7.75478 21.4849 7.38016 21.1094L2.87466 16.5929C2.50004 16.2174 2.28995 15.7084 2.29059 15.178L2.29835 8.79847C2.29899 8.26804 2.51032 7.75959 2.88585 7.38497L7.40232 2.87947Z"
              fill="#E6062A" />
            <rect x="11.2949" y="7" width="1.4" height="6.98871" fill="white" />
            <rect x="11.2949" y="15.0938" width="1.4" height="1.32321" fill="white" />
          </svg>
        </span>
        <span>{{err}}</span>
      </div>
    </div>

    <alc-section-loader *ngIf="showLoader"></alc-section-loader>
    <ng-container>
      <div class="alc-upload-content">
        <div [draggable]="isDraggable" class="upload-area">
          <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M19.3909 31.1502L24.0739 26.4688L28.7554 31.1502" stroke="#003595" stroke-width="2"
              stroke-linecap="round" />
            <path d="M24.074 27.3516V39.0276" stroke="#003595" stroke-width="2" stroke-linecap="round" />
            <path
              d="M31.1475 35.4075C31.611 35.5005 32.088 35.5485 32.577 35.5485C36.6075 35.5485 39.8715 32.2815 39.8715 28.2525C39.8715 25.2195 38.019 22.62 35.3835 21.519C35.394 21.3375 35.412 21.156 35.412 20.9715C35.412 15.81 31.2285 11.625 26.0655 11.625C21.945 11.625 18.4545 14.2965 17.2125 18C17.199 18 17.187 17.9985 17.175 17.9985C12.453 17.9985 8.62354 21.825 8.62354 26.5485C8.62354 31.269 12.453 35.097 17.175 35.097"
              stroke="#003595" stroke-width="2" />
          </svg>
          <input #fileInput type="file" (change)="onFileSelect($event)" hidden />
          <ng-container *ngIf="!showUploadBtn">
            <div class="content-title-one" *ngIf="!isMobile && !isTablet">Select a file or drag and drop here</div>
            <div class="content-title-one" *ngIf="isMobile || isTablet">Select a file</div>
            <div class="content-title-two">Pdf, docx, xlsx, pptx, txt, rtf, csv, png, jpg & msg.</div>
            <div class="content-title-two">File size no more than 5MB</div>
            <div class="alc-file-select">
              <button (click)="fileInput.click()" type="button" class="alc_button alc_button--primary">Select
                file</button>
            </div>
          </ng-container>
          <ng-container *ngIf="showUploadBtn">
            <div class="alc_input-container">
              <div class="alc_input" [ngClass]="{'flex-1': isMobile, 'flex-50': !isMobile}">
                <label for="name" class="alc_input-label">Selected file</label>
                <div class="alc_input-wrapper alc_input-wrapper--icon">
                  <input type="text" readonly class="alc_input-text" [value]="fileName" />
                  <button mat-icon-button class="clear-btn" (click)="removeFile()" *ngIf="fileName">
                    <mat-icon>close</mat-icon>
                  </button>
                </div>
                <div class="alc-file-select">
                  <button type="button" class="alc_button alc_button--primary" (click)="saveFiles()">Upload
                    file</button>
                </div>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
      <div class="uploaded-files" *ngIf="ELEMENT_DATA.length > 0">
        <div class="file-info">Uploaded files</div>
        <!-- Upload File Grid -->
        <div *ngIf="!isMobile">
          <div class="uploaded-file-grid mat-elevation-z8">
            <table mat-table [dataSource]="dataSource" matSort>

              <!-- ID Column -->
              <ng-container matColumnDef="documentName">
                <th mat-header-cell *matHeaderCellDef (click)="customSort('documentName')">
                  File
                  <mat-icon class="sort-icon" 
                  [ngClass]="{'sort-icon-active': currentSort.active === 'documentName' && currentSort.direction !== ''}">{{getSortIcon('documentName')}}</mat-icon>
                </th>
                <td mat-cell *matCellDef="let element">
                  <a 
                    class="file-name ellipsis cursor-pointer"
                    (click)="handleFile(element.presignedUrl, element.documentName)"
                   >{{element.documentName}}</a>
                </td>
              </ng-container>

              <!-- Name Column -->
              <ng-container matColumnDef="uploadDate">
                <th mat-header-cell *matHeaderCellDef (click)="customSort('uploadDate')">
                  Upload date
                  <mat-icon class="sort-icon"
                  [ngClass]="{'sort-icon-active': currentSort.active === 'uploadDate' && currentSort.direction !== ''}">{{getSortIcon('uploadDate')}}</mat-icon>
                </th>
                <td mat-cell *matCellDef="let element">{{ element.uploadDate | date : "MM/dd/yyyy"}}</td>
              </ng-container>

              <!-- Age Column -->
              <ng-container matColumnDef="uploadedBy">
                <th mat-header-cell *matHeaderCellDef (click)="customSort('uploadedBy')">
                  User
                  <mat-icon class="sort-icon" 
                  [ngClass]="{'sort-icon-active': currentSort.active === 'uploadedBy' && currentSort.direction !== ''}">{{getSortIcon('uploadedBy')}}</mat-icon>
                </th>
                <td mat-cell *matCellDef="let element">{{ element.uploadedBy }}</td>
              </ng-container>

              <!-- Delete Icon Column -->
              <ng-container matColumnDef="delete">
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let element">
                  <div class="delete-icon" (click)="deleteElement(element.documentId)">
                    <svg width="56" height="64" viewBox="0 0 56 64" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <mask id="mask0_1059_98870" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="16" y="20"
                        width="24" height="24">
                        <rect x="16" y="20" width="24" height="24" fill="#D9D9D9" />
                      </mask>
                      <g mask="url(#mask0_1059_98870)">
                        <path
                          d="M23 41C22.45 41 21.9793 40.8043 21.588 40.413C21.196 40.021 21 39.55 21 39V26H20V24H25V23H31V24H36V26H35V39C35 39.55 34.8043 40.021 34.413 40.413C34.021 40.8043 33.55 41 33 41H23ZM33 26H23V39H33V26ZM25 37H27V28H25V37ZM29 37H31V28H29V37Z"
                          fill="#E6062A" />
                      </g>
                    </svg>
                  </div>
                </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
          </div>
        </div>
        <!-- Upload File List -->
        <div *ngIf="isMobile">
          <div class="file-list-container">
            <div class="file-list-item" *ngFor="let element of ELEMENT_DATA">
              <div class="file-info">
                <a class="file-name ellipsis cursor-pointer"
                  (click)="handleFile(element.presignedUrl, element.documentName)"
                >{{element.documentName}}</a>
                <div class="file-meta">
                  <div class="file-date">{{element.uploadDate | date : "MM/dd/yyyy"}}</div>
                  <div class="file-uploader">{{element.uploadedBy}}</div>
                </div>
              </div>
              <div class="file-actions">
                <span class="delete-icon" (click)="deleteElement(element.documentId)">
                  <svg width="56" height="64" viewBox="0 0 56 64" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <mask id="mask0_1059_98870" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="16" y="20"
                      width="24" height="24">
                      <rect x="16" y="20" width="24" height="24" fill="#D9D9D9" />
                    </mask>
                    <g mask="url(#mask0_1059_98870)">
                      <path
                        d="M23 41C22.45 41 21.9793 40.8043 21.588 40.413C21.196 40.021 21 39.55 21 39V26H20V24H25V23H31V24H36V26H35V39C35 39.55 34.8043 40.021 34.413 40.413C34.021 40.8043 33.55 41 33 41H23ZM33 26H23V39H33V26ZM25 37H27V28H25V37ZM29 37H31V28H29V37Z"
                        fill="#E6062A" />
                    </g>
                  </svg>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="alc-upload-actions">
        <button type="button" class="close-button alc_button alc_button--secondary"
          (click)="closePopup()">Close</button>
      </div>
    </ng-container>
  </div>
</div>