import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { format } from 'date-fns';

export interface dateTime {
  time: string;
  date: string;
}

@Injectable({
  providedIn: 'root'
})
export class AlconClockService {

  private timeFormat = "K:mm aaa";
  private dateFormat = "MMMM d, yyyy";
  public dateTimeEveryMinute$ = new Subject<dateTime>();
  private minute = 60 * 1000;

  constructor() { 
    this.setAbsoluteTimeInterval(this.notifyMinutesChange, this.minute);    
  }

  //this function syncs the interval with the time change on the system clock
  private setAbsoluteTimeInterval(callback: ()=>void, interval: number){
    const now = new Date().getTime();
    const delay = interval - (now % interval);
    const execute = () => {
      callback.bind(this)();
      setInterval(callback.bind(this), interval)
    }
    setTimeout(execute, delay);
    setTimeout(()=>{
      callback.bind(this)();
    })
  }

  private notifyMinutesChange(){
    const now = new Date()
    this.dateTimeEveryMinute$.next({
      time: format(now, this.timeFormat),
      date: format(now, this.dateFormat)
    })
  }

}
