import { AbstractControl, ValidatorFn } from '@angular/forms';

export function noCodeValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const forbidden = testForbiddenPatterns(control.value);
    return forbidden ? { 'forbiddenCodeSnippet': { value: control.value } } : null;
  };
}

export function testForbiddenPatterns(value: any){
  // Regular expressions to check for common XML code snippets
  const forbiddenPatterns = [
    /<\?xml\b[^>]*>/i,        // XML declaration
    /<!DOCTYPE\b[^>]*>/i,      // Document type declaration
    /<!\[CDATA\[[\s\S]*?\]\]>/i, // CDATA section
    /<[\w\s]*\/>/i,            // Self-closing tags
    /<[^>]*>/,                  // Regular expression to check for XML tags
    /<[^>]+>/g,                  // Regular expression to check for XML tags
    /<script[\s\S]*?>[\s\S]*?<\/script>/i, // Regular expression to check for JavaScript code
    /\b(SELECT|INSERT|UPDATE|DELETE|DROP|TRUNCATE|CREATE|ALTER|EXEC)\b\s+[A-Za-z0-9_\*,\s]*\bFROM\b\s+\w+/i, // SQL SELECT, INSERT, UPDATE, DELETE, DROP, TRUNCATE, CREATE, ALTER, EXEC patterns
    /\b(INSERT\s+INTO|UPDATE\s+\w+\s+SET|DELETE\s+FROM)\s+\w+/i,        // INSERT INTO, UPDATE SET, DELETE FROM patterns
    /\b(DROP|TRUNCATE|CREATE|ALTER|EXEC)\s+(TABLE|DATABASE)\s+\w+/i,   // DROP TABLE, CREATE DATABASE, etc.
    /\b(OR|AND)\b\s*\d+/i                       // Logical operators followed by numbers
      // Add more patterns as needed
];
  // Check if any forbidden pattern is found in the control value
  return forbiddenPatterns.some(pattern => pattern.test(value));
}

    // Custom validator function for US phone numbers
export function phoneNumberValidator(): ValidatorFn {
      return (control: AbstractControl): { [key: string]: any } | null => {
        const validPhoneNumberPattern = /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;
        const value = control.value;
        if(!value) {
          return null;
        }
        const isValid = validPhoneNumberPattern.test(value);
        return isValid ? null : { 'invalidPhoneNumber': { value: value } };
      };
    }

 // Helper Function to trim strings in the form data
export function trimFormData(data: any) {
    const trimmedData: any = {};
    for(const key in data) {
      if(data.hasOwnProperty(key)) {
        const value = data[key];
        if(typeof value === 'string') {
          trimmedData[key] = value.trim();
        } else if(typeof value === 'object' && value !== null && !Array.isArray(value)) {
          trimmedData[key] = trimFormData(value);
        } else {
          trimmedData[key] = value;
        }
      }
    } 
    return trimmedData;
} 